import * as React from 'react';

type Props = {
  isChecked: boolean;
  id: string;
  value: string;
  label: string;
};

const RadioButton = (props: Props): React.ReactElement => {
  return (
    <>
      <input className="tw-mr-2 tw-p-1" type="radio" id={props.id} value={props.value} checked={props.isChecked} />
      <label className="tw-form-check-label tw-inline-block" htmlFor={props.id}>
        {props.label}
      </label>
    </>
  );
};

export default RadioButton;
