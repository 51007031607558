import api from '../api';
import _ from 'lodash';

const TOKEN_KEY = 'access_token';
const USER_INFO_KEY = 'logged_in_user';
// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem(USER_INFO_KEY);
  return userStr ? JSON.parse(userStr) : null;
};

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem(TOKEN_KEY) || null;
};

export const requireLogin = async () => {
  const res = await api.dockOrderApis.getDockOrderElement();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
  return res.meta.status === 'failure';
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem(USER_INFO_KEY);
  sessionStorage.removeItem(TOKEN_KEY);
};

// set the token and user from the session storage
export const setUserSession = (token: any, userInfo: any) => {
  if (token) {
    sessionStorage.setItem(TOKEN_KEY, token);
  }
  sessionStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
};

export const convertTZ = (date: any, tzString: any) => {
  return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }));
};

export const convertTimeZoneJapan = (date: any) => {
  return convertTZ(date, 'Asia/Tokyo');
};

export const tokenExpired = (code: any) => {
  return ['E-L-111', 'E-L-121', 'E-L-122', 'E-L-123', 'E-L-124', 'E-L-125'].includes(code);
};

export const sortObjectByIndex = (obj: any) => {
  let result = {};
  let keys = Object.keys(obj).map((k) => {
    return { key: k, index: obj[k]?.index ? Number(obj[k]['index'].replace(/-/g, '')) : 0 };
  });
  keys = _.sortBy(keys, ['index']);
  keys.map((v) => {
    result = _.assign(result, { [v.key]: obj[v.key] });
  });
  return result;
};

export const getArrayObjectOrderByIndex = (obj: any) => {
  let keys = Object.keys(obj).map((k) => {
    return { key: k, index: obj[k]?.index ? Number(obj[k]['index'].replace(/-/g, '')) : 0 };
  });
  keys = _.sortBy(keys, ['index']);
  return keys.map((t) => t.key);
};

export const nonAccentVietnamese = (str: any) => {
  if (isEmpty(str)) return str;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export const selectOperationTemplate = {
  measurement: 'MEASUREMENT',
  condition_check: 'CONDITION CHECK',
  replace: 'REPLACE',
  replace_new: 'REPLACE - NEW',
  replace_spare: 'REPLACE - SPARE',
  repair: 'REPAIR',
  repair_clean: 'REPAIR - CLEAN',
  inspection: 'INSPECTION',
};

export enum SomeCodes {
  jpn = 'jpn',
  eng = 'eng',
  label_jpn = 'label_jpn',
  label_eng = 'label_eng',
  element_label_jpn = 'element_label_jpn',
  element_label_eng = 'element_label_eng',
  dock_order_element_label_jpn = 'dock_order_element_label_jpn',
  dock_order_element_label_eng = 'dock_order_element_label_eng',
  ship_spec = 'ship_spec',
  dock_order = 'dock_order'
}

export const selectLanguage = (key: string, type: string) => {
  if(key === SomeCodes['jpn'] && type === SomeCodes['ship_spec']){
    return SomeCodes['label_jpn']
  } else if(key === SomeCodes['eng'] && type === SomeCodes['ship_spec']) {
    return SomeCodes['label_eng']
  } else if(key === SomeCodes['jpn'] && type === SomeCodes['dock_order']) {
    return SomeCodes['dock_order_element_label_jpn']
  } else if(key === SomeCodes['eng'] && type === SomeCodes['dock_order']){
    return SomeCodes['dock_order_element_label_eng']
  } else if(key === SomeCodes['jpn']){
    return SomeCodes['element_label_jpn']
  } else {
    return SomeCodes['element_label_eng']
  }
}

export const isNumber = (value?: unknown): value is number => typeof value === 'number' && isFinite(value);

export const isString = (value?: unknown): value is string | undefined => typeof value === 'string' || value instanceof String;

export const isEmpty = (value?: string | number | null): value is null | undefined => value === null || value === undefined || value === '';

export const nvl = (value?: string | null, value2 = ''): string => {
  return value == null ? value2 : value;
};

export const truncateString = (text: String, len: number) => {
  if (!text) return;
  return text.length <= len ? text : text.substring(0, len) + '...';
};
