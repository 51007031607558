import { Dialog, Transition } from '@headlessui/react';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFormInput from '../../../../../hooks/useFormInput';
import { mainActions } from '../../../../../store/mainSlice';

function AddItemModal({ ...props }) {
  const dispatch = useDispatch();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  const { addItemModal } = useSelector((state) => state.main);

  const [isOpen, setIsOpen] = useState(false);
  const [dataItem, setDataItem] = useState(null);

  useEffect(() => {
    if (addItemModal) {
      setIsOpen(addItemModal.isOpen);
      setDataItem(addItemModal.item);
      newItemTitle.clear();
      newItemDescription.clear();
    }
  }, [addItemModal]);

  const newItemTitle = useFormInput({ initialValue: '', placeholder: 'Title' });
  const newItemDescription = useFormInput({ initialValue: '', placeholder: 'Explanation' });

  const handleDeletePlaceHolder = () => {
    props.handleDeletePlaceHolder();
  };

  const addCustomElement = () => {
    // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
    props.addCustomElement({ ...dataItem, title: newItemTitle.value, description: newItemDescription.value });
  };

  const onClose = () => {
    newItemTitle.clear();
    newItemDescription.clear();
    dispatch(
      mainActions.setAddItemModal({
        isOpen: false,
        item: null,
      }),
    );
  };

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}

          <span className="hidden" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle max-w-screen-xl w-full">
              <div className="flex flex-col bg-white py-4 px-8">
                <div className="tw-font-bold tw-text-xl pb-3">
                  Add an item to
                  <span className="text-purple-400 ml-2">
                    {/* // @ts-expect-error ts-migrate(2339) FIXME: Property 'no' does not exist on type 'never'. */}
                    {
                      // @ts-ignore
                      dataItem?.no
                    }
                  </span>
                </div>

                <div className="py-4 mb-3">
                  <div className="flex flex-col w-2/5 pr-3 mb-3">
                    <div className="tw-font-bold tw-text-lg pb-2">Title of new item</div>

                    <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-full px-3 py-4" type="text" {...newItemTitle} />
                  </div>

                  <div className="flex flex-col w-3/5">
                    <div className="tw-font-bold tw-text-lg pb-2">Explanation of new item</div>

                    <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-full px-3 py-4" type="text" {...newItemDescription} />
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    onClick={addCustomElement}
                    className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-9 py-2.5 mb-2 tw-text-white font-bold tw-bg-primary mr-2"
                  >
                    Add an Item
                  </button>

                  <button onClick={handleDeletePlaceHolder} className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-6 py-2.5 mb-2 tw-text-primary font-bold">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default memo(AddItemModal);
