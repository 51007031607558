import request from '../../utils/request';

const getList = () => request.get('api/v1/users');
const delUser = (data: any) => request.post('api/v1/users/delete_user', data);
const addUser = (data: any) => request.post(`api/v1/users/create_user`, data);
const editUser = (data: any) => request.post(`api/v1/users/update_user`, data);

const userApis = {
    getList,
    delUser,
    addUser,
    editUser
};

export default userApis;
