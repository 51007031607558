import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useDispatch } from 'react-redux';
import { mainActions } from '../../store/mainSlice';
import { dockOrderAction } from '../../store/dockOrderSlice';
import { getListShip, shipsActions } from '../../store/shipsSlice';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ContentLoader from 'react-content-loader';
import api from '../../api';

type Ship = {
  ship_id: string;
  ship_spec: {
    ship_name: string;
    ship_code: string;
  };
};

type Language = {
  key: string;
  name: string;
}

function AppHeader() {
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [shipName, setShipName] = useState('');
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  const { userInfo } = useSelector((state) => state.main);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ships' does not exist on type 'DefaultRo... Remove this comment to see the full error message
  const { shipList, getListStatus, selectedShip, dockOrderRepairHistory, shipSpecComfirmed } = useSelector((state) => state.ships);
  const history = useHistory();
  const dispatch = useDispatch();
  const [hidden, setHidden] = useState(true);
  const [shipEmpty, setShipEmpty] = useState(false);
  const shipNameRef = useRef(null);
  const LanguageList = [{key: 'eng', name: 'English'}, {key: 'jpn', name: '日本語'}];
  const [languageName, setLanguageName] = useState("English");


  const handleConfirmOut = () => {
    dispatch(mainActions.logout());
    history.push('/login');
    setIsModalConfirm(false);
  };

  useEffect(() => {
    if (getListStatus === 'idle' && userInfo) {
      dispatch(getListShip());
    }
  }, [getListStatus, dispatch, userInfo]);

  useEffect(() => {
    setShipEmpty((shipList || []).length === 0 && !selectedShip && getListStatus === 'succeeded');
  }, [selectedShip, shipList, getListStatus]);

  const linkToChangePasswordPage = () => {
    history.push('/change-password');
  };

  const pushDashboard = (ship: any) => {
    dispatch(shipsActions.setSelectedShip(ship));
    dispatch(shipsActions.setDockOrderDraft(null));
    history.push('/dashboard');
  };

  const handleChangeSelecteLanguage = (e: Language) => {
    setLanguageName(e.name);
    dispatch(dockOrderAction.setLanguage(e.key));
  }

  const handleChangeSelectedShip = (ship: any) => {
    if (selectedShip.ship_id !== ship.ship_id) {
      const currPathName = history.location.pathname;
      if (
        currPathName === '/basic-info' ||
        currPathName === '/application-form' ||
        currPathName === '/dock-order' ||
        currPathName === '/ship-spec' ||
        currPathName === '/repairment'
      ) {
        if (currPathName === '/ship-spec' && shipSpecComfirmed) {
          pushDashboard(ship);
          return;
        }
        if (currPathName === '/repairment') {
          pushDashboard(ship);
          return;
        }
        if (window.confirm('Are you sure you want to discard updated information?')) {
          pushDashboard(ship);
        }
      } else {
        dispatch(shipsActions.setSelectedShip(ship));
      }
    }
  };

  const backToDashboard = () => {
    if (userInfo?.is_admin) {
      return history.push('/admin/users');
    }
    if (dockOrderRepairHistory) {
      // dispatch(shipsActions.setDockOrderDraft(null));
      history.push('/dashboard');
    } else {
      const currPathName = history.location.pathname;
      if (currPathName === '/basic-info' || currPathName === '/application-form' || currPathName === '/dock-order' || currPathName === '/ship-spec') {
        if (currPathName === '/ship-spec' && shipSpecComfirmed) {
          return history.push('/dashboard');
        }
        if (window.confirm('Are you sure you want to discard updated information?')) {
          // dispatch(shipsActions.setDockOrderDraft(null));
          if (currPathName === '/ship-spec') {
            if (shipList && shipList.length > 0 && selectedShip?.ship_id === 999999) {
              dispatch(shipsActions.discardNewShip());
              dispatch(shipsActions.setSelectedShip(shipList[0]));
            }
          }
          history.push('/dashboard');
        }
      } else {
        history.push('/dashboard');
      }
    }
  };

  const handleChange = (e: any) => {
    setShipName(e.target.value);
  };

  const handleClickAddShip = () => {
    setHidden(!hidden);
    setShipName('');
    const t1 = setTimeout(() => {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      shipNameRef.current.focus();
      clearTimeout(t1);
    });
  };

  const handleAddShip = async () => {
    const newShip = {
      ship_id: 999999,
      ship_spec: {
        ship_spec_id: 999999,
        organization_id: 1,
        ship_code: '',
        ship_name: shipName,
        created: true,
      },
    };
    dispatch(shipsActions.setNewShip(newShip));
    dispatch(shipsActions.setSelectedShip(newShip));
    setShipName('');
    history.push('/ship-spec');
  };

  const handleShipSpec = (e: any) => {
    e.stopPropagation();
    history.push('/ship-spec');
  };

  return (
    <>
      <div className="tw-w-full tw-fixed tw-z-10">
        <div style={{ backgroundColor: userInfo?.is_admin ? '#3D4166' : '' }} className="tw-h-14 tw-w-full tw-flex tw-bg-lightdary tw-flex-row tw-justify-between">
          <div onClick={backToDashboard} className="header-title tw-cursor-pointer">
            <img src={'/static/seawiseロゴ_2.png'} className="tw-w-60 tw-h-42"/>
            <span className={`${userInfo?.is_admin ? 'tw-text-white' : ''}`}>Dock Order System</span>

            {userInfo?.is_admin && <span className="header-badge tw-ml-2">Admin</span>}
          </div>

          <Transition.Root show={isModalConfirm} as={React.Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setIsModalConfirm}>
              <div className="flex items-center justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                {/* This element is to trick the browser into centering the modal contents. */}

                <span className="hidden" aria-hidden="true">
                  &#8203;
                </span>

                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4"
                  enterTo="opacity-100 translate-y-0"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-4"
                >
                  <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle max-w-screen-xl tw-w-6/12">
                    <div className="flex flex-col bg-white py-4 px-8">
                      {isModalConfirm && (
                        <React.Fragment>
                          <div className="tw-font-bold tw-text-xl pt-10 pb-3 w-full text-center">You are trying to sign out. Are you sure?</div>

                          <div className="flex flex-row justify-center px-3 py-3 mb-5">
                            <button
                              onClick={() => handleConfirmOut()}
                              className="d-flex items-center self-center tw-rounded-lg tw-border-2 tw-border-primary tw-px-12 py-3 mb-2 mr-2 tw-text-white tw-bg-primary tw-w-52"
                            >
                              {logoutSVG}

                              <span className="tw-ml-4">Sign Out</span>
                            </button>

                            <button
                              onClick={() => setIsModalConfirm(false)}
                              className="d-flex items-center self-center tw-rounded-lg tw-border-2 tw-border-primary px-16 py-3 mb-2 tw-text-primary"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" className="mr-4">
                                <path d="M1 1L13 13M1 13L13 1L1 13Z" stroke="#5C6BC0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              Cancel
                            </button>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <div className="tw-flex">
            {userInfo && (
               <div className="tw-bg-secondary tw-h-14 tw-px-8 tw-mr-2 tw-flex tw-relative">
               <Menu as="div" className="tw-text-left tw-flex tw-items-center">
                 <Menu.Button as="div" className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full">
                   { languageName }
                   <ChevronDownIcon className="tw--mr-1 tw-ml-2 tw-h-5 tw-w-5" aria-hidden="true" />
                 </Menu.Button>

                 <Transition
                   as={Fragment}
                   enter="transition ease-out duration-100"
                   enterFrom="transform opacity-0 scale-95"
                   enterTo="transform opacity-100 scale-100"
                   leave="transition ease-in duration-75"
                   leaveFrom="transform opacity-100 scale-100"
                   leaveTo="transform opacity-0 scale-95"
                 >
                   <Menu.Items className="tw-origin-top-right tw-absolute tw-right-0 tw-top-14 tw-w-40 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-primary tw-ring-opacity-5 focus:tw-outline-none">
                     <div className="py-1">
                       {(LanguageList  || []).map((e: Language) => {
                         return (
                           <span className="tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-lightdary tw-cursor-pointer">
                             <Menu.Item
                               onClick={() => handleChangeSelecteLanguage(e)}
                               key={e.key}
                             >
                               <div>
                                 <span className="tw-font-bold tw-px-4">{e.name}</span>
                               </div>
                             </Menu.Item>
                           </span>
                         );
                       })}
                     </div>
                   </Menu.Items>
                 </Transition>
               </Menu>
             </div>
            )}
            {userInfo && !userInfo?.is_admin && (
              <>
                {selectedShip || shipEmpty ? (
                  <div className="tw-bg-secondary tw-h-14 tw-px-8 tw-mr-2 tw-flex tw-items-center tw-text-2xl tw-relative">
                    <Menu as="div" className="tw-text-left tw-flex tw-items-center">
                      <Menu.Button as="div" className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full tw-cursor-pointer">
                        <div className={`tw-text-base ${shipEmpty ? 'tw-px-20 tw-font-bold' : ''}`}>{shipEmpty ? 'Add new ship' : 'ship'}</div>

                        {selectedShip && (
                          <>
                            <span className="tw-font-bold tw-px-4">{selectedShip?.ship_spec?.ship_name}</span>

                            {selectedShip?.ship_spec?.ship_code && <div className="opacity-50">({selectedShip?.ship_spec?.ship_code})</div>}

                            <div className="tw-ml-16 opacity-50 cursor-pointer underline text-base">
                              <span onClick={handleShipSpec}>Ship Spec</span>
                            </div>
                          </>
                        )}

                        <ChevronDownIcon className="opacity-50 tw--mr-1 tw-ml-2 tw-h-5 tw-w-5" aria-hidden="true" />
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="tw-origin-top-right tw-absolute tw-left-0 tw-right-0 tw-top-14 tw-rounded-md tw-shadow-lg tw-bg-white focus:tw-outline-none tw-ring-1 tw-ring-primary tw-ring-opacity-5 tw-max-h-96 tw-overflow-y-auto">
                          <div className="py-1">
                            {(shipList || []).map((ship: Ship) => {
                              const shipcode = ship === undefined ? 'None' : ship.ship_spec?.ship_code;
                              return (
                                <span className="tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-lightdary tw-cursor-pointer">
                                  <Menu.Item
                                    // className="tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-lightdary tw-cursor-pointer"
                                    onClick={() => handleChangeSelectedShip(ship)}
                                    key={ship.ship_id}
                                  >
                                    <div>
                                      <span>ship</span>
                                      <span className="tw-font-bold tw-px-4">{ship.ship_spec?.ship_name}</span>
                                      {shipcode && <span>({shipcode})</span>}
                                    </div>
                                  </Menu.Item>
                                </span>
                              );
                            })}
                          </div>

                          <hr hidden={!hidden} />

                          <div className="tw-text-sm ml-12 mb-1 tw-flex opacity-50 cursor-pointer tw-py-1" hidden={!hidden} onClick={handleClickAddShip}>
                            <button>
                              <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 12 12" fill="none">
                                <path d="M6 1V6M6 6V11M6 6H11M6 6H1" stroke="#CBD5E1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </button>

                            <div className="mx-2">Add new ship</div>
                          </div>

                          <div className="flex tw-text-sm my-2 tw-mx-6" hidden={hidden}>
                            <input
                              ref={shipNameRef}
                              onKeyDown={(e) => {
                                if (e.code === 'Space') {
                                  e.stopPropagation();
                                }
                              }}
                              className="p-1 border-2 rounded-md tw-w-full"
                              type="text"
                              value={shipName}
                              placeholder="Ship name"
                              onChange={handleChange}
                            />

                            <button
                              type="button"
                              className={`${
                                shipName ? 'tw-border-purple-400 text-purple-400' : 'opacity-60 tw-border-gray-500 text-slate-300 cursor-default tw-w-20'
                              } mx-2 p-1 border-2 rounded-md`}
                              onClick={handleAddShip}
                              disabled={!shipName}
                            >
                              Add
                            </button>

                            <div className="tw-py-1 tw-pl-4 cursor-pointer underline opacity-60" onClick={handleClickAddShip}>
                              Cancel
                            </div>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                ) : (
                  <SelectShipPlacehoder />
                )}
              </>
            )}
            {userInfo && (
              <div className="tw-bg-secondary tw-h-14 tw-px-8 tw-mr-2 tw-flex tw-relative">
                <Menu as="div" className="tw-text-left tw-flex tw-items-center">
                  <Menu.Button as="div" className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full">
                    <div className="tw-bg-primary tw-w-10 tw-h-10 tw-rounded-full tw-mr-4"></div>
                    {userInfo.username}

                    <ChevronDownIcon className="tw--mr-1 tw-ml-2 tw-h-5 tw-w-5" aria-hidden="true" />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="tw-origin-top-right tw-absolute tw-right-0 tw-top-14 tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-primary tw-ring-opacity-5 focus:tw-outline-none">
                      <div className="py-1">
                        <div className="tw-flex tw-ml-5 cursor-pointer" onClick={linkToChangePasswordPage}>
                          <div className="tw-my-auto">{SettingSVG}</div>

                          <Menu.Item>
                            {/* // className="tw-block tw-px-4 tw-py-2 tw-text-sm" // @ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided...
                            Remove this comment to see the full error message> */}
                            <button>Change Password</button>
                          </Menu.Item>
                        </div>
                        <div className="tw-flex tw-ml-5 cursor-pointer" onClick={() => setIsModalConfirm(true)}>
                          <div className="tw-my-auto">{logoutSVG}</div>

                          <Menu.Item>
                            {/* className="tw-block tw-px-4 tw-py-2 tw-text-sm" */}
                            <button className="tw-flex tw-ml-5">Log out</button>
                          </Menu.Item>
                        </div>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const logoutSVG = (() => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 4L7.6 5.4L10.2 8H0V10H10.2L7.6 12.6L9 14L14 9L9 4ZM18 16H10V18H18C19.1 18 20 17.1 20 16V2C20 0.9 19.1 0 18 0H10V2H18V16Z" fill="#E8EAF6" stroke="#CBD5E1" />
    </svg>
  );
})();

const SettingSVG = (() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M11.3958 3.5975C11.0408 2.13417 8.95917 2.13417 8.60417 3.5975C8.37417 4.54333 7.29083 4.99167 6.46083 4.485C5.17417 3.70167 3.7025 5.17417 4.48583 6.46C4.60359 6.65313 4.67407 6.87133 4.69156 7.09685C4.70904 7.32236 4.67303 7.54882 4.58645 7.75779C4.49988 7.96676 4.36518 8.15233 4.19333 8.29941C4.02148 8.44648 3.81733 8.5509 3.5975 8.60417C2.13417 8.95917 2.13417 11.0408 3.5975 11.3958C3.81714 11.4492 4.02107 11.5537 4.19273 11.7008C4.36438 11.8479 4.49892 12.0333 4.58539 12.2422C4.67187 12.451 4.70785 12.6773 4.6904 12.9027C4.67296 13.1281 4.60258 13.3461 4.485 13.5392C3.70167 14.8258 5.17417 16.2975 6.46 15.5142C6.65313 15.3964 6.87133 15.3259 7.09685 15.3084C7.32236 15.291 7.54882 15.327 7.75779 15.4135C7.96676 15.5001 8.15233 15.6348 8.29941 15.8067C8.44648 15.9785 8.5509 16.1827 8.60417 16.4025C8.95917 17.8658 11.0408 17.8658 11.3958 16.4025C11.4492 16.1829 11.5537 15.9789 11.7008 15.8073C11.8479 15.6356 12.0333 15.5011 12.2422 15.4146C12.451 15.3281 12.6773 15.2922 12.9027 15.3096C13.1281 15.327 13.3461 15.3974 13.5392 15.515C14.8258 16.2983 16.2975 14.8258 15.5142 13.54C15.3964 13.3469 15.3259 13.1287 15.3084 12.9032C15.291 12.6776 15.327 12.4512 15.4135 12.2422C15.5001 12.0332 15.6348 11.8477 15.8067 11.7006C15.9785 11.5535 16.1827 11.4491 16.4025 11.3958C17.8658 11.0408 17.8658 8.95917 16.4025 8.60417C16.1829 8.55075 15.9789 8.44627 15.8073 8.29921C15.6356 8.15215 15.5011 7.96666 15.4146 7.75782C15.3281 7.54897 15.2922 7.32267 15.3096 7.09731C15.327 6.87195 15.3974 6.65388 15.515 6.46083C16.2983 5.17417 14.8258 3.7025 13.54 4.48583C13.3469 4.60359 13.1287 4.67407 12.9032 4.69156C12.6776 4.70904 12.4512 4.67303 12.2422 4.58645C12.0332 4.49988 11.8477 4.36518 11.7006 4.19333C11.5535 4.02148 11.4491 3.81733 11.3958 3.5975Z"
        stroke="#CBD5E1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678Z"
        stroke="#CBD5E1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
})();

const SelectShipPlacehoder = (props: any) => {
  return (
    <ContentLoader speed={2} width={392} height={60} viewBox="0 0 900 60" backgroundColor="#d9d9d9" foregroundColor="#ededed" {...props}>
      <rect x="50" y="0" rx="10" ry="10" width="150" height="50" />

      <rect x="254" y="0" rx="10" ry="10" width="230" height="50" />

      <rect x="530" y="0" rx="10" ry="10" width="200" height="50" />

      <rect x="760" y="0" rx="10" ry="10" width="60" height="50" />
    </ContentLoader>
  );
};

export default AppHeader;
