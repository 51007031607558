import { Dialog, Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from '../../../../../store/mainSlice';

function DeleteItemModal(props: any) {
  const dispatch = useDispatch();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  const { deleteItemModal } = useSelector((state) => state.main);

  const [isOpen, setIsOpen] = useState(false);
  const [dataItem, setDataItem] = useState(null);

  useEffect(() => {
    if (deleteItemModal) {
      setIsOpen(deleteItemModal.isOpen);
      setDataItem(deleteItemModal.item);
    }
  }, [deleteItemModal]);

  const onCloseModal = () => {
    dispatch(
      mainActions.setDeleteItemModal({
        isOpen: false,
        item: null,
      }),
    );
  };

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onCloseModal}>
        <div className="flex items-center justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle max-w-screen-xl w-full">
              <div className="block bg-white py-4 px-8 tw-justify-center">
                <div className="flex tw-justify-end">
                  <div className="tw-cursor-pointer" onClick={onCloseModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 tw-text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                </div>
                <div className="block mx-auto tw-w-2/5">
                  <div className="tw-font-bold tw-text-xl pb-3 tw-text-primary whitespace-pre">{
                    // @ts-ignore
                    `${dataItem?.no}      ${dataItem?.title}`
                  }</div>
                  <div className="tw-font-bold tw-text-xl pb-8">You are trying to delete this item. Are you sure?</div>
                </div>
                <div className="flex tw-justify-center">
                  <button
                    onClick={() => props.onSubmit(dataItem)}
                    className="flex self-center tw-rounded-lg tw-border-2 tw-border-primary px-20 py-3 mb-2 tw-text-white font-bold tw-bg-primary mr-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="mr-4">
                      <path d="M5 13L9 17L19 7" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Delete the item
                  </button>
                  <button onClick={onCloseModal} className="flex self-center tw-rounded-lg tw-border-2 tw-border-primary px-20 py-3 mb-2 tw-text-primary font-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="mr-4">
                      <path d="M6 6L18 18M6 18L18 6L6 18Z" stroke="#5C6BC0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default DeleteItemModal;
