import { useDispatch } from 'react-redux';
import { mainActions } from '../../../../../store/mainSlice';

type Props = {
  index: number;
  no: string;
  parentItem: any;
  parentKey: any;
  position: number;
};

const CustomPlaceHolder = (props: Props) => {
  let rowClass = 'tw-flex tw-bg-secondary tw-items-center tw-h-12 tw-rounded-xl tw-border-2 border-gray-300 cursor-pointer';
  let leftClass = 'tw-w-4/12 tw-border-r-2 tw-h-full tw-border-white tw-flex tw-items-center tw-px-4 tw-justify-between';
  let borderClass1 = 'tw-w-3/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-white tw-items-center flex';
  let borderClass2 = 'tw-w-2/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-white tw-items-center flex';

  let titleClass = 'tw-font-bold tw-flex-1 text-gray-300';

  if (props.index) {
    if (props.index % 2 !== 0) {
      rowClass = `tw-flex tw-bg-white tw-items-center tw-h-11 tw-rounded-xl tw-border-2 border-gray-300`;
      leftClass = `tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between`;
      borderClass1 = `tw-w-3/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex`;
      borderClass2 = 'tw-w-2/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex';
      titleClass = 'tw-flex-1 text-gray-300';
    } else {
      rowClass = `tw-flex tw-bg-lightdary tw-items-center tw-h-11 tw-rounded-xl tw-border-2 border-gray-300`;
      leftClass = `tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between`;
      borderClass1 = `tw-w-3/12 tw-px-4  tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex`;
      borderClass2 = 'tw-w-2/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex';

      titleClass = 'tw-flex-1 text-gray-300';
    }
  }

  let twidth = props.no.split('-').length * 4;

  const dispatch = useDispatch();

  const openAddItemModal = () => {
    dispatch(
      mainActions.setAddItemModal({
        isOpen: true,
        item: {
          no: props.no,
          parentKey: props.parentKey,
          position: props.position,
          parentItem: props.parentItem,
        },
      }),
    );
  };

  return (
    <>
      <div className={rowClass} onClick={openAddItemModal}>
        <div className={leftClass}>
          <div className={'tw-text-right text-gray-300 tw-pr-4 tw-pl-' + twidth}>{props.no}</div>
          <div className={titleClass}>Add an item</div>
        </div>
        <div className={borderClass1}></div>
        <div className={borderClass2}></div>
        <div className={borderClass2}></div>
        <div className="tw-w-1/12 tw-px-4 tw-flex-1 tw-items-center flex"></div>
      </div>
    </>
  );
};

export default CustomPlaceHolder;
