import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import api from '../../api';
import { Dialog, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from '../../store/mainSlice';
import Loading from 'atoms/Loading';

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;

const convertNestedObjectToArray = (nestedObj: any) => Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes: any) => Math.round(bytes / KILO_BYTES_PER_BYTE);

type Props = {
  updateFilesCb(...values: any): void;
  setImageDeleteFlg(imageDeleteFlg: boolean): void;
  onSearch(e: any, isUpdImage?: boolean): void;
};

const UploadImage = (props: Props) => {
  type Item = {
    no: string;
    lock: string;
    ship: {
      ship_spec: {
        ship_code: string;
      };
    };
    basic_info: {
      dock_order_purpose: string;
    };
    update_at: string;
  };

  const fileInputField = useRef(null);
  const [files, setFiles] = useState<string[]>([]);
  const [isModalDeleteItemOpen, setModalDeleteItemOpen] = useState(false);
  const [urlDelete, setUrlDelete] = useState('');
  const [dataItem, setDataItem] = useState<Item>();

  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  const { uploadDataModal } = useSelector((state) => state.main);

  useEffect(() => {
    if (uploadDataModal) {
      setIsOpen(uploadDataModal.isOpen);
      setDataItem(uploadDataModal.item);
      setFiles(uploadDataModal.item?.images ?? []);
    }
  }, [uploadDataModal]);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    if (acceptedFiles) {
      uploadFile(acceptedFiles);
      // const updatedFiles = addNewFiles(acceptedFiles);
      // setFiles(updatedFiles);
      // callUpdateFilesCb(updatedFiles);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ noClick: true, onDrop });

  const handleUploadBtnClick = () => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    fileInputField.current.click();
  };

  const callUpdateFilesCb = (files: any) => {
    // ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
    props.updateFilesCb({ ...dataItem, images: files });
  };

  const handleNewFileUpload = (e: any) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      uploadFile(newFiles);
      // const updatedFiles = addNewFiles(newFiles);
      // setFiles(updatedFiles);
      // callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (url: string) => {
    // ts-expect-error ts-migrate(2339) FIXME: Property 'lock' does not exist on type 'never'.
    if (!dataItem?.lock) {
      if (files && files.length > 0) {
        setFiles((prev) => {
          const newArr = prev.filter((file) => file !== url);
          callUpdateFilesCb(newArr);
          return newArr;
        });
        // setFiles({ ...files });
        // callUpdateFilesCb({ ...files });
      }
    }
  };

  const handleClose = () => {
    dispatch(mainActions.setUploadDataModal({ isOpen: false, item: null }));
    const inputTextSearch = document.getElementById('inputTextSearch');
    if (!inputTextSearch) return;
    //@ts-ignore
    props.onSearch(inputTextSearch.defaultValue, true);
  };

  const uploadFile = async (newFiles: any) => {
    try {
      setIsLoading(true);
      for (const file of newFiles) {
        const formData = new FormData();
        formData.append('file', file);
        await api.userFileUploadApi.uploadFile(formData).then((res) => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
          if (res.meta.status === 'success') {
            setFiles((prev) => {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type 'AxiosRes... Remove this comment to see the full error message
              const newArr = [...prev, res.entity];
              callUpdateFilesCb(newArr);
              return newArr;
            });
          }
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const isImageFile = (url: any) => {
    const regex = /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg))/i;
    return regex.test(url);
  };

  const onDownload = (url: any) => {
    const file_name = url.substring(url.lastIndexOf('/') + 1);
    //const file_name = url;
    const formData = new FormData();
    formData.append('file', file_name);
    api.userFileUploadApi.downloadFile(file_name).then((res) => {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'AxiosResponse<any, any>' is not assignable t... Remove this comment to see the full error message
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      console.log(res);
      link.target = isImageFile(url) ? '_blank' : '_self';
      link.setAttribute('download', file_name); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleDeleteItem = () => {
    if (urlDelete) {
      const file_name = urlDelete;
      const formData = new FormData();
      // ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      formData.append('file', file_name);
      api.userFileUploadApi.deleteFile(formData).then((res) => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
        if (res.meta.status === 'success') {
        }
      });

      removeFile(urlDelete);
      setModalDeleteItemOpen(false);
      props.setImageDeleteFlg(true);
    }
  };

  const onConfirmDelete = (url: string) => {
    setUrlDelete(url);
    setModalDeleteItemOpen(true);
  };

  return (
    // ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
    <>
      <Loading isLoading={isLoading}></Loading>
      <Transition.Root show={isModalDeleteItemOpen} as={React.Fragment}>
        <Dialog as="div" className="fixed z-30 inset-0 overflow-y-auto" onClose={setModalDeleteItemOpen}>
          <div className="flex items-center justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}

            <span className="hidden" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle max-w-screen-xl w-full">
                <div className="block bg-white py-4 px-8 tw-justify-center">
                  <div className="flex tw-justify-end">
                    <div className="tw-cursor-pointer" onClick={() => setModalDeleteItemOpen(false)}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 tw-text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </div>
                  </div>

                  <div className="block mx-auto tw-w-2/5">
                    <div className="tw-font-bold tw-text-xl pb-3 tw-text-primary whitespace-pre">Delete image?</div>

                    <div className="tw-font-bold tw-text-xl pb-8">You are trying to delete this image. Are you sure?</div>
                  </div>

                  <div className="flex tw-justify-center">
                    <button
                      onClick={handleDeleteItem}
                      className="flex self-center tw-rounded-lg tw-border-2 tw-border-primary px-20 py-3 mb-2 tw-text-white font-bold tw-bg-primary mr-2"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="mr-4">
                        <path d="M5 13L9 17L19 7" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      Delete the image
                    </button>

                    <button
                      onClick={() => setModalDeleteItemOpen(false)}
                      className="flex self-center tw-rounded-lg tw-border-2 tw-border-primary px-20 py-3 mb-2 tw-text-primary font-bold"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="mr-4">
                        <path d="M6 6L18 18M6 18L18 6L6 18Z" stroke="#5C6BC0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {isOpen && (
        <div
          style={{ boxShadow: '0px 2px 40px rgba(0, 0, 0, 0.2)', top: 250, right: 200 }}
          className={`flex justify-between absolute z-30 bg-white tw-p-4 tw-rounded-xl w-${dataItem?.lock ? '4/12' : '8/12'} tw-m-2`}
        >
          {!dataItem?.lock && (
            <>
              <div className="tw-w-6/12 tw-px-8 tw-pb-11">
                <h2 className="tw-text-2xl tw-font-bold tw-mb-2">
                  Add files to <span className="text-purple-400">{dataItem?.no}</span>
                </h2>
                <div className="tw-border-4 border-dashed tw-rounded-md tw-h-full flex text-center justify-center tw-flex-col">
                  <div className="flex text-center justify-center">
                    <button
                      className="tw-w-4/12 tw-border-2 tw-border-primary tw-text-primary tw-font-bold tw-p-2 tw-rounded-xl tw-mt-6"
                      type="button"
                      onClick={handleUploadBtnClick}
                    >
                      <span>Select Files</span>
                    </button>
                  </div>

                  <input className="hidden" type="file" ref={fileInputField} onChange={handleNewFileUpload} title="" value="" multiple={true} />
                </div>
              </div>
            </>
          )}
          <div style={{ minHeight: 256 }} className={dataItem?.lock ? 'tw-w-full' : 'tw-w-6/12'}>
            <div className="flex justify-content-end">
              <button onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 tw-text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className="flex flex-row pl-4 tw-flex-wrap text-center">
              {(files || []).map((url: string, index) => {
                return (
                  <div className="tw-my-1 tw-mx-2 tw-w-3.3/12" key={index}>
                    <div className="relative">
                      {!dataItem?.lock && (
                        <div className="absolute cursor-pointer right-0 top-0 tw-bg-gray-100 tw-rounded-bl-md" onClick={() => onConfirmDelete(url)}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 tw-text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </div>
                      )}
                      {url && (
                        <div title={url.substring(url.lastIndexOf('/') + 1)} className="cursor-pointer" onClick={() => onDownload(url)}>
                          {isImageFile(url) ? (
                            <img width={120} height={120} src={url} alt={`file preview ${index}`} />
                          ) : (
                            <div className="flex justify-center justify-content-center" style={{ width: 120, height: 120 }}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-16 tw-w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                />
                              </svg>

                              <span className="absolute bottom-10 text-xs">{url.substring(url.lastIndexOf('/') + 1, 8)}...</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UploadImage;
