import auth from './auth';
import ships from './ships';
import dockOrderApis from "./dock_order";
import userFileUploadApi from './user_file_upload'
import user from './user';
import organization from './organization';

const api = {
  auth,
  ships,
  dockOrderApis,
  userFileUploadApi,
  user,
  organization,
};
export default api;
