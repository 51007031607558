import { BrowserRouter, Switch } from 'react-router-dom';
import Login from './pages/client/Login';
import UploadExcel from './pages/client/UploadExcel';
import ChangePassword from './pages/common/ChangePassword';
import AppHeader from './components/AppHeader';
import Dashboard from './pages/client/Dashboard';
import ShipSpec from './pages/client/ShipSpec';
import PrivateRoute from './utils/privateRoute';
import PublicRoute from './utils/publicRoute';
import DocOrder from './pages/client/DocOrder';
import CreateDocOrder from './pages/client/DocOrder/createDocOrder';
import ApplicationForm from './pages/client/DocOrder/applicationForm';
import CompleteOrder from './pages/client/CompleteOrder';
import Loader from './components/Loader';
import Admin from './pages/admin';
import Repairment from './pages/client/DocOrder/Repairment';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Loader />

        <AppHeader />

        <Switch>
          <PublicRoute path="/login" component={Login} />

          <PrivateRoute path="/admin" component={Admin} />

          <PrivateRoute exact path="/" component={Dashboard} />

          <PrivateRoute path="/dashboard" component={Dashboard} />

          <PrivateRoute path="/import-excel" component={UploadExcel} />

          <PrivateRoute path="/change-password" component={ChangePassword} />

          <PrivateRoute path="/ship-spec" component={ShipSpec} />

          <PrivateRoute path="/basic-info" component={DocOrder} />

          <PrivateRoute path="/application-form" component={ApplicationForm} />

          <PrivateRoute path="/dock-order" component={CreateDocOrder} />

          <PrivateRoute path="/complete-on-order" component={CompleteOrder} />

          <PrivateRoute path="/repairment" component={Repairment} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
