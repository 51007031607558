import React from 'react';
import { useHistory } from 'react-router-dom';
import { RouteComponentProps, StaticContext } from 'react-router';

type Props = {
  dock_order_id: number;
};

const CompleteOrder = (props: RouteComponentProps<null, StaticContext, Props>): React.ReactElement => {
  const history = useHistory();

  const sendCode = () => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    localStorage.setItem('orderStatus', true);
    history.push('/dashboard');
  };

  const dockOrderId = props.location?.state?.dock_order_id;

  return (
    <div className="tw-pt-28 tw-px-52">
      <div className="tw-flex tw-justify-center tw-text-primary">
        <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-28 w-28" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>

      <div className="tw-mt-7">
        <div className="tw-text-center text-lg tw-font-bold">Dock Order ID : {dockOrderId}</div>
        <div className="tw-text-center">
          <h2 className="tw-text-2xl tw-font-bold tw-my-2">Your order has been successfully accepted.</h2>
        </div>
        {/*<div className="tw-flex tw-flex-col tw-px-96 tw-mt-10">*/}
        {/*    /!*<h4 className="tw-text-base tw-font-medium">Please enter your dock reservation code below.</h4>*!/*/}
        {/*    <div className="tw-flex  tw-flex-col tw-justify-center">*/}
        {/*        /!*<input placeholder="Dock reservation code" className="tw-border-2 tw-mt-4 tw-rounded-md tw-h-10 tw-w-full tw-p-2" />*!/*/}
        {/*    </div>*/}
        {/*</div>*/}

        <div className="tw-flex-col tw-px-96 tw-text-center tw-justify-center tw-flex tw-mt-2 tw-pb-2">
          <h4 className="tw-text-base tw-font-medium">Haven’t you made a dock reservation yet?</h4>

          <button
            onClick={() => (window.location.href = `https://rdr.seawise.jp/?dock-order-id=${dockOrderId}`)}
            className="tw-rounded-lg tw-mt-4 tw-w-full tw-border-primary tw-border-2 tw-py-1 tw-px-2 tw-text-primary tw-font-bold mx-auto"
          >
            Reserve Dock
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompleteOrder;
