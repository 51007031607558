import React from 'react';
import { useHistory } from 'react-router-dom';

function SideBar() {
  const history = useHistory();

  const menuData = [
    {
      title: 'User',
      url: '/admin/users',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                   </svg>`,
    },
    {
      title: 'Organization',
      url: '/admin/organizations',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                    </svg>`,
    },
  ];

  const onRedirect = (url: any) => {
    history.push(url);
  };

  return (
    <div className="tw-w-2/12 tw-h-screen tw-pt-28 tw-border-r tw-flex tw-flex-col tw-overflow-y-auto">
      {menuData.map((t) => {
        const active = window.location.pathname === t.url;
        return (
          <div
            onClick={() => onRedirect(t.url)}
            className={`tw-flex tw-p-5 tw-text-lg tw-cursor-pointer ${active ? 'tw-bg-gray-300' : 'tw-text-gray-300'} tw-mb-2 tw-rounded-tr-lg tw-rounded-br-lg`}
          >
            <span dangerouslySetInnerHTML={{ __html: t.icon }}></span>

            <span className="tw-ml-3 ">{t.title}</span>
          </div>
        );
      })}
    </div>
  );
}

export default SideBar;
