import { Input, Select } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from '../../../../../store/mainSlice';
import { selectLanguage } from '../../../../../utils/common';
import OperationSubProcessComponent from './OperationSubProcess';
import Tooltip from '../../../../../components/Tooltip';
import { SUB_PROCESS_TITLE } from 'pages/client/DocOrder/createDocOrder';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import SelectOperation from './SelectOperation';

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

type Props = {
  index?: string;
  no: string;
  title: string;
  hint: string;
  parentKey: string;
  parts?: any;
  position?: number;
  key: string;
  valueUnit?: any;
  valueUnitName?: any;
  valueDirection?: any;
  valueProcess?: any;
  valueSubProcess?: any;
  handleChange(value: any, no: any, parentKey: any, position: any, key: any, keyStructs: any): void;
  images: any;
  isCustom: any;
  isConfirmOrder: boolean;
  isSearched: boolean;
  fnOnChangeSearchInputCheck(e: any): void;
  keyStructs: boolean;
  fnAddOperation(data: any): void;
  fnDeleteOperation(data: any): void;
  isMutilOperations: boolean;
  isRepairHistory: boolean;
  collapseId: string;
  isLeaf: boolean;
  generalDateInput: boolean;
  generalRangeDateHeader: string | undefined | null;
  selectedYear: any;
  selectedDay: any;
  keyParts: any;
  parentParts: any;
  currentParts: any | null;
  numberOperation: any | null;
  currentKey: string;
  mainKey: any;
  selectOperation?: any | undefined;
};

const CustomRow = (props: Props): React.ReactElement => {
  let rowClass = 'tw-flex tw-bg-secondary tw-items-center tw-h-12 tw-rounded-lg';
  let leftClass = 'tw-w-4/12 tw-border-r-2 tw-h-full tw-border-white tw-flex tw-items-center tw-px-4 tw-justify-between ';
  let borderClass = 'tw-w-2/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-white tw-items-center flex';
  let borderClassNotGeneral = 'tw-w-3/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-white tw-items-center flex';

  let titleClass = 'tw-font-bold tw-flex-1';

  if (props.index && props.index !== 'head') {
    if (Number(props.index) % 2 !== 0) {
      rowClass = `tw-flex tw-bg-white tw-items-center tw-h-12`;
      leftClass = `tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between`;
      borderClass = `tw-w-2/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex`;
      borderClassNotGeneral = 'tw-w-3/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex';
      titleClass = 'tw-flex-1';
    } else {
      rowClass = `tw-flex tw-bg-lightdary tw-items-center tw-h-12`;
      leftClass = `tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between`;
      borderClass = `tw-w-2/12 tw-px-4  tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex`;
      borderClassNotGeneral = 'tw-w-3/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex';
      titleClass = 'tw-flex-1';
    }
  }

  const [subOperationCustom, setSubOperationCustom] = useState({ value: '', processKey: null, prev: null, isCustom: true });
  const [subUnitCustom, setSubUnitCustom] = useState({ value: '', processKey: null, isCustom: true });
  const [subProcessTemplate, setSubProcessTemplate] = useState([]);

  const [unitValue, setUnitValue] = useState<string>();
  const [unitDateValue, setUnitDateValue] = useState<Dayjs | undefined>(props.selectedDay ? dayjs(props.selectedDay) : undefined);
  const dispatch = useDispatch();

  let isKeyPressInput = false;

  const isDockingAndUndockingHead: boolean = props.generalDateInput && props.index === 'head';

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'dockOrders' does not exist on type 'Defa... Remove this comment to see the full error message
  const { operationUnitDict, subProcessPullDownSource, language } = useSelector((state) => state.dockOrders);
  const getOperationUnitTitle = (key: any) => {
    // @ts-ignore
    return operationUnitDict[key] ? operationUnitDict[key][selectLanguage(language, 'operation_unit')] || operationUnitDict[key][selectLanguage(language, 'dock_order')] : key;
  };

  useEffect(() => {
    setSubProcessTemplate(subProcessPullDownSource.find((t: any) => t.key_parts === props.keyParts)?.sub_process || {});
  }, [subProcessPullDownSource, props.keyParts]);

  const handleEnterKeySubOperation = (e: any) => {
    if (e.key === 'Enter') {
      isKeyPressInput = true;
      props.handleChange({ ...subOperationCustom, value: e.target.value }, props.no, props.parentKey, props.position, 'sub_process_operation', props.keyStructs);
      const subOperation = _.cloneDeep(subOperationCustom);
      subOperation['value'] = '';
      setSubOperationCustom({ ...subOperation });
    }
  };

  const handleEnterKeyOperationSubProcess = (e: any, subOperationProcessCustom: any) => {
    if (e.key === 'Enter') {
      isKeyPressInput = true;
      props.handleChange({ ...subOperationProcessCustom, value: e.target.value }, props.no, props.parentKey, props.position, 'sub_process_operation', props.keyStructs);
    }
  };

  const handleEnterKeySubUnit = (e: any) => {
    if (e.key === 'Enter') {
      isKeyPressInput = true;
      props.handleChange({ ...subUnitCustom, value: e.target.value }, props.no, props.parentKey, props.position, 'sub_process_qtySelect', props.keyStructs);
      const unitCustom = _.cloneDeep(subUnitCustom);
      unitCustom['value'] = '';
      setSubUnitCustom({ ...unitCustom });
    }
  };

  const operationOnSelect = (value: any, option: any, subProcess: any) => {
    if (isKeyPressInput) {
      isKeyPressInput = false;
    } else {
      props.handleChange(value, props.no, props.parentKey, props.position, subProcess ? 'sub_process_operation' : 'operation', props.keyStructs);
    }
  };

  const unitOnSelect = (value: any, option: any, subProcess: any) => {
    if (isKeyPressInput) {
      isKeyPressInput = false;
    } else {
      props.handleChange(value, props.no, props.parentKey, props.position, subProcess ? 'sub_process_qtySelect' : 'qtySelect', props.keyStructs);
    }
  };

  // general controls

  const GeneralUnitDayChange = (value: any) => {
    if (isKeyPressInput) {
      isKeyPressInput = false;
    } else {
      props.handleChange(value, props.no, props.parentKey, props.position, 'generalUnitDaySelect', props.keyStructs);
    }
  };

  const onCollapse = (collapseId: any) => {
    if (collapseId) {
      const elmnt = document.getElementById(collapseId);
      const icon = document.getElementById(`icon-${collapseId}`);
      if (elmnt) {
        if (elmnt.classList.contains('hidden')) {
          elmnt.classList.remove('hidden');
          if (icon) {
            icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 9l-7 7-7-7" />
                            </svg>`;
          }
        } else {
          elmnt.classList.add('hidden');
          if (icon) {
            icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 5l7 7-7 7" />
                                            </svg>`;
          }
        }
      }
    }
  };

  const addDirection = () => {
    dispatch(
      mainActions.setDirectionDataModal({
        isOpen: true,
        item: {
          no: props.no,
          parentKey: props.parentKey,
          position: props.position,
          keyStructs: props.keyStructs,
        },
      }),
    );
  };

  const addDirectionSubProcess = (directionValue: any, processKey: any) => {
    dispatch(
      mainActions.setDirectionSubProcessDataModal({
        isOpen: true,
        item: {
          no: props.no,
          parentKey: props.parentKey,
          position: props.position,
          keyStructs: props.keyStructs,
          directionValue: props.valueDirection,
          valueSubProcess: props.valueSubProcess,
          processKey: processKey,
          subProcessDirectionValue: directionValue,
          updated: true,
        },
      }),
    );
  };

  const editDirection = (directionValue: any) => {
    dispatch(
      mainActions.setDirectionDataModal({
        isOpen: true,
        item: {
          no: props.no,
          parentKey: props.parentKey,
          position: props.position,
          keyStructs: props.keyStructs,
          directionValue,
          valueSubProcess: props.valueSubProcess,
          updated: true,
        },
      }),
    );
  };

  const editDirectionSubProcess = (directionValue: any, processKey: any) => {
    dispatch(
      mainActions.setDirectionSubProcessDataModal({
        isOpen: true,
        item: {
          no: props.no,
          parentKey: props.parentKey,
          position: props.position,
          keyStructs: props.keyStructs,
          directionValue: props.valueDirection,
          valueSubProcess: props.valueSubProcess,
          processKey: processKey,
          subProcessDirectionValue: directionValue,
          updated: true,
        },
      }),
    );
  };

  const lookDirection = (directionValue: any) => {
    dispatch(
      mainActions.setDirectionDataModal({
        isOpen: true,
        item: {
          no: props.no,
          parentKey: props.parentKey,
          position: props.position,
          keyStructs: props.keyStructs,
          directionValue,
          lock: true,
        },
      }),
    );
  };

  const openUploadFileModal = () => {
    dispatch(
      mainActions.setUploadDataModal({
        isOpen: true,
        item: {
          no: props.no,
          keyStructs: props.keyStructs,
          images: props.valueSubProcess?.[0]?.images,
          lock: props.isConfirmOrder || props.isRepairHistory,
        },
      }),
    );
  };

  const openDeleteItemModal = () => {
    dispatch(
      mainActions.setDeleteItemModal({
        isOpen: true,
        item: {
          no: props.no,
          parentKey: props.parentKey,
          position: props.position,
          title: props.title,
        },
      }),
    );
  };

  const openModalMultiOperations = () => {
    if (props.isSearched) return;
    let originData = {};
    const selectedKey = props.mainKey || props.currentKey;
    const title = getOperationUnitTitle(selectedKey);
    let operationData = {
      isOpen: true,
      item: {
        addItemNo: {},
        selectOperation: SUB_PROCESS_TITLE,
        numberOperationNo: props.numberOperation,
        operationMultiData: {},
        dockElementDict: operationUnitDict,
      },
    };
    const treeDataPart = _.cloneDeep(props.currentParts);
    const treeDataParent = _.cloneDeep(props.parentParts);
    let operationTempData = {};
    let updated = false;
    if (treeDataPart && treeDataPart?.parts && Object.keys(treeDataPart?.parts).length > 0) {
      updated = !!props.mainKey && treeDataPart?.modified;
    }
    if (updated) {
      if (treeDataParent?.parts) {
        const nodes = Object.keys(treeDataParent.parts).filter((k) => treeDataParent.parts[k].hasOwnProperty('main_key') && k.includes(props.mainKey));
        let arrNode = {};
        if (nodes && nodes.length > 0) {
          nodes.map((n) => {
            const pos = n
              .replace(new RegExp(selectedKey + '_', 'g'), '')
              .split('_')
              .map((n) => parseInt(n));
            const subNodePart = treeDataParent?.parts[n];
            if (subNodePart?.index) {
              delete subNodePart.index;
            }
            const keyName = `${selectedKey}_no${pos[0]}`;
            arrNode = Object.assign(arrNode, { [keyName]: { ...subNodePart } });
            let obj = { [keyName]: {} };
            Object.keys(props.selectOperation || []).map((k) => {
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              obj[keyName][k] = (subNodePart['sub_process'] && !!subNodePart['sub_process'][k]) || false;
            });
            if (subNodePart?.parts) {
              setOriginDataOperationChild(subNodePart?.parts, obj[keyName], true, operationData.item.selectOperation);
            }
            if (obj?.index) {
              delete obj.index;
            }
            originData = { ...originData, ...obj };
            if (pos.length > 1) {
              for (let i = 1; i < pos.length; i++) {
                const cloneObj = _.cloneDeep(obj[keyName]);
                // @ts-ignore
                if (cloneObj?.['index']) {
                  // @ts-ignore
                  delete cloneObj['index'];
                }
                const nextObj = { [`${selectedKey}_no${pos[i]}`]: { ...cloneObj } };
                const cloneSubObj = _.cloneDeep(subNodePart);
                arrNode = Object.assign(arrNode, { [`${selectedKey}_no${pos[i]}`]: { ...cloneSubObj } });
                originData = { ...originData, ...nextObj };
              }
            }
          });
        }
        operationData.item.addItemNo = {
          no: props.no,
          keyStructs: props.keyStructs,
          title,
          node: arrNode,
          currentKey: selectedKey,
          updated: true,
          operationTempData,
          numberOperation: props.numberOperation,
          treeDataPart: treeDataPart,
          treeDataParent: treeDataParent,
        };
      }
      operationData.item.operationMultiData = originData;
    } else {
      operationData.item.addItemNo = {
        no: props.no,
        keyStructs: props.keyStructs,
        title,
        node: treeDataPart,
        currentKey: selectedKey,
        operationTempData,
        numberOperation: props.numberOperation,
        treeDataPart: treeDataPart,
        treeDataParent: treeDataParent,
      };
      setOriginDataMultiOperations(operationData, treeDataPart, treeDataParent, operationTempData, props.numberOperation, selectedKey);
    }
    dispatch(mainActions.setOperationTableModal(operationData));
  };

  const setOriginDataMultiOperations = (operationData: any, treeDataPart: any, treeDataParent: any, operationTempData: any, numberOperation: any, currentKey: any) => {
    let originData = {};
    const nodes = Object.keys(treeDataParent.parts).filter((n) => n.includes(currentKey) && n.includes('_no') && n !== currentKey);
    if (nodes && nodes.length > 0) {
      nodes.map((n) => {
        const pos = n
          .replace(new RegExp(currentKey + '_', 'g'), '')
          .split('_')
          .map((n) => parseInt(n));
        const subNodePart = treeDataParent?.parts[n];
        const keyName = `${currentKey}_no${pos[0]}`;
        let obj = { [keyName]: {} };
        Object.keys(operationTempData).map((k) => {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          obj[keyName][k] = false;
        });
        if (subNodePart?.parts) {
          setOriginDataOperationChild(subNodePart?.parts, obj[keyName], false, operationTempData);
        }
        originData = { ...originData, ...obj };
        if (pos.length > 1) {
          for (let i = 1; i < pos.length; i++) {
            const cloneObj = _.cloneDeep(obj[keyName]);
            const nextObj = { [`${currentKey}_no${pos[i]}`]: { ...cloneObj } };
            originData = { ...originData, ...nextObj };
          }
        }
      });
    } else {
      for (let i = 0; i < numberOperation; i++) {
        const keyName = `${currentKey}_no${i + 1}`;
        let obj = { [keyName]: {} };
        Object.keys(props.selectOperation || []).map((k) => {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          obj[keyName][k] = false;
        });
        if (treeDataPart?.parts) {
          setOriginDataOperationChild(treeDataPart?.parts, obj[keyName], false, operationTempData);
        }
        originData = { ...originData, ...obj };
      }
    }
    operationData.item.operationMultiData = originData;
  };

  const setOriginDataOperationChild = (childParts: any, data: any, processData: any, operationTempData: any) => {
    if (childParts && data) {
      data['childs'] = {};
      Object.keys(childParts).map((child) => {
        const titleList: string[] = childParts[child]['sub_process'].map((v: any) => getOperationUnitTitle(v.title));
        data['childs'][child] = {};
        Object.keys(operationTempData).map((k) => {
          if (!processData) {
            data['childs'][child][k] = false;
          } else {
            Object.keys(operationTempData).map((k) => {
              data['childs'][child][k] = (childParts[child]['sub_process'] && titleList.includes(operationTempData[k])) || false;
            });
          }
        });
        if (childParts[child]?.parts) {
          setOriginDataOperationChild(childParts[child]?.parts, data['childs'][child], processData ? childParts[child] : false, operationTempData);
        }
      });
    }
  };

  const twidth = props.isSearched ? 4 : props.no.split('-').length * 4;
  const level = props.isSearched ? 2 : props.no.split('-').length;

  const truncateString = (text: String, len: number) => {
    if (!text) return;
    return text.length <= len ? text : text.substring(0, len) + '...';
  };

  return (
    <>
      <div className={rowClass} key={props.key}>
        <div onClick={() => onCollapse(`${props.collapseId}`)} className={leftClass}>
          {props.isSearched && (
            <div className="form-check form-check-inline mb-0">
              <input
                name={props.no}
                disabled={props.isMutilOperations}
                onChange={props.fnOnChangeSearchInputCheck}
                id={props.no}
                className="form-check-input appearance-none tw-h-4 tw-w-4 border border-gray-300 rounded-sm tw-bg-white focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                type="checkbox"
              />
            </div>
          )}

          {level % 2 === 0 ? <div className={'tw-text-right tw-pr-4 tw-pl-' + twidth}>{props.no}</div> : <div className={'tw-text-right tw-pr-4 tw-ml-' + twidth}>{props.no}</div>}
          {!props.isLeaf && (
            <span className="tw-cursor-pointer" id={`icon-${props.collapseId}`}>
              {level === 1 ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 9l-7 7-7-7" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 5l7 7-7 7" />
                </svg>
              )}
            </span>
          )}

          <div
            className={`${titleClass} tw-cursor-pointer`}
            style={{ textOverflow: 'ellipsis', overflow: 'hidden', WebkitLineClamp: '2', display: '-webkit-box', WebkitBoxOrient: 'vertical' }}
          >
            <span>{props.title}</span>
          </div>
          {level === 1 ? (
            <Tooltip content={props.hint}>
              <div className="hint tw-cursor-help tw-text-white" data-tip={props.hint}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M8.66667 10.6667H8V8H7.33333M8 5.33333H8.00667M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                    stroke="#F1F5F9"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </Tooltip>
          ) : (
            <div className="tw-flex">
              {props.isCustom && !props.isSearched ? (
                <div className="tw-cursor-pointer mr-2" hidden={props.isConfirmOrder || props.isRepairHistory} onClick={openDeleteItemModal}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.99967 2.66659C6.58519 2.66659 5.22863 3.22849 4.22844 4.22868C3.22824 5.22888 2.66634 6.58543 2.66634 7.99992C2.66634 8.7003 2.80429 9.39383 3.07232 10.0409C3.34034 10.688 3.73319 11.2759 4.22844 11.7712C4.72368 12.2664 5.31163 12.6593 5.9587 12.9273C6.60577 13.1953 7.29929 13.3333 7.99967 13.3333C8.70006 13.3333 9.39358 13.1953 10.0407 12.9273C10.6877 12.6593 11.2757 12.2664 11.7709 11.7712C12.2662 11.2759 12.659 10.688 12.927 10.0409C13.1951 9.39383 13.333 8.7003 13.333 7.99992C13.333 6.58543 12.7711 5.22888 11.7709 4.22868C10.7707 3.22849 9.41416 2.66659 7.99967 2.66659ZM3.28563 3.28587C4.53587 2.03563 6.23156 1.33325 7.99967 1.33325C9.76779 1.33325 11.4635 2.03563 12.7137 3.28587C13.964 4.53612 14.6663 6.23181 14.6663 7.99992C14.6663 8.8754 14.4939 9.7423 14.1589 10.5511C13.8238 11.36 13.3328 12.0949 12.7137 12.714C12.0947 13.333 11.3597 13.8241 10.5509 14.1591C9.74206 14.4941 8.87515 14.6666 7.99967 14.6666C7.1242 14.6666 6.25729 14.4941 5.44845 14.1591C4.63961 13.8241 3.90469 13.333 3.28563 12.714C2.66657 12.0949 2.17551 11.36 1.84048 10.5511C1.50545 9.7423 1.33301 8.8754 1.33301 7.99992C1.33301 6.23181 2.03539 4.53612 3.28563 3.28587ZM6.19494 6.19518C6.45529 5.93483 6.8774 5.93483 7.13775 6.19518L7.99967 7.05711L8.8616 6.19518C9.12195 5.93483 9.54406 5.93483 9.80441 6.19518C10.0648 6.45553 10.0648 6.87764 9.80441 7.13799L8.94248 7.99992L9.80441 8.86185C10.0648 9.1222 10.0648 9.54431 9.80441 9.80466C9.54406 10.065 9.12195 10.065 8.8616 9.80466L7.99967 8.94273L7.13775 9.80466C6.8774 10.065 6.45529 10.065 6.19494 9.80466C5.93459 9.54431 5.93459 9.1222 6.19494 8.86185L7.05687 7.99992L6.19494 7.13799C5.93459 6.87764 5.93459 6.45553 6.19494 6.19518Z"
                      fill="#CBD5E1"
                    />
                  </svg>
                </div>
              ) : null}

              <Tooltip content={props.hint}>
                <div className="hint tw-cursor-help" data-tip={props.hint}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M8.66667 10.6667H8V8H7.33333M8 5.33333H8.00667M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                      stroke="#CBD5E1"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </Tooltip>
            </div>
          )}
        </div>
        {
          <div style={{ marginLeft: props.isSearched ? -0.9 : 0 }} className={borderClassNotGeneral}>
            {props.index && !isDockingAndUndockingHead && (
              <div>
                {props.isConfirmOrder || props.isRepairHistory ? (
                  <div className="tw-mr-5 normal-case">{getOperationUnitTitle(props.valueSubProcess ? props.valueSubProcess?.[0]?.['title'] : null)}</div>
                ) : (
                  <SelectOperation
                    index={props.index}
                    key={props.key}
                    no={props.no}
                    parentKey={props.parentKey}
                    keyStructs={props.keyStructs}
                    isMutilOperations={props.isMutilOperations}
                    generalDateInput={props.generalDateInput}
                    subProcessTemplate={subProcessTemplate}
                    openModalMultiOperations={openModalMultiOperations}
                    handleEnterKeySubOperation={handleEnterKeySubOperation}
                    subOperationCustom={subOperationCustom}
                    setSubOperationCustom={setSubOperationCustom}
                    getOperationUnitTitle={getOperationUnitTitle}
                    fnAddOperation={props.fnAddOperation}
                    fnDeleteOperation={props.fnDeleteOperation}
                    isConfirmOrder={props.isConfirmOrder}
                    operationOnSelect={operationOnSelect}
                    position={props.position}
                    valueProcess={props.valueProcess}
                    valueSubProcess={props.valueSubProcess}
                    selectOperation={props.selectOperation}
                  ></SelectOperation>
                )}
              </div>
            )}
          </div>
        }

        <div className={borderClass}>
          {props.index && !isDockingAndUndockingHead && (
            <>
              {/*QTY normal*/}
              {props.isConfirmOrder || props.isRepairHistory ? (
                !props.generalDateInput ? (
                  <span className="tw-w-1/2">{props.valueUnit}</span>
                ) : props.index === 'head' ? (
                  <></>
                ) : (
                  <span>{props.selectedDay}</span>
                )
              ) : (
                <>
                  {props.generalDateInput ? (
                    <DatePicker
                      value={unitDateValue ? unitDateValue : undefined}
                      className="mr-2 px-1 py-2 border shadow-sm  row-start-2 row-end-3 tw-h-8
                            border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-md sm:text-sm focus:ring-1 text-right"
                      style={{
                        borderRadius: '0.375rem',
                      }}
                      onChange={(e) => {
                        setUnitDateValue(e ? e : undefined);
                        if (e) GeneralUnitDayChange(e.format('YYYY-MM-DD'));
                      }}
                    />
                  ) : (
                    <>
                      <input
                        defaultValue={props.valueSubProcess?.[0] ? props.valueSubProcess?.[0]?.['unit'] : ''}
                        disabled={props.valueProcess === null}
                        type="text"
                        className="mr-2 px-1 py-2 border shadow-sm  row-start-2 row-end-3 tw-h-8
              border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block tw-w-1/2 rounded-md sm:text-sm focus:ring-1 text-right"
                        value={unitValue}
                        onChange={(e) => setUnitValue(e.target.value)}
                        onBlur={() =>
                          props.handleChange(
                            { value: unitValue, processKey: props.valueProcess },
                            props.no,
                            props.parentKey,
                            props.position,
                            props.index === 'head' ? 'sub_process_qtyText_head' : 'sub_process_qtyText',
                            props.keyStructs,
                          )
                        }
                        name="qtyText"
                        id={props.parentKey + '_' + props.no + 'qtyText'}
                      />
                    </>
                  )}
                </>
              )}
              {props.isConfirmOrder || props.isRepairHistory ? (
                <>{!props.generalDateInput ? <div>{getOperationUnitTitle(props.valueUnitName)}</div> : <></>}</>
              ) : (
                <>
                  {!props.generalDateInput ? (
                    <Select
                      // name="qtySelect"
                      disabled={props.valueProcess === null}
                      value={getOperationUnitTitle(props.valueSubProcess?.[0]?.['unit_name'])}
                      id={props.parentKey + '_' + props.no + 'qtySelect'}
                      onSelect={(value: any) => unitOnSelect({ value, processKey: props.valueProcess }, null, true)}
                      className="tw-w-1/2"
                      placeholder=""
                      dropdownRender={(menu) => (
                        <div>
                          {menu}

                          <div className="tw-flex tw-p-2">
                            <Input
                              className="tw-flex-auto"
                              name="qtySelect"
                              onKeyDown={handleEnterKeySubUnit}
                              value={subUnitCustom.value}
                              onChange={(e) => setSubUnitCustom({ value: e.target.value, processKey: props.valueProcess, isCustom: true })}
                            />
                          </div>
                        </div>
                      )}
                    >
                      <Select.Option value={'set(s)'}>set(s)</Select.Option>

                      <Select.Option value={'pc(s)'}>pc(s)</Select.Option>

                      <Select.Option value={'place(s)'}>place(s)</Select.Option>

                      <Select.Option value={'m2'}>m2</Select.Option>
                    </Select>
                  ) : null}
                </>
              )}
            </>
          )}
        </div>
        {/* )} */}
        {props.isRepairHistory && (
          <div className={borderClass}>
            {props.index && (
              <>
                <div onClick={() => {}} className="flex tw-w-full tw-justify-between  tw-cursor-pointer">
                  <p
                    className="tw-w-5/6 tw-h-12 tw-py-2"
                    style={{ textOverflow: 'ellipsis', overflow: 'hidden', WebkitLineClamp: '2', display: '-webkit-box', WebkitBoxOrient: 'vertical' }}
                  >
                    Check result
                  </p>

                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mt-2.5 tw-text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </div>
              </>
            )}
          </div>
        )}
        <div className={borderClass}>
          {props.index && (
            <>
              <div className="flex tw-w-full tw-justify-between">
                <button onClick={addDirection} hidden={props.valueSubProcess?.[0]?.['direction'] || props.isConfirmOrder || props.isRepairHistory}>
                  <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M6 1V6M6 6V11M6 6H11M6 6H1" stroke={props.index === 'head' ? '#FFF' : '#CBD5E1'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>

                <p
                  className="tw-w-5/6 tw-h-12 tw-py-2 text-sm tw-cursor-po inter"
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis', WebkitLineClamp: '2', display: '-webkit-box', WebkitBoxOrient: 'vertical' }}
                  onClick={() => lookDirection(props.valueSubProcess?.[0]?.['direction'])}
                >
                  {truncateString(props.valueSubProcess?.[0]?.['direction'] || '', 100)}
                </p>

                <div
                  className="tw-cursor-pointer opacity-60 tw-py-4"
                  hidden={!props.valueSubProcess?.[0]?.['direction'] || props.isConfirmOrder || props.isRepairHistory}
                  onClick={() => editDirection(props.valueSubProcess?.[0]?.['direction'])}
                >
                  Edit
                </div>
              </div>
            </>
          )}
        </div>

        <div style={{ marginLeft: props.isSearched ? 1.2 : 0 }} className={'tw-w-1/12 tw-px-4 tw-flex-1 tw-items-center flex'}>
          <button onClick={openUploadFileModal} hidden={props.isConfirmOrder || props.isRepairHistory}>
            <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path d="M6 1V6M6 6V11M6 6H11M6 6H1" stroke={props.index === 'head' ? '#FFF' : '#CBD5E1'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>

          {props.valueSubProcess?.[0]?.images && props.valueSubProcess?.[0]?.images.length > 0 && (
            <>
              <div className="tw-ml-4 tw-relative tw-cursor-pointer" onClick={openUploadFileModal}>
                <div style={{ width: 36, height: 36, backgroundColor: 'rgba(0,0,0,0.4)' }} className="tw-absolute tw-flex tw-items-center tw-justify-center">
                  <span className="tw-text-white tw-text-xs">+{props.valueSubProcess?.[0].images.length}</span>
                </div>
                {props.valueSubProcess?.[0].images.filter((url: any) => /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg))/i.test(url)).length > 0 ? (
                  <img
                    style={{ width: 36, height: 36 }}
                    src={props.valueSubProcess?.[0].images.filter((url: any) => /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg))/i.test(url))[0]}
                    alt=""
                  />
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 36, height: 36 }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {/*Show list sub_process */}
      {props.valueSubProcess && (
        <>
          {Object.keys(props.valueSubProcess).map((processKey, i) => {
            return (
              i > 0 && (
                <OperationSubProcessComponent
                  subProcessIndex={i}
                  subProcess={props.valueSubProcess[processKey]}
                  unitTitle={getOperationUnitTitle(processKey)}
                  getOperationUnitTitle={getOperationUnitTitle}
                  isLastItem={i === Object.keys(props.valueSubProcess).length - 1}
                  rowClass={rowClass}
                  leftClass={leftClass}
                  borderClass={borderClass}
                  borderClassNotGeneral={borderClassNotGeneral}
                  isConfirmOrder={props.isConfirmOrder}
                  isRepairHistory={props.isRepairHistory}
                  index={props.index}
                  key={props.key}
                  subProcessTemplate={subProcessTemplate}
                  parentKey={props.parentKey}
                  selectOperation={props.selectOperation}
                  fnDeleteOperation={() =>
                    props.fnDeleteOperation({ no: props.no, parentKey: props.parentKey, position: props.position, processKey, keyStructs: props.keyStructs })
                  }
                  fnAddOperation={() => props.fnAddOperation({ no: props.no, keyStructs: props.keyStructs })}
                  operationOnSelect={(value: any) => operationOnSelect({ prev: processKey, value }, null, true)}
                  no={props.no}
                  handleEnterKeySubOperation={(e: any, value: any) => {
                    handleEnterKeyOperationSubProcess(e, value);
                  }}
                  processKey={processKey}
                  handleUnitValueChange={(value: any) =>
                    props.handleChange({ value: value, processKey }, props.no, props.parentKey, props.position, 'sub_process_qtyText', props.keyStructs)
                  }
                  handleEnterKeySubUnit={(e: any, value: any) => {
                    setSubUnitCustom(value);
                    handleEnterKeySubUnit(e);
                  }}
                  unitOnSelect={(value: any) => unitOnSelect({ value, processKey }, null, true)}
                  lookDirection={lookDirection}
                  addDirectionSubProcess={addDirectionSubProcess}
                  editDirection={editDirection}
                  editDirectionSubProcess={editDirectionSubProcess}
                />
              )
            );
          })}
        </>
      )}
    </>
  );
};

export default CustomRow;
