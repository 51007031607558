import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

function CompleteOrderModal() {
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(!!localStorage.getItem('orderStatus'));
  }, []);

  const onClose = () => {
    setModalOpen(false);
    localStorage.removeItem('orderStatus');
  };

  return (
    <Transition.Root show={isModalOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}

          <span className="hidden" aria-hidden="true">
            &#8203;
          </span>
          {
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle max-w-screen-xl w-full">
                <div className="flex flex-col bg-white py-4 px-8">
                  <React.Fragment>
                    <div className="flex items-center justify-end tw-text-gray-400 pb-4 tw-text-center">
                      <svg onClick={onClose} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 tw-cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </div>

                    <div className="tw-flex tw-justify-center tw-text-primary">
                      <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-28 w-28" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>

                    <div className="pb-4 tw-text-center tw-mb-5">
                      <p className="tw-font-bold tw-text-xl tw-mb-4">All the work you have to do is done.</p>

                      <p>Good job on your work!</p>
                    </div>

                    <button onClick={onClose} className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-6 py-2.5 mb-2 tw-text-primary font-bold">
                      Close
                    </button>
                  </React.Fragment>
                </div>
              </div>
            </Transition.Child>
          }
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default CompleteOrderModal;
