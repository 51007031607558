import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link, Element } from 'react-scroll';
import { getShipSpecEntity, saveShipSpecEntity, shipsActions } from '../../../store/shipsSlice';
import _ from 'lodash';
import api from '../../../api';
import { mainActions } from '../../../store/mainSlice';
import { selectLanguage } from '../../../utils/common';
import Tooltip from '../../../components/Tooltip';

function ShipSpec(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const hiddenKey = ['ship_id', 'index'];
  const [confirmed, setConfirmed] = useState(false);
  const [saveFirstTime, setSaveFirstTime] = useState(false);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'dockOrders' does not exist on type 'Defa... Remove this comment to see the full error message
  const { language } = useSelector((state) => state.dockOrders);

  const {
    selectedShip,
    shipSpecEntity,
    shipSpecElement,
    saveShipSpecEntityStatus,
    getShipSpecEntityStatus,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ships' does not exist on type 'DefaultRo... Remove this comment to see the full error message
  } = useSelector((state) => state.ships);

  useEffect(() => {
    if (selectedShip?.ship_spec?.ship_spec_id && !saveFirstTime) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      dispatch(getShipSpecEntity(selectedShip));
    }
    return () => {
      dispatch(shipsActions.setSaveShipSpecEntityStatus('idle'));
      setSaveFirstTime(false);
    };
  }, [selectedShip, dispatch]);

  useEffect(() => {
    const success = saveShipSpecEntityStatus === 'succeeded';
    const loading = getShipSpecEntityStatus === 'loading' || saveShipSpecEntityStatus === 'loading';
    dispatch(mainActions.setLoading(loading));
    setConfirmed(success);
    dispatch(shipsActions.setShipSpecComfirmed(success));
  }, [saveShipSpecEntityStatus, getShipSpecEntityStatus, dispatch]);

  const submitShipSpec = async () => {
    if (shipSpecEntity?.ship_spec_general_info?.imo_no || shipSpecEntity?.ship_spec_general_info?.sentai_no) {
      let shipSpecId = null;
      if (selectedShip?.ship_spec?.created) {
        try {
          const result = await api.ships.createShip({
            ship_name: selectedShip?.ship_spec?.ship_name,
            ship_code: shipSpecEntity?.ship_spec_general_info?.imo_no,
            sentai_no: shipSpecEntity?.ship_spec_general_info?.sentai_no,
          });

          // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type 'AxiosRes... Remove this comment to see the full error message
          if (!result?.entity) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
            alert('Error: ' + result.meta?.message);
          } else {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type 'AxiosRes... Remove this comment to see the full error message
            shipSpecId = result?.entity?.ship_spec?.ship_spec_id;
            setSaveFirstTime(true);
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type 'AxiosRes... Remove this comment to see the full error message
            dispatch(shipsActions.setSelectedShip(result?.entity));
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type 'AxiosRes... Remove this comment to see the full error message
            dispatch(shipsActions.setNewShip({ data: result?.entity, remove: true }));
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        shipSpecId = selectedShip?.ship_spec_id;
        setSaveFirstTime(false);
      }
      if (shipSpecId) {
        // ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        dispatch(
          //@ts-ignore
          saveShipSpecEntity({
            //@ts-ignore
            ship_spec_id: shipSpecId,
            //@ts-ignore
            ship_spec_entity: shipSpecEntity,
          }),
        );
      }
    } else {
      alert("'IMO No.' or 'sentai_no' is required");
    }
  };

  const getLanguageTitle = (value: any) => {
    return value[selectLanguage(language, 'ship_spec')];
  };
  const changeShipSpecEntity = (key: any, value: any) => {
    dispatch(shipsActions.setShipSpecEntity({ key, value }));
  };

  const exportExcel = () => {
    dispatch(mainActions.setLoading(true));
    api.ships
      .exportExcelSpec(selectedShip?.ship_spec?.ship_spec_id)
      .then((response) => {
        dispatch(mainActions.setLoading(false));
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'AxiosResponse<any, any>' is not assignable t... Remove this comment to see the full error message
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `export_spec_${new Date().getTime()}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        dispatch(mainActions.setLoading(false));
      });
  };

  const onBack = () => {
    setConfirmed(false);
    dispatch(shipsActions.setSaveShipSpecEntityStatus('idle'));
    history.goBack();
  };

  const onBackToEdit = () => {
    setConfirmed(false);
    dispatch(shipsActions.setShipSpecComfirmed(false));
  };

  return (
    <div className="tw-flex">
      <div className="tw-w-60 tw-bg-lightdary tw-h-screen tw-pt-14 tw-flex tw-flex-col tw-overflow-y-auto">
        <div className="logo tw-h-16 tw-font-bold tw-text-xl tw-flex tw-items-center tw-justify-center">Register / Edit ship info</div>

        <div className="tw-h-14 tw-bg-primary tw-flex tw-items-center tw-text-white">
          <div className="tw-w-14 tw-mr-1 tw-flex tw-flex-col tw-items-center tw-justify-center">
            <div className="tw-w-4 tw-h-4 tw-bg-white"></div>

            <div className="tw-text-tiny">ship spec</div>
          </div>

          <div>Ship Spec</div>
        </div>

        <div className="tw-flex tw-flex-1 tw-bg-white tw-overflow-y-auto">
          <div className="tw-w-14 tw-mr-1 tw-bg-lightdary"></div>

          <div className="tw-w-14 tw-bg-lightdary tw-flex-1 tw-overflow-y-auto tw-whitespace-nowrap">
            {Object.keys(shipSpecEntity)?.map((key) => {
              const entitiesElement = shipSpecElement.find((e: any) => e.key === key);
              // ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              const fillAll = Object.values(shipSpecEntity[key] || {})?.every((shipSpec) => (_.isObject(shipSpec) ? Object.values(shipSpec).every((e) => !!e) : !!shipSpec));
              return (
                <Link
                  activeClass="tw-bg-secondary"
                  spy={true}
                  smooth={true}
                  duration={250}
                  containerId="containerElement"
                  to={key}
                  key={key}
                  className="tw-cursor-pointer hover:tw-bg-secondary hover:tw-text-primary tw-h-10 tw-mt-0.5 tw-px-4 tw-flex tw-items-center tw-justify-between"
                >
                  <span>{getLanguageTitle(entitiesElement)}</span>

                  <span>
                    {fillAll && (
                      <svg className="tw-stroke-current tw-text-primary" width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M3.5 5L4.5 6L6.5 4M9.5 5C9.5 5.59095 9.3836 6.17611 9.15746 6.72208C8.93131 7.26804 8.59984 7.76412 8.18198 8.18198C7.76412 8.59984 7.26804 8.93131 6.72208 9.15746C6.17611 9.3836 5.59095 9.5 5 9.5C4.40905 9.5 3.82389 9.3836 3.27792 9.15746C2.73196 8.93131 2.23588 8.59984 1.81802 8.18198C1.40016 7.76412 1.06869 7.26804 0.842542 6.72208C0.616396 6.17611 0.5 5.59095 0.5 5C0.5 3.80653 0.974106 2.66193 1.81802 1.81802C2.66193 0.974106 3.80653 0.5 5 0.5C6.19347 0.5 7.33807 0.974106 8.18198 1.81802C9.02589 2.66193 9.5 3.80653 9.5 5Z"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </span>
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      <div className="tw-pt-14 tw-flex-grow tw-max-h-screen tw-flex tw-flex-col">
        <div className="header tw-h-16 tw-px-10 tw-flex tw-items-center tw-justify-between">
          <button onClick={onBack} className="tw-h-full tw-flex tw-items-center tw-text-xl focus:tw-outline-none">
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 15L1 8L8 1" stroke="#0F172A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

            <span className="tw-px-3">Back</span>
          </button>
          {confirmed ? (
            <div className={' tw-mt-2 tw-px-14 tw-items-center tw-justify-between tw-flex'}>
              <button
                onClick={exportExcel}
                className="tw-flex tw-items-center tw-rounded-xl
            tw-w-44 tw-justify-center tw-h-11 tw-border-primary tw-border-2 tw-text-primary tw-px-3 tw-font-bold focus:tw-outline-none active:tw-bg-lightdary"
              >
                Export (Excel)
              </button>

              <a href={'javascript:void(0)'} onClick={onBackToEdit} className={'tw-font-bold tw-text-primary tw-underline tw-mt-2 tw-cursor-pointer tw-ml-4'}>
                Back to Edit
              </a>
            </div>
          ) : (
            <div className={' tw-mt-2 tw-px-14 tw-flex tw-items-center tw-justify-between tw-flex'}>
              {getShipSpecEntityStatus === 'succeeded' && (
                <button
                  onClick={submitShipSpec}
                  className="tw-rounded-xl tw-flex tw-items-center tw-justify-center tw-w-44
             tw-h-11 tw-bg-primary tw-px-5 tw-text-white tw-font-bold focus:tw-outline-none"
                >
                  Save
                </button>
              )}
            </div>
          )}
        </div>

        <div className="header tw-h-14 tw-px-14 tw-flex tw-items-center tw-justify-between">
          <div className="tw-font-bold tw-text-2xl">Ship spec</div>
          {/* <button className="tw-flex tw-items-center tw-justify-center tw-h-11 tw-bg-primary tw-px-3 tw-text-white tw-font-bold">項目を追加する</button> */}
        </div>

        <Element name="" className="tw-overflow-y-auto tw-flex-1 tw-px-14" id="containerElement">
          {Object.keys(shipSpecEntity || {})
            .filter((t) => !hiddenKey.includes(t))
            .map((key, i) => {
              const element = shipSpecElement.find((e: any) => e.key === key);
              const entity = shipSpecEntity[key];
              return (
                <Element name={key} key={key} className="tw-rounded-lg tw-border-2 tw-mb-2">
                  <CustomRow no={`${i + 1}`} title={getLanguageTitle(element)} hint={element?.description || ''} />
                  {Object.keys(entity || {})
                    .filter((t) => !hiddenKey.includes(t))
                    .map((keyLv2, j) => {
                      const elementLv2 = shipSpecElement.find((e: any) => e.key === keyLv2);
                      const entityLv2 = entity[keyLv2];
                      if (typeof entityLv2 === 'object' && !Array.isArray(entityLv2) && entityLv2 !== null) {
                        return (
                          <div key={`${key}.${keyLv2}`}>
                            <CustomRow index={`${j + 1}`} no={`${i + 1}-${j + 1}`} title={getLanguageTitle(elementLv2)} hint={elementLv2?.description || ''} />
                            {Object.keys(entityLv2 || {})
                              .filter((t) => !hiddenKey.includes(t))
                              .map((keyLv3, k) => {
                                const elementLv3 = shipSpecElement.find((e: any) => e.key === keyLv3);
                                const entityLv3 = entityLv2[keyLv3];
                                return (
                                  <CustomRow
                                    lv={3}
                                    confirmed={confirmed}
                                    index={`${j + 1}`}
                                    key={`${key}.${keyLv2}.${keyLv3}`}
                                    no={`${i + 1}-${j + 1}-${k + 1}`}
                                    title={getLanguageTitle(elementLv3)}
                                    hint={elementLv3?.description || ''}
                                    inputProps={{
                                      value: entityLv3 || '',
                                      placeholder: elementLv3?.placeholder,
                                      onChange: (e: any) => changeShipSpecEntity(`${key}.${keyLv2}.${keyLv3}`, e.target.value),
                                    }}
                                  />
                                );
                              })}
                          </div>
                        );
                      }
                      return (
                        <CustomRow
                          confirmed={confirmed}
                          index={`${j + 1}`}
                          key={`${key}.${keyLv2}`}
                          no={`${i + 1}-${j + 1}`}
                          title={getLanguageTitle(elementLv2)}
                          hint={elementLv2?.description || ''}
                          inputProps={{
                            value: entityLv2 || '',
                            placeholder: elementLv2?.placeholder,
                            onChange: (e: any) => changeShipSpecEntity(`${key}.${keyLv2}`, e.target.value),
                          }}
                        />
                      );
                    })}
                </Element>
              );
            })}

          <div className="tw-h-full tw-w-full"></div>
        </Element>
      </div>
    </div>
  );
}

const CustomRow = ({ index, no, title, hint, inputProps = {}, confirmed = false, lv = 0 }: any) => {
  let rowClass = 'tw-flex tw-bg-secondary tw-items-center tw-h-12';
  let leftClass = 'tw-w-5/12 tw-border-r-2 tw-h-full tw-border-white tw-flex tw-items-center tw-px-4 tw-justify-between';
  let titleClass = 'tw-font-bold tw-flex-1';
  if (index) {
    if (index % 2 !== 0) {
      rowClass = `tw-flex tw-bg-white tw-items-center tw-h-11`;
      leftClass = `tw-w-5/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between`;
      titleClass = 'tw-flex-1';
    } else {
      rowClass = `tw-flex tw-bg-lightdary tw-items-center tw-h-11`;
      leftClass = `tw-w-5/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between`;
      titleClass = 'tw-flex-1';
    }
  }
  if (lv && lv === 3) {
    leftClass += ' tw-pl-8';
  }

  return (
    <>
      <div className={rowClass}>
        <div className={leftClass}>
          <div className="tw-w-14">{no}</div>

          <div className={titleClass}>{title}</div>

          <Tooltip content={hint || ''}>
            <div className="hint tw-cursor-help">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.83333 12.3333H9V9H8.16667M9 5.66667H9.00833M16.5 9C16.5 9.98491 16.306 10.9602 15.9291 11.8701C15.5522 12.7801 14.9997 13.6069 14.3033 14.3033C13.6069 14.9997 12.7801 15.5522 11.8701 15.9291C10.9602 16.306 9.98491 16.5 9 16.5C8.01509 16.5 7.03982 16.306 6.12987 15.9291C5.21993 15.5522 4.39314 14.9997 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801 2.0709 11.8701C1.69399 10.9602 1.5 9.98491 1.5 9C1.5 7.01088 2.29018 5.10322 3.6967 3.6967C5.10322 2.29018 7.01088 1.5 9 1.5C10.9891 1.5 12.8968 2.29018 14.3033 3.6967C15.7098 5.10322 16.5 7.01088 16.5 9Z"
                  stroke="#475569"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </Tooltip>
        </div>

        <div className="tw-px-4 tw-flex-1">
          {inputProps.onChange &&
            (confirmed ? (
              <div>{inputProps.value}</div>
            ) : (
              <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-full" type="text" {...inputProps} />
            ))}
        </div>
      </div>
    </>
  );
};

export default ShipSpec;
