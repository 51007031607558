import React, { useState } from 'react';
import './style.css';

function Tooltip(props: any) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div onMouseOver={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)} className="tooltip-container">
      {isOpen && <div className="tooltip-content top">{props.content}</div>}
      {props.children}
    </div>
  );
}

export default Tooltip;
