import request from '../../utils/request';

const login = (data: any) => request.post('api/v1/auth', data);
const changePassword = (data: any) => request.post('api/v1/users/change_password', data);
const delUser = (data: any) => request.post('api/v1/users/delete_user', data);
const addUser = (data: any) => request.post(`api/v1/users/create_user`, data);
const editUser = (data: any) => request.post(`api/v1/users/edit_user`, data);

const auth = {
  login,
  changePassword,
  delUser,
  addUser,
  editUser,
};

export default auth;
