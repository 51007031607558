import RadioButton from 'atoms/RadioButton';

export type StatutorySurveyItemKey =
  | 'load_line'
  | 'safety_construction'
  | 'safety_equipment'
  | 'safety_radio'
  | 'chemical_fitness_cert'
  | 'gas_fitness_cert'
  | 'dangerous_goods_fitness'
  | 'oil_pollution_prevention'
  | 'noxious_liquid_substances'
  | 'sewage_pollution_prevention'
  | 'air_pollution_prevention'
  | 'energy_efficiency'
  | 'anti_fouling_system'
  | 'ballast_water_management';

export type StatutorySurveyOption = 'Initial' | 'Renewal' | 'Intermediate' | 'Periodical' | 'Annual' | 'Occasional';

export type StatutorySurveyItemProps = {
  itemKey: StatutorySurveyItemKey;
  label: string;
  leftItemValue: boolean;
  onChangeLeftItemValue: React.ChangeEventHandler<HTMLInputElement>;
  rightItemValue: string | null;
  onChangeRightItemValue: React.ChangeEventHandler<HTMLInputElement>;
  options: StatutorySurveyOption[];
  bgColor: string;
};

export function StatutorySurveyItem({ itemKey, label, leftItemValue, onChangeLeftItemValue, rightItemValue, onChangeRightItemValue, options, bgColor }: StatutorySurveyItemProps) {
  const buildQualifiedOptionName = (option: StatutorySurveyOption) => `${itemKey}_${option.toLocaleLowerCase()}`;

  return (
    <div className={`tw-bg-slate-200 tw-grid tw-grid-cols-2 ${bgColor}`}>
      <div className=" tw-border-r-2 tw-border-secondary tw-p-2 ">
        <div className="tw-form-check tw-flex tw-items-center">
          <input className="tw-mr-2 tw-p-1" type="checkbox" value={itemKey} id={itemKey} name={itemKey} checked={leftItemValue} onChange={onChangeLeftItemValue} />
          <label className="tw-form-check-label tw-inline-block" htmlFor={itemKey}>
            {label}
          </label>
        </div>
      </div>
      <div className=" tw-col-span-1">
        <div className="tw-grid tw-grid-cols-3 tw-flex" onChange={onChangeRightItemValue}>
          {options
            .map((option) => [option, buildQualifiedOptionName(option)])
            .map(([option, qualifiedOptionName]) => (
              <div className="tw-form-check tw-p-2">
                <RadioButton isChecked={rightItemValue === option} value={option} id={qualifiedOptionName} label={option} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
