import { configureStore } from '@reduxjs/toolkit';
import mainReducers from './mainSlice';
import shipsReducers from './shipsSlice';
import dockOrdersReducers from './dockOrderSlice';
import usersReducers from './userSlice';
import organizationReducers from './organizationSlice';

export default configureStore({
  reducer: {
    main: mainReducers,
    ships: shipsReducers,
    dockOrders: dockOrdersReducers,
    users: usersReducers,
    organization: organizationReducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
