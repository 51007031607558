import request from '../../utils/request';
import DockOrderListDrafts from './dockOrderListDrafts.json';

type DockOrderListDraft = typeof DockOrderListDrafts;

const getDockOrder = (ship_id: any, dock_order_id: any) => request.get('api/v1/dock_order/dock_order/get', { params: { ship_id, dock_order_id } });
const getDockOrderDraft = async (ship_id: string, dock_order_id?: string) =>
  await request.get<DockOrderListDraft>('api/v1/dock_order/dock_order/list_draft', { params: { ship_id, dock_order_id } });

const getDockOrderElement = () => request.get('api/v1/dock_order/dock_order_element');
const saveDraft = (data: any) => request.post('api/v1/dock_order/dock_order', data);
const updateDraftToFinal = (id: any) => request.post(`api/v1/dock_order/dock_order/${id}/store`);
const getDockOrderTemplate = (ship_type_code: any) => request.get('api/v1/dock_order/dock_order_template', { params: { ship_type_code } });
const createDockOrderElementCustom = (data: any) => request.post('api/v1/dock_order/dock_order_element_custom', data);
const removeCurrentDraft = (id: any) => request.delete(`api/v1/dock_order/dock_order/${id}`);
const createOperationOrUnitCustom = (data: any) => request.post('api/v1/operation_unit_element_custom', data);
const getOperationUnitElement = () => request.get('api/v1/operation_unit_element_custom');
const exportExcelApplication = (ship_id: any) => request.get('api/v1/ship_info/ship_structure/export_excel_application', { params: { ship_id: ship_id }, responseType: 'blob' });

const dockOrderApis = {
  saveDraft,
  getDockOrder,
  getDockOrderDraft,
  getDockOrderElement,
  getDockOrderTemplate,
  createDockOrderElementCustom,
  updateDraftToFinal,
  removeCurrentDraft,
  createOperationOrUnitCustom,
  getOperationUnitElement,
  exportExcelApplication,
};

export default dockOrderApis;
