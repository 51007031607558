import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { shipsActions } from '../../../store/shipsSlice';
import api from '../../../api';
import _ from 'lodash';
import SaveDraftModal from '../../../components/SaveDraftModal';
import { convertTimeZoneJapan } from '../../../utils/common';
import { mainActions } from '../../../store/mainSlice';
import Loading from 'atoms/Loading';

const PURPOSES = [
  { title: 'Annual Survey', isChoose: true },
  { title: 'Intermediate Survey', isChoose: false },
  { title: 'Special Survey', isChoose: false },
  { title: 'Other', isChoose: false },
];

const SPECIFICATIONS = [
  {
    key: 'main_engine',
    title: 'Main engine',
    items: [
      { key: 'Model', value: 'XXX vessels industry' },
      { key: 'Serial no.', value: '1234567890A' },
      { key: 'Cylinder', value: '6' },
    ],
  },
  {
    key: 'generator_engine',
    title: 'Generator engine',
    items: [
      { key: 'Model', value: 'XXX vessels industry' },
      { key: 'Serial no.', value: '1234567890B' },
      { key: 'Cylinder', value: '2' },
    ],
  },
];

function DocOrder(props: any) {
  const history = useHistory();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ships' does not exist on type 'DefaultRo... Remove this comment to see the full error message
  const { shipList, dockOrderDraft, selectedShip } = useSelector((state) => state.ships);
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalConfirm, setModalConfirm] = useState(false);
  const [shipName, setShipName] = useState(null);
  const [shipCode, setShipCode] = useState(null);
  const [draftId, setDraftId] = useState(null);
  const [purposeOfOrder, setPurposeOfOrder] = useState('Annual Survey');
  const [specList, setSpecList] = useState([]);
  const [lastEdited, setLastEdited] = useState(null);
  const [updateSpecIndex, setUpdateSpecIndex] = useState(0);
  const [updateItemIndex, setUpdateItemIndex] = useState(0);
  const [inputUpdateSpecValue, setInputUpdateSpecValue] = useState('');
  const [isOpenEditSpec, setOpenEditSpec] = useState(false);
  const [openDraftModal, setOpenDraftModal] = useState(false);

  const [dockOrderDefault, setDockOrderDefault] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function InitialComponent() {
      const dockOrderDefaultInfo = {
        ship_id: selectedShip?.ship_id,
        dock_order_id: '',
        application: {},
        dock_order_entity: {},
        basic_info: {
          dock_order_purpose: 'Annual Survey',
          specifications: SPECIFICATIONS,
        },
      };
      setDockOrderDefault(dockOrderDefaultInfo);
      if (selectedShip) {
        try {
          setIsLoading(true);
          const listDrafts = await api.ships.getListDraft(selectedShip?.ship_id);
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'length' does not exist on type 'AxiosRes... Remove this comment to see the full error message
          if (listDrafts !== null && listDrafts.length > 0) {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            const draft = listDrafts[0];
            const dockOrder = {
              ship_id: selectedShip?.ship_id,
              dock_order_id: draft.dock_order_id,
              application: draft.application,
              dock_order_entity: draft.dock_order_entity,
              basic_info: draft.basic_info,
            };
            dispatch(shipsActions.setDockOrderDraft(dockOrder));

            setModalOpen(true);

            setShipName(draft?.ship?.ship_spec?.ship_name);
            setShipCode(draft?.ship?.ship_spec?.ship_code);
            setPurposeOfOrder(draft.basic_info.dock_order_purpose ? draft.basic_info.dock_order_purpose : 'Annual Survey');
            setSpecList(draft.basic_info.specifications ? draft.basic_info.specifications : SPECIFICATIONS);
            const updateAt = moment(convertTimeZoneJapan(draft?.update_at)).format('YYYY/MM/DD');
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            setLastEdited(updateAt);
            setDraftId(draft?.dock_order_id);
          } else if (dockOrderDraft && dockOrderDraft.ship_id === selectedShip?.ship_id) {
            const basicInfo = dockOrderDraft.basic_info;
            setPurposeOfOrder(basicInfo.dock_order_purpose ? basicInfo.dock_order_purpose : 'Annual Survey');
            setSpecList(basicInfo.specifications ? basicInfo.specifications : SPECIFICATIONS);
            setDraftId(dockOrderDraft.dock_order_id);
          } else {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ key: string; title: string; it... Remove this comment to see the full error message
            setSpecList(SPECIFICATIONS);
            dispatch(shipsActions.setDockOrderDraft(dockOrderDefaultInfo));
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      }
    }
    InitialComponent();
  }, [selectedShip]);

  const handleChangeShip = (event: any) => {
    let ship_code = event.target.value;
    shipList.map((item: any) => {
      if (ship_code === item.ship_spec.ship_code) {
        dispatch(shipsActions.setSelectedShip(item));
      }
    });
  };

  const onChangePurpose = (purpose: any) => {
    // let puposes = purposeList.map(item => {
    //   item.isChoose = item.title === purpose.title;
    //   return item;
    // });
    // setPurposeList([...puposes]);
    setPurposeOfOrder(purpose.title);
  };

  const gotoCreateDocOrderScreen = () => {
    history.push(`/dock-order`);
  };

  const gotoApplicationForm = () => {
    saveTempDockOrder();
    history.push(`/application-form`);
  };

  const saveTempDockOrder = () => {
    let dockOrder = _.cloneDeep(dockOrderDraft);
    dockOrder.basic_info.specifications = _.cloneDeep(specList);
    dockOrder.basic_info.dock_order_purpose = purposeOfOrder;
    dispatch(shipsActions.setDockOrderDraft(dockOrder));
  };

  const continueEditing = () => {
    setModalOpen(false);
    // history.push(`/basic-info`);
    // history.push(`/application-form?id=${draftId}`);
  };

  const openEditSpecModal = async (specIndex: any, itemIndex: any) => {
    setOpenEditSpec(true);
    setUpdateSpecIndex(specIndex);
    setUpdateItemIndex(itemIndex);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'items' does not exist on type 'never'.
    setInputUpdateSpecValue(specList[specIndex].items[itemIndex].value);
    setModalOpen(true);
  };

  const saveSpecValue = () => {
    setModalOpen(true);
    let specs = _.cloneDeep(specList);
    // @ts-ignore
    specs[updateSpecIndex].items[updateItemIndex].value = inputUpdateSpecValue;
    setSpecList(specs);
    setModalOpen(false);
  };

  const saveDraft = async () => {
    let dockOrder = _.cloneDeep(dockOrderDraft);
    dockOrder.basic_info = {
      dock_order_purpose: purposeOfOrder,
      specifications: specList,
    };
    dockOrder.ship_id = selectedShip?.ship_id;
    dockOrder.is_draft = true;

    try {
      dispatch(mainActions.setLoading(true));
      let response = await api.dockOrderApis.saveDraft(dockOrder);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
      if (response.meta.status === 'success') {
        dispatch(mainActions.setLoading(false));
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type 'AxiosRes... Remove this comment to see the full error message
        setDraftId(response.entity.dock_order_id);
        setOpenDraftModal(true);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type 'AxiosRes... Remove this comment to see the full error message
        dispatch(shipsActions.setDockOrderDraft(response.entity));
        // localStorage.setItem('openDraft', true);
        // history.push(`/dashboard`);
      } else {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
        alert(response.meta.message);
      }
    } catch (err) {
      console.error(err);
      dispatch(mainActions.setLoading(false));
    }
  };

  const removeDraft = async () => {
    setModalOpen(false);
    setModalConfirm(false);
    try {
      let response = await api.dockOrderApis.removeCurrentDraft(draftId);
      dispatch(shipsActions.setDockOrderDraft(dockOrderDefault));
      history.push('/basic-info');
    } catch (e) {
      alert("Can't remove draft");
    }
  };

  const goBackHome = () => {
    saveTempDockOrder();
    if (window.confirm('Are you sure you want to discard updated information?')) {
      dispatch(shipsActions.setDockOrderDraft(dockOrderDefault));
      history.goBack();
    }
  };

  const onCloseDraftModal = () => {
    dispatch(shipsActions.setDockOrderDraft(dockOrderDefault));
    history.push('/dashboard');
  };

  const onContinueEdit = () => {
    setOpenDraftModal(false);
  };

  return (
    <>
      {openDraftModal && <SaveDraftModal isOpen={openDraftModal} onClose={onCloseDraftModal} onContinue={onContinueEdit} />}
      <Loading isLoading={isLoading}></Loading>
      <div className="tw-flex">
        <Transition.Root show={isModalOpen} as={React.Fragment}>
          <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setModalOpen}>
            <div className="flex items-center justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
              {/* This element is to trick the browser into centering the modal contents. */}

              <span className="hidden" aria-hidden="true">
                &#8203;
              </span>
              {!isOpenEditSpec && (
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4"
                  enterTo="opacity-100 translate-y-0"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-4"
                >
                  <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle max-w-screen-xl w-full">
                    <div className="flex flex-col bg-white py-4 px-8">
                      {isModalConfirm ? (
                        <React.Fragment>
                          <div className="tw-font-bold tw-text-xl pt-10 pb-3 w-full text-center">You are trying to discard current draft. Are you sure?</div>

                          <div className="flex flex-row justify-center px-3 py-3 mb-5">
                            <button
                              onClick={() => removeDraft()}
                              className="d-flex items-center self-center tw-rounded-lg tw-border-2 tw-border-primary px-8 py-3 mb-2 mr-2 tw-text-white tw-bg-primary"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none" className="mr-4">
                                <path d="M1 7L5 11L15 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              Continue and Create new order
                            </button>

                            <button
                              onClick={() => setModalOpen(false)}
                              className="d-flex items-center self-center tw-rounded-lg tw-border-2 tw-border-primary px-16 py-3 mb-2 tw-text-primary"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" className="mr-4">
                                <path d="M1 1L13 13M1 13L13 1L1 13Z" stroke="#5C6BC0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              Cancel
                            </button>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div className="tw-font-bold tw-text-xl pb-4">You have an unordered draft.</div>

                          <div className="flex flex-row justify-between content-center px-3 py-4 mb-5 tw-rounded-xl tw-border-2 border-slate-500">
                            <div className="flex flex-col">
                              <span className="text-sm mb-2 text-gray-400">Ship</span>

                              <div className="flex items-center">
                                <span className="text-lg">{shipName}</span>
                                {shipCode ? <span className="text-sm ml-2 text-gray-400">({shipCode})</span> : null}
                              </div>
                            </div>

                            <div className="flex flex-col">
                              <span className="text-sm mb-2 text-gray-400">Purpose of order</span>

                              <span className="text-lg">{purposeOfOrder}</span>
                            </div>

                            <div className="flex flex-col">
                              <span className="text-sm mb-2 text-gray-400">Last edited</span>

                              <span className="text-lg">{lastEdited}</span>
                            </div>
                            <div className="flex flex-col"></div>
                          </div>
                          <div className="flex flex-row justify-between content-center">
                            <div className="flex flex-col">
                              <button onClick={continueEditing} className="tw-rounded-lg h-full tw-bg-primary px-6 py-2.5 mb-2 tw-text-white tw-font-bold ">
                                Continue Editing
                              </button>
                            </div>
                            <div className="flex flex-col">
                              <button
                                onClick={() => setModalConfirm(true)}
                                className="self-center tw-rounded-lg tw-border-2 tw-border-red-600 px-6 py-2.5 mb-2 tw-text-red-600 font-bold"
                              >
                                Discard current draft and Create new order
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </Transition.Child>
              )}

              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
              >
                <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle">
                  {isOpenEditSpec && (
                    <div className="flex flex-col bg-white py-4 px-8">
                      <React.Fragment>
                        <div className="">
                          <div className="flex flex-col bg-white py-4 px-8">
                            <div className="tw-font-bold tw-text-xl pb-3">
                              {/* // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type 'never'. */}
                              {
                                // @ts-ignore
                                specList[updateSpecIndex].title
                              }{' '}
                              -{' '}
                              {
                                // @ts-ignore
                                specList[updateSpecIndex].items[updateItemIndex].key
                              }
                            </div>

                            <div className="flex flex-row content-center py-4 mb-3">
                              <div className="flex flex-col tw-w-full">
                                <div className="tw-font-bold tw-text-lg pb-2">Value</div>

                                <input
                                  className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-full px-3 py-4"
                                  type="text"
                                  value={inputUpdateSpecValue}
                                  onChange={(event) => setInputUpdateSpecValue(event.target.value)}
                                />
                              </div>
                            </div>

                            <div className="flex justify-end">
                              <button
                                onClick={() => saveSpecValue()}
                                className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-9 py-2.5 mb-2 tw-text-white font-bold tw-bg-primary mr-2"
                              >
                                Save
                              </button>

                              <button
                                onClick={() => {
                                  setModalOpen(false);
                                }}
                                className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-6 py-2.5 mb-2 tw-text-primary font-bold"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    </div>
                  )}
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="tw-pt-14 tw-flex-grow tw-max-h-screen tw-flex tw-flex-col">
          <div className="header tw-h-16 tw-px-44 tw-flex tw-items-center tw-justify-between">
            <button
              onClick={() => {
                goBackHome();
              }}
              className="tw-h-full tw-flex tw-items-center tw-text-xl active:tw-bg-secondary"
            >
              <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 15L1 8L8 1" stroke="#0F172A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              <span className="tw-px-3">Back</span>
            </button>

            <div className={'tw-mt-30 tw-ml-40'}>
              <ul className="w-full steps tw-mt-5">
                {/*<li className="step step-info" data-content="✓">Basic info</li>*/}

                <li className="step step-success">Basic info</li>

                <li className="step">Application form</li>

                <li className="step">Dock order</li>
              </ul>
            </div>

            <button
              onClick={saveDraft}
              className="tw-flex tw-items-center tw-justify-center tw-h-11 tw-rounded-2xl tw-border-2
         tw-border-primary tw-px-3 tw-text-primary"
            >
              Save Draft
            </button>
          </div>

          <div className="header tw-mt-5 tw-h-14 tw-px-44 tw-flex tw-items-center tw-justify-between">
            <div className="tw-font-bold tw-text-2xl">Create dock order</div>
          </div>

          <div className="tw-pt-10 tw-px-44 tw-mb-3">
            {selectedShip && (
              <div className={'tw-grid tw-grid-cols-6 tw-gap-4'}>
                <div className={'tw-col-span-1'}>
                  <h3 className="tw-font-bold">Ship</h3>
                </div>

                <div className="tw-col-start-2 tw-col-end-4">
                  <select
                    className="tw-w-full tw-p-2 tw-border-secondary tw-border tw-rounded"
                    onChange={(event) => {
                      handleChangeShip(event);
                    }}
                    value={selectedShip.ship_spec.ship_code}
                  >
                    {shipList.map((ship: any) => {
                      return (
                        <option value={ship.ship_spec?.ship_code}>
                          {ship.ship_spec?.ship_name} ({ship.ship_spec?.ship_code})
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}

            <div className="th tw-flex tw-border-secondary tw-border-b-2 tw-px-5 tw-my-3" />

            <div className={'tw-grid tw-grid-cols-6 tw-gap-4'}>
              <div className={'tw-col-span-1'}>
                <h3 className="tw-font-bold">Purpose of order</h3>
              </div>

              <div className={'tw-col-start-2 tw-col-end-7'}>
                <div className="tw-grid tw-grid-cols-2 tw-gap-3">
                  {PURPOSES.map((pur) => {
                    if (pur.title === purposeOfOrder) {
                      return (
                        <div
                          className="tw-bg-white tw-cursor-pointer
                 tw-rounded-xl tw-text-black tw-text-2xl border-4 tw-border-purple-400
                 tw-flex tw-items-center tw-py-7 tw-font-bold"
                        >
                          <span className="tw-rounded-full tw-bg-primary tw-w-5 tw-h-5 tw-mx-4" />
                          {pur.title}
                        </div>
                      );
                    }

                    return (
                      <div
                        className="tw-bg-white tw-border-secondary tw-border tw-cursor-pointer tw-rounded-xl tw-text-black tw-text-2xl
                   tw-flex tw-items-center tw-py-7 tw-font-bold tw-text-gray-300"
                        onClick={() => onChangePurpose(pur)}
                      >
                        <span className="tw-rounded-full tw-border tw-border-secondary tw-w-5 tw-h-5 tw-mx-4" />
                        {pur.title}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="th tw-flex tw-border-secondary tw-border-b-2 tw-px-5 tw-my-3" />

            <div className={'tw-grid tw-grid-cols-6'}>
              <div className={'tw-col-span-1'}>
                <h3 className="tw-font-bold">Specification</h3>
              </div>
              {specList.map((spec, specIndex) => {
                return (
                  <div className={'tw-col-start-2 tw-col-end-7'} key={'spec_' + specIndex}>
                    <div
                      className="tw-flex-1 tw-overflow-y-auto tw-whitespace-nowrap tw-rounded-xl tw-mb-1"
                      id={
                        // @ts-ignore
                        spec.key
                      }
                    >
                      <div
                        className="th accordion-button tw-bg-secondary tw-cursor-pointer tw-text-black tw-p-2 tw-text-black"
                        // ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type 'never'.
                        data-bs-toggle="collapse"
                        data-bs-target={
                          // @ts-ignore
                          '#collapseZero_' + spec.key
                        }
                        // ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type 'never'.
                        aria-expanded="true"
                        aria-controls={
                          // @ts-ignore
                          'collapseZero_' + spec.key
                        }
                      >
                        {/* // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type 'never'. */}
                        {
                          // @ts-ignore
                          spec.title
                        }
                      </div>

                      <table
                        className="tw-table tw-w-full accordion-collapse collapse show"
                        id={
                          // @ts-ignore
                          'collapseZero_' + spec.key
                        }
                        aria-labelledby="headingOne"
                      >
                        <tbody>
                          {/* // @ts-expect-error ts-migrate(2339) FIXME: Property 'items' does not exist on type 'never'. */}
                          {
                            // @ts-ignore
                            spec.items.map((item: any, itemIndex: any) => {
                              return (
                                <tr className={'tw-bg-lightdary'} key={'spec_item_' + itemIndex}>
                                  <td className="tw-border-r-2 tw-border-secondary tw-p-2">{item.key}</td>

                                  <td className="tw-px-2">{item.value}</td>

                                  <td className={'tw-text-right tw-px-2'}>
                                    <button className={'tw-text-primary'} onClick={() => openEditSpecModal(specIndex, itemIndex)}>
                                      Edit
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className={'tw-text-center tw-w-full tw-my-5'}>
              <button
                // onClick={gotoCreateDocOrderScreen}
                onClick={gotoApplicationForm}
                className="tw-w-full tw-rounded-2xl tw-items-center
           tw-justify-center tw-h-11 tw-bg-primary tw-px-3 tw-text-white tw-font-bold"
                style={{ margin: '0 auto' }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocOrder;
