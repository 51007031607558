import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from '../utils/common';

// handle the public routes
// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'Component' implicitly has an 'any... Remove this comment to see the full error message
function PublicRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={(props: any) => (!getToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/dashboard' }} />)} />;
}

export default PublicRoute;
