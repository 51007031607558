import request from '../../utils/request';
import mockTemplate from './mockTemplate';
const getList = (params: any) => request.get('api/v1/ship_info/ship', { params });
const getHistory = (ship_id: any) => request.get('api/v1/dock_order/dock_order', { params: { ship_id } });
const getShipSpecEntity = (ship_spec_id: any) => request.get('api/v1/ship_info/ship_spec_entity', { params: { ship_spec_id } });
/*
  mock for "ship spec template" because api not working

  extract => const getShipSpecTemplate = (ship_type_code) => request.get('api/v1/ship_info/ship_spec_template', { params: { ship_type_code } });
 */
const getShipSpecTemplate = (ship_type_code: any) => Promise.resolve(mockTemplate);
const getShipSpecElement = () => request.get('api/v1/ship_info/ship_spec_element');
const importFromExcel = (data: any) => request.post('api/v1/dock_order_new/upload_excel', data);
const storeShipSpecEntity = (data: any) => request.post('api/v1/ship_info/ship_spec_entity', data);
const getListDraft = (ship_id: any) => request.get('api/v1/dock_order/dock_order/list_draft', { params: { ship_id } });
const createShip = (data: any) => request.post('api/v1/ship_info/ship', data);
const exportExcelStruct = (ship_id: any) => request.get('api/v1/ship_info/ship_structure/export_excel', { params: { ship_id: ship_id }, responseType: "blob" })
const exportExcelSpec = (ship_id: any) => request.get('api/v1/ship_info/ship_spec_entity/export_excel', { params: { ship_spec_id: ship_id }, responseType: "blob" })
const createShipSpec = (data: any) => request.post('api/v1/ship_info/ship_spec', data);

const shipApis = {
  getList,
  getHistory,
  importFromExcel,
  getShipSpecEntity,
  getShipSpecTemplate,
  getShipSpecElement,
  storeShipSpecEntity,
  getListDraft,
  createShip,
  exportExcelStruct,
  exportExcelSpec,
  createShipSpec
};

export default shipApis;
