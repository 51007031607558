import { createSlice } from '@reduxjs/toolkit';
import { getUser, removeUserSession, setUserSession } from '../utils/common';

type State = {
  userInfo: any;
  language: string;
  loading: boolean;
  directionDataModal: {
    isOpen: boolean;
    item: any;
  };
  directionSubProcessDataModal: {
    isOpen: boolean;
    item: any;
  };
  uploadDataModal: {
    isOpen: boolean;
    item: any;
  };
  addItemModal: {
    isOpen: boolean;
    item: any;
  };
  deleteItemModal: {
    isOpen: boolean;
    item: any;
  };
  operationTableModal: {
    isOpen: boolean;
    item: any;
  };
};

const initState: State = {
  userInfo: getUser(),
  language: 'eng',
  loading: false,
  directionDataModal: {
    isOpen: false,
    item: null,
  },
  directionSubProcessDataModal: {
    isOpen: false,
    item: null,
  },
  uploadDataModal: {
    isOpen: false,
    item: null,
  },
  addItemModal: {
    isOpen: false,
    item: null,
  },
  deleteItemModal: {
    isOpen: false,
    item: null,
  },
  operationTableModal: {
    isOpen: false,
    item: null,
  },
};

const mainSlice = createSlice({
  name: 'main',
  initialState: initState,
  reducers: {
    loginSuccess(state, action) {
      state.userInfo = action.payload;
    },
    setUserInfo(state, action) {
      state.userInfo = { ...state.userInfo, ...action.payload };
      setUserSession(null, state.userInfo);
    },
    logout(state) {
      removeUserSession();
      state.userInfo = null;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setDirectionDataModal: (state, action) => {
      state.directionDataModal = action.payload;
    },
    setDirectionSubProcessDataModal: (state, action) => {
      state.directionSubProcessDataModal = action.payload;
    },
    setUploadDataModal: (state, action) => {
      state.uploadDataModal = action.payload;
    },
    setAddItemModal: (state, action) => {
      state.addItemModal = action.payload;
    },
    setDeleteItemModal: (state, action) => {
      state.deleteItemModal = action.payload;
    },
    setOperationTableModal: (state, action) => {
      state.operationTableModal = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

const { actions, reducer } = mainSlice;

export const mainActions = actions;

export default reducer;
