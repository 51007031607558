import { Dialog, Transition } from '@headlessui/react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from '../../../../../store/mainSlice';
import { shipsActions } from '../../../../../store/shipsSlice';
import { getArrayObjectOrderByIndex, selectLanguage } from '../../../../../utils/common';
import DeleteOperationModal from './DeleteOperationModal';

function OperationTableModal(props: any) {
  const dispatch = useDispatch();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  const { operationTableModal } = useSelector((state) => state.main);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ships' does not exist on type 'DefaultRo... Remove this comment to see the full error message
  const { dockOrderDraft } = useSelector((state) => state.ships);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'dockOrders' does not exist on type 'Defa... Remove this comment to see the full error message
  const { language } = useSelector((state) => state.dockOrders);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteOperationItem, setDeleteOperationItem] = useState(null);
  const [addItemNo, setAddItemNo] = useState({});
  const [selectOperation, setSelectOperation] = useState({});
  const [numberOperationNo, setNumberOperationNo] = useState(null);
  const [operationMultiData, setOperationMultiData] = useState({});
  const [dockElementDict, setdockerElementDict] = useState({});

  useEffect(() => {
    if (operationTableModal) {
      setIsOpen(operationTableModal.isOpen);
      setAddItemNo(operationTableModal.item?.addItemNo || {});
      setSelectOperation(operationTableModal.item?.selectOperation || {});
      setNumberOperationNo(operationTableModal.item?.numberOperationNo);
      setOperationMultiData(operationTableModal.item?.operationMultiData || {});
      setdockerElementDict(operationTableModal.item?.dockElementDict || {});
    }
  }, [operationTableModal]);

  const onCloseModal = () => {
    dispatch(
      mainActions.setOperationTableModal({
        isOpen: false,
        item: null,
      }),
    );
  };

  const getEngTitle = (key: any) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return dockElementDict[key] ? dockElementDict[key][selectLanguage(language, "dock_order")] : key;
  };

  const calculateMultiOperationRowSpan = (data: any) => {
    let rowSpan = 1;
    if (data?.parts) {
      rowSpan += Object.keys(data?.parts).length;
      Object.keys(data?.parts).map((k) => {
        if (data?.parts[k]?.parts) {
          rowSpan += calculateMultiOperationRowSpan(data?.parts[k]) - 1;
        }
      });
    }
    return rowSpan;
  };

  // @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
  const renderMultiOperationChildView = (childParts: any, operationData: any, operationPath: any, paddingLeft: any) => {
    if (childParts && operationData) {
      const keys = childParts ? getArrayObjectOrderByIndex(childParts) : [];
      return keys.map((child) => {
        return (
          <>
            <tr>
              <td style={{ paddingLeft: paddingLeft * 0.75 + 'rem' }} className={`tw-border tw-border-secondary tw-text-sm`}>
                {getEngTitle(child)}
              </td>
              {Object.keys(selectOperation).map((operationKey) => (
                <td className="tw-text-center tw-text-sm tw-border tw-border-secondary">
                  <div className="form-check form-check-inline tw-mr-0 mb-0 tw-min-h-0">
                    <input
                      name={`ckb_operation_${operationKey}_${child}`}
                      checked={!!operationData['childs'][child][operationKey]}
                      onChange={(e) => {
                        const prev = _.cloneDeep(operationMultiData);
                        const current = _.get(prev, operationPath);
                        // @ts-ignore
                        current[child][operationKey] = e.target.checked;
                        setOperationMultiData({ ...prev });
                      }}
                      className="form-check-input appearance-none tw-h-5 tw-w-5 border border-gray-300 rounded-sm tw-bg-white focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                      type="checkbox"
                    />
                  </div>
                </td>
              ))}
            </tr>
            {childParts[child]?.parts &&
              renderMultiOperationChildView(childParts[child]?.parts, operationData['childs'][child], `${operationPath}.${child}.childs`, paddingLeft + 1)}
          </>
        );
      });
    }
  };

  const addNodeMultiOperation = () => {
    let originData = _.cloneDeep(operationMultiData);
    const newNumber = Object.keys(originData).length + 1;
    const mainKey = Object.keys(originData).sort()[0].replace('_no1', '');
    //@ts-ignore
    const newItem = _.cloneDeep(originData[Object.keys(originData).sort()[0]]);
    const dockDraft = _.cloneDeep(dockOrderDraft);
    dockDraft.basic_info.specifications[0].items[2]['value'] = newNumber;
    dispatch(shipsActions.setDockOrderDraft(dockDraft));
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    setNumberOperationNo(newNumber);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    resetMultiOperationData(newItem);
    originData = { ...originData, [`${mainKey}_no${newNumber}`]: { ...newItem } };
    setOperationMultiData(originData);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'updated' does not exist on type '{}'.
    if (addItemNo?.updated && addItemNo?.node) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type '{}'.
      const newNumber = Object.keys(addItemNo?.node).length + 1;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type '{}'.
      const newItem = _.cloneDeep(addItemNo.node[Object.keys(addItemNo?.node)[0]]);
      newItem['sub_process'] = {};
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type '{}'.
      addItemNo.node = Object.assign({ [`${newItem.main_key}_no${newNumber}`]: { ...newItem, isCustom: true } }, addItemNo?.node);
      setAddItemNo(addItemNo);
    }
    setTimeout(() => {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      document.getElementById('tbMultiOperationEdit').scrollTo(
        0,
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        document.getElementById('tbMultiOperationEdit').scrollHeight,
      );
    }, 100);
  };

  const resetMultiOperationData = (childParts: any, child: any) => {
    if (childParts) {
      Object.keys(childParts)
        .filter((t) => t !== 'childs')
        .map((k) => {
          if (!child) {
            childParts[k] = false;
          } else {
            Object.keys(selectOperation).map((t) => {
              childParts[k][t] = false;
            });
          }
          if (childParts?.childs) {
            resetMultiOperationData(childParts?.childs, true);
          }
        });
    }
  };

  const onClear = () => {
    let originData = {};
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentKey' does not exist on type '{}'.
    const currentKey = addItemNo?.currentKey;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'treeDataPart' does not exist on type '{}... Remove this comment to see the full error message
    const treeDataPart = addItemNo?.treeDataPart;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'treeDataParent' does not exist on type '... Remove this comment to see the full error message
    const treeDataParent = addItemNo?.treeDataParent;
    if (currentKey && treeDataPart && treeDataParent) {
      const nodes = Object.keys(treeDataParent.parts).filter((n) => n.includes(currentKey) && n.includes('_no') && n !== currentKey);
      if (nodes && nodes.length > 0) {
        nodes.map((n) => {
          const pos = n
            .replace(new RegExp(currentKey + '_', 'g'), '')
            .split('_')
            .map((n) => parseInt(n));
          const subNodePart = treeDataParent?.parts[n];
          const keyName = `${currentKey}_no${pos[0]}`;
          let obj = { [keyName]: {} };
          Object.keys(selectOperation).map((k) => {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            obj[keyName][k] = false;
          });
          if (subNodePart?.parts) {
            setOriginDataOperationChild(subNodePart?.parts, obj[keyName], false, selectOperation);
          }
          originData = { ...originData, ...obj };
          if (pos.length > 1) {
            for (let i = 1; i < pos.length; i++) {
              const cloneObj = _.cloneDeep(obj[keyName]);
              const nextObj = { [`${currentKey}_no${pos[i]}`]: { ...cloneObj } };
              originData = { ...originData, ...nextObj };
            }
          }
        });
      } else {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        for (let i = 0; i < numberOperationNo; i++) {
          const keyName = `${currentKey}_no${i + 1}`;
          let obj = { [keyName]: {} };
          Object.keys(selectOperation).map((k) => {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            obj[keyName][k] = false;
          });
          if (treeDataPart?.parts) {
            setOriginDataOperationChild(treeDataPart?.parts, obj[keyName], false, selectOperation);
          }
          originData = { ...originData, ...obj };
        }
      }
      setOperationMultiData(originData);
    }
  };

  const setOriginDataOperationChild = (childParts: any, data: any, processData: any, operationTempData: any) => {
    if (childParts && data) {
      data['childs'] = {};
      Object.keys(childParts).map((child) => {
        data['childs'][child] = {};
        Object.keys(operationTempData).map((k) => {
          if (!processData) {
            data['childs'][child][k] = false;
          } else {
            Object.keys(operationTempData).map((k) => {
              data['childs'][child][k] = (childParts[child]['sub_process'] && !!childParts[child]['sub_process'][k]) || false;
            });
          }
        });
        if (childParts[child]?.parts) {
          setOriginDataOperationChild(childParts[child]?.parts, data['childs'][child], processData ? childParts[child] : false, operationTempData);
        }
      });
    }
  };

  const handleOpenMultiOperationDel = (no: any) => {
    setDeleteOperationItem(no);
    setIsOpenDeleteModal(true);
  };

  const handleOperationDelete = () => {
    if (deleteOperationItem) {
      let operationData = {};
      const cloneItem = _.cloneDeep(operationMultiData);
      // ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      // @ts-ignore
      const deleteKey = Object.keys(operationMultiData).find((t) => t.includes(deleteOperationItem));
      // @ts-ignore
      if (cloneItem[deleteKey]) delete cloneItem[deleteKey];
      // ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      // @ts-ignore
      const mainKey = deleteKey.replace(deleteOperationItem, '');
      Object.keys(cloneItem)
        .sort()
        .map((k, i) => {
          // ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          // @ts-ignore
          operationData[`${mainKey}_no${i + 1}`] = { ...cloneItem[k] };
        });
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'updated' does not exist on type '{}'.
      if (addItemNo?.updated && addItemNo?.node) {
        let node = {};
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type '{}'.
        const cloneItem = _.cloneDeep(addItemNo.node);
        // ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        // @ts-ignore
        const deleteKey = Object.keys(cloneItem).find((t) => t.includes(deleteOperationItem));
        if (cloneItem[deleteKey]) delete cloneItem[deleteKey];
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        const mainKey = deleteKey.replace(deleteOperationItem, '');
        Object.keys(cloneItem)
          .sort()
          .map((k, i) => {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            node[`${mainKey}_no${i + 1}`] = { ...cloneItem[k] };
          });
        props.handleOperatonDeleted(mainKey, deleteOperationItem, addItemNo);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type '{}'.
        addItemNo.node = node;
        setAddItemNo(addItemNo);
      }
      const newNumber = Object.keys(operationData).length;
      const dockDraft = _.cloneDeep(dockOrderDraft);
      dockDraft.basic_info.specifications[0].items[2]['value'] = newNumber;
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      setNumberOperationNo(newNumber);
      setOperationMultiData(operationData);
      setIsOpenDeleteModal(false);
    }
  };

  return (
    <>
      <DeleteOperationModal isOpen={isOpenDeleteModal} onSubmit={handleOperationDelete} onClose={() => setIsOpenDeleteModal(false)} />
      <Transition.Root show={isOpen} as={React.Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => {}}>
          <div className="flex items-center justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle max-w-screen-xl w-full">
                <div className="flex flex-col bg-white py-4 px-8">
                  <div className="flex tw-justify-between">
                    <div className="tw-font-bold tw-text-xl pb-3">
                      <span className="text-purple-400 ml-2">
                        {
                          // @ts-ignore
                          addItemNo?.no
                        }
                      </span>

                      <span className="tw-ml-3">
                        {
                          //@ts-ignore
                          addItemNo?.title
                        }
                      </span>
                    </div>

                    <button onClick={onCloseModal}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 tw-text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  <div className="tw-flex tw-justify-between">
                    <div>&nbsp;</div>

                    {addItemNo && (
                      <p className="tw-cursor-pointer underline underline-offset-1 opacity-60 tw-mb-2" onClick={onClear}>
                        Clear
                      </p>
                    )}
                  </div>

                  <div id="tbMultiOperationEdit" className="flex flex-col tw-max-h-72 tw-overflow-y-scroll scrollbar">
                    <table className="table-auto">
                      <thead>
                        <th colSpan={2} className="tw-border tw-border-secondary tw-py-2 tw-pl-3 tw-text-sm tw-font-normal">
                          {
                            // @ts-ignore
                            addItemNo?.title
                          }
                        </th>
                        {Object.keys(selectOperation).map((key) => (
                          <th style={{ width: 150 }} className="tw-border tw-border-secondary tw-text-center tw-font-normal tw-text-sm tw-py-2">
                            {
                              // @ts-ignore
                              selectOperation[key]
                            }
                          </th>
                        ))}
                      </thead>
                      <tbody>
                        <>
                          {
                            // @ts-expect-error ts-migrate(2339) FIXME: Property 'updated' does not exist on type '{}'.
                            !addItemNo?.updated
                              ? [...Array(numberOperationNo).keys()].map((value) => {
                                  return (
                                    <>
                                      <tr className="tw-bg-gray-100">
                                        <td
                                          rowSpan={calculateMultiOperationRowSpan(
                                            //@ts-ignore
                                            addItemNo?.node,
                                          )}
                                          className="tw-border tw-bg-white tw-border-secondary tw-text-center tw-w-20"
                                        >
                                          <div className="flex justify-between">
                                            <input
                                              style={{ width: '6rem' }}
                                              onChange={(e) => {}}
                                              className="mx-2 px-1 py-2 my-1 border shadow-sm tw-h-8 border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 rounded-md sm:text-sm focus:ring-1"
                                              value={`No.${value + 1}`}
                                            />

                                            <div
                                              className="cursor-pointer"
                                              onClick={() => {
                                                handleOpenMultiOperationDel(`_no${value + 1}`);
                                              }}
                                            >
                                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mt-3 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth={2}
                                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                              </svg>
                                            </div>
                                          </div>
                                        </td>
                                        <td className={`tw-border tw-border-secondary tw-text-sm tw-pl-3 tw-font-bold `}>
                                          {
                                            // @ts-ignore
                                            addItemNo?.title
                                          }
                                        </td>
                                        {/* // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentKey' does not exist on type '{}'. */}
                                        {
                                          // @ts-ignore
                                          operationMultiData &&
                                            Object.keys(operationMultiData).length > 0 &&
                                            addItemNo &&
                                            // @ts-ignore
                                            addItemNo.currentKey &&
                                            Object.keys(selectOperation).map((operationKey, i) => (
                                              <td className={`tw-text-center tw-text-sm tw-border tw-border-secondary`}>
                                                <div className="form-check form-check-inline tw-mr-0 mb-0 tw-min-h-0">
                                                  {/* <input checked={
                                                                                                operationMultiData[`${addItemNo.currentKey}_no${value + 1}`] &&
                                                                                                operationMultiData[`${addItemNo.currentKey}_no${value + 1}`][operationKey]
                                                                                            } onChange={(e) => {
                                                                                                if (!operationMultiData[`${addItemNo.currentKey}_no${value + 1}`]) {
                                                                                                    operationMultiData[`${addItemNo.currentKey}_no${value + 1}`] = {};
                                                                                                }
                                                                                                operationMultiData[`${addItemNo.currentKey}_no${value + 1}`][operationKey] = e.target.checked
                                                                                                setOperationMultiData({ ...operationMultiData });
                                                                                            }}
                                                                                                className="form-check-input appearance-none tw-h-5 tw-w-5 border border-gray-300 rounded-sm tw-bg-white focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer" type="checkbox" /> */}
                                                </div>
                                              </td>
                                            ))
                                        }
                                      </tr>
                                      {operationMultiData &&
                                        Object.keys(operationMultiData).length > 0 &&
                                        addItemNo &&
                                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type '{}'.
                                        addItemNo?.node?.parts &&
                                        // ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type '{}'.
                                        renderMultiOperationChildView(
                                          // @ts-ignore
                                          addItemNo?.node?.parts,
                                          // @ts-ignore
                                          operationMultiData[`${addItemNo.currentKey}_no${value + 1}`],
                                          // @ts-ignore
                                          `${addItemNo.currentKey}_no${value + 1}.childs`,
                                          1,
                                        )}
                                    </>
                                  );
                                })
                              : // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type '{}'.
                                addItemNo?.node &&
                                Object.keys(
                                  // @ts-ignore
                                  addItemNo?.node,
                                ).length > 0 &&
                                // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type '{}'.
                                Object.keys(addItemNo?.node)
                                  .sort()
                                  .map((valueKey, i) => {
                                    return (
                                      <>
                                        <tr className="tw-bg-gray-100">
                                          <td
                                            rowSpan={calculateMultiOperationRowSpan(
                                              // @ts-ignore
                                              addItemNo?.node[valueKey],
                                            )}
                                            className="tw-border tw-bg-white tw-border-secondary tw-text-center tw-w-20"
                                          >
                                            <div className="flex justify-between">
                                              <input
                                                style={{ width: '6rem' }}
                                                onChange={(e) => {}}
                                                className="mx-2 px-1 py-2 my-1 border shadow-sm tw-h-8 border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 rounded-md sm:text-sm focus:ring-1"
                                                value={`No.${i + 1}`}
                                              />

                                              <div
                                                className="cursor-pointer"
                                                onClick={() => {
                                                  handleOpenMultiOperationDel(`_no${i + 1}`);
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="h-4 w-4 mt-3 text-gray-300"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  stroke="currentColor"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                  />
                                                </svg>
                                              </div>
                                            </div>
                                          </td>
                                          <td className={`tw-border tw-border-secondary tw-text-sm tw-pl-3 tw-font-bold `}>
                                            {
                                              // @ts-ignore
                                              addItemNo?.title
                                            }
                                          </td>
                                          {/* // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentKey' does not exist on type '{}'. */}
                                          {operationMultiData &&
                                            Object.keys(operationMultiData).length > 0 &&
                                            addItemNo &&
                                            // @ts-ignore
                                            addItemNo.currentKey &&
                                            Object.keys(selectOperation).map((operationKey, i) => (
                                              <td className={`tw-text-center tw-text-sm tw-border tw-border-secondary`}>
                                                <div className="form-check form-check-inline tw-mr-0 mb-0 tw-min-h-0">
                                                  {/* <input checked={
                                                                                                    operationMultiData[valueKey] &&
                                                                                                    operationMultiData[valueKey][operationKey]
                                                                                                } onChange={(e) => {
                                                                                                    if (!operationMultiData[valueKey]) {
                                                                                                        operationMultiData[valueKey] = {};
                                                                                                    }
                                                                                                    operationMultiData[valueKey][operationKey] = e.target.checked
                                                                                                    setOperationMultiData({ ...operationMultiData });
                                                                                                }}
                                                                                                    className="form-check-input appearance-none tw-h-5 tw-w-5 border border-gray-300 rounded-sm tw-bg-white focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer" type="checkbox" /> */}
                                                </div>
                                              </td>
                                            ))}
                                        </tr>
                                        {operationMultiData &&
                                          Object.keys(operationMultiData).length > 0 &&
                                          // ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type '{}'.
                                          addItemNo &&
                                          // @ts-ignore
                                          addItemNo?.node[valueKey].parts &&
                                          // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type '{}'.
                                          renderMultiOperationChildView(addItemNo?.node[valueKey].parts, operationMultiData[valueKey], `${valueKey}.childs`, 1)}
                                      </>
                                    );
                                  })
                          }
                        </>
                      </tbody>
                    </table>
                  </div>

                  <div className="cursor-pointer add-new-row" onClick={addNodeMultiOperation} hidden={props.isConfirmOrder}>
                    <div className="add-new-row-container cursor-pointer">
                      <div className="blank-space" />

                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className="add-new-row-icon">
                        <path d="M6 1V6M6 6V11M6 6H11M6 6H1" stroke="#5C6BC0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                  </div>

                  <div className="flex justify-end mt-3">
                    <button
                      onClick={() => props.handleUpdateCheckboxOperation(operationMultiData, addItemNo, numberOperationNo)}
                      className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-9 py-2.5 mb-2 tw-text-white font-bold tw-bg-primary mr-2"
                    >
                      Select Operation
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default OperationTableModal;
