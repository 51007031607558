import { Dialog, Transition } from '@headlessui/react';
import { Input, Select } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { nonAccentVietnamese, selectOperationTemplate } from '../../../../../utils/common';

function UpdateOperationSearchModal(props: any) {
  const [operationEditData, setOperationEditData] = useState({ process: null, unit: null, value: null, sub_process: null });

  const addSubOperationFromSearch = () => {
    if (operationEditData) {
      let data = _.cloneDeep(operationEditData);
      // @ts-ignore
      if (!data.sub_process) data.sub_process = {};
      const keyOperationDefault = !data['sub_process']
        ? Object.keys(selectOperationTemplate)[0]
        : // @ts-ignore
          (Object.keys(selectOperationTemplate).filter((k) => !Object.keys(data['sub_process']).includes(k) && k !== data['process']) || ['default'])[0];

      // @ts-ignore
      if (data['sub_process'][keyOperationDefault]) {
        //TODO: alter message exists new item
        //  console.log('---- sub_process: item new exists', data);
      } else {
        const operationItem = {
          inspection: [],
          ship_type: [],
          unit: null,
          unit_name: '',
          comment: null,
        };
        data['sub_process'] = Object.assign(data['sub_process'], { [keyOperationDefault || 'default']: { ...operationItem } });
        setOperationEditData(data);
      }
    }
  };

  const deleteSubOperationFromSearch = (processKey: any) => {
    if (!processKey) {
      setOperationEditData((prev) => {
        return { ...prev, process: null };
      });
    } else {
      if (operationEditData.sub_process) {
        const subProcess = _.cloneDeep(operationEditData.sub_process);
        delete subProcess[processKey];
        setOperationEditData((prev) => {
          prev['sub_process'] = subProcess;
          return { ...prev };
        });
      }
    }
  };

  return (
    <Transition.Root show={props.isOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => {}}>
        <div className="flex items-center justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}

          <span className="hidden" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle max-w-screen-xl w-full">
              <div className="flex flex-col bg-white py-4 px-8">
                <div className="flex tw-justify-between">
                  <div className="tw-font-bold tw-text-xl pb-3">Select operation for checked items</div>
                </div>

                <div className="flex flex-col py-4 mb-3 tw-max-h-60 tw-overflow-y-scroll">
                  <div className="tw-flex tw-items-center tw-h-12 tw-w-full ">
                    <div className="tw-px-4 tw-border-r-2 tw-w-2/12 tw-h-full tw-border-secondary tw-items-center flex">
                      <div className="tw-flex-1 font-bold tw-p-2">Operation</div>
                    </div>

                    <div className="tw-px-4 tw-w-10/12 tw-items-center flex">
                      <div className="tw-flex-1 font-bold">QTY</div>
                    </div>
                  </div>

                  <div className="flex tw-bg-lightdary tw-h-12 tw-w-full">
                    <div className="tw-p-2 tw-w-2/12 tw-border-r-2 tw-border-secondary">
                      <Select
                        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; name: string; style: ... Remove this comment to see the full error message
                        name="operation"
                        style={{ width: '8vw', marginLeft: 14, borderRadius: 30 }}
                        value={operationEditData.process}
                        id={'operationEditData'}
                        onSelect={(value: any) =>
                          setOperationEditData((prev) => {
                            return { ...prev, process: value };
                          })
                        }
                        placeholder="Select operation"
                        dropdownRender={(menu) => (
                          <>
                            {menu}

                            <div className="tw-flex tw-p-2 z-50">
                              <Input
                                className="tw-flex-auto"
                                name="multi_operation"
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
                                    const value = nonAccentVietnamese(e.target.value).split(' ').join('_');
                                    setOperationEditData((prev) => {
                                      return {
                                        ...prev,
                                        process: value,
                                      };
                                    });
                                  }
                                }}
                              />
                            </div>
                          </>
                        )}
                      >
                        {Object.keys(selectOperationTemplate).map((key) => (
                          // @ts-ignore
                          <Select.Option value={key}>{selectOperationTemplate[key]}</Select.Option>
                        ))}
                      </Select>
                      {/*Button add operation to sub_process */}

                      {operationEditData.process && (
                        // @ts-ignore
                        <button className="tw-cursor-pointer tw-ml-1 tw-mb-2" hidden={props.isConfirmOrder} onClick={() => deleteSubOperationFromSearch()}>
                          <svg xmlns="http://www.w3.org/2000/svg" style={{ marginBottom: 4 }} width="20" height="14" fill="none" viewBox="0 0 14 14" stroke="currentColor">
                            <path stroke-linecap="round" stroke="#CBD5E1" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
                          </svg>
                        </button>
                      )}
                      {operationEditData.process &&
                        // ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                        // @ts-ignore
                        (!operationEditData.sub_process || Object.keys(operationEditData.sub_process).length === 0) && (
                          <button className="tw-ml-2" onClick={() => addSubOperationFromSearch()} hidden={props.isConfirmOrder}>
                            <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 12 12" fill="none">
                              <path d="M6 1V6M6 6V11M6 6H11M6 6H1" stroke={'#CBD5E1'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </button>
                        )}
                    </div>

                    <div className="tw-p-2 tw-w-2/12 flex tw-flex-row">
                      <input
                        type="text"
                        className="mr-2 px-1 py-2 border shadow-sm  row-start-2 row-end-3 tw-h-8
                  border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block tw-w-1/3 rounded-md sm:text-sm focus:ring-1"
                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message
                        value={
                          // @ts-ignore
                          operationEditData.value
                        }
                        // @s-expect-error ts-migrate(2345) FIXME: Argument of type '(prev: { process: null; unit: nu... Remove this comment to see the full error message
                        onChange={(e) =>
                          // @ts-ignore
                          setOperationEditData((prev) => {
                            return { ...prev, value: e.target.value };
                          })
                        }
                        name="qtyText"
                        id={'qtyText'}
                      />

                      <Select
                        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; name: string; value: ... Remove this comment to see the full error message
                        name="qtySelect"
                        value={operationEditData.unit}
                        id={'qtySelect'}
                        onSelect={(value: any) => {
                          setOperationEditData((prev) => {
                            return { ...prev, unit: value };
                          });
                        }}
                        className="tw-w-1/2"
                        placeholder=""
                        dropdownRender={(menu) => (
                          <div>
                            {menu}

                            <div className="tw-flex tw-p-2">
                              <Input
                                className="tw-flex-auto"
                                name="multi_qtySelect"
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
                                    const value = nonAccentVietnamese(e.target.value).split(' ').join('_');
                                    setOperationEditData((prev) => {
                                      return {
                                        ...prev,
                                        unit: value,
                                      };
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}
                      >
                        <Select.Option value={'set(s)'}>set(s)</Select.Option>

                        <Select.Option value={'pc(s)'}>pc(s)</Select.Option>

                        <Select.Option value={'place(s)'}>place(s)</Select.Option>

                        <Select.Option value={'m2'}>m2</Select.Option>
                      </Select>
                    </div>
                  </div>

                  {operationEditData.sub_process && (
                    <>
                      {/* // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                      {Object.keys(operationEditData.sub_process).map((processKey, i) => {
                        return (
                          <div className={`flex ${i % 2 === 1 ? 'tw-bg-lightdary' : ''} tw-h-12 tw-w-full`}>
                            <div className="tw-p-2 tw-w-2/12 tw-border-r-2 tw-border-secondary">
                              <Select
                                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; name: string; style: ... Remove this comment to see the full error message
                                name="operation"
                                style={{ width: '8vw', marginLeft: 14, borderRadius: 30 }}
                                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                                value={selectOperationTemplate[operationEditData.sub_process[processKey]] ?? (processKey !== 'default' ? processKey : null)}
                                id={'operationEditData'}
                                onSelect={(value: any) =>
                                  setOperationEditData((prev) => {
                                    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                                    const subProcess = _.cloneDeep(prev.sub_process[processKey]);
                                    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                                    if (!prev.sub_process[value] && value !== operationEditData.process) {
                                      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                                      delete prev.sub_process[processKey];
                                      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                                      const elementKey = selectOperationTemplate[value] ? value : nonAccentVietnamese(value).split(' ').join('_');
                                      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                                      prev.sub_process[elementKey] = { ...subProcess };
                                    }
                                    return {
                                      ...prev,
                                    };
                                  })
                                }
                                placeholder="Select operation"
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}

                                    <div className="tw-flex tw-p-2 z-50">
                                      <Input className="tw-flex-auto" name="multi_operation" onKeyDown={(e) => {}} />
                                    </div>
                                  </>
                                )}
                              >
                                {Object.keys(selectOperationTemplate).map((key) => (
                                  <Select.Option value={key}>
                                    <span
                                      className={`${
                                        // @ts-ignore
                                        (Object.keys(operationEditData.sub_process).includes(key) && key !== processKey) || key === operationEditData.process
                                          ? 'tw-text-red-400'
                                          : ''
                                      }`}
                                    >
                                      {/* // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error
                                      message */}
                                      {
                                        // @ts-ignore
                                        selectOperationTemplate[key]
                                      }
                                    </span>
                                  </Select.Option>
                                ))}
                              </Select>
                              {/*Button add operation to sub_process */}
                              <button className="tw-cursor-pointer tw-ml-1 tw-mb-2" hidden={props.isConfirmOrder} onClick={() => deleteSubOperationFromSearch(processKey)}>
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ marginBottom: 4 }} width="20" height="14" fill="none" viewBox="0 0 14 14" stroke="currentColor">
                                  <path stroke-linecap="round" stroke="#CBD5E1" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
                                </svg>
                              </button>
                              {/* // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                              {
                                // @ts-ignore
                                i === Object.keys(operationEditData.sub_process).length - 1 && (
                                  <button className="tw-ml-2" onClick={() => addSubOperationFromSearch()} hidden={props.isConfirmOrder}>
                                    <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 12 12" fill="none">
                                      <path d="M6 1V6M6 6V11M6 6H11M6 6H1" stroke={'#CBD5E1'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                  </button>
                                )
                              }
                            </div>

                            <div className="tw-p-2 tw-w-2/12 flex tw-flex-row">
                              <input
                                type="text"
                                className="mr-2 px-1 py-2 border shadow-sm  row-start-2 row-end-3 tw-h-8
                  border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block tw-w-1/3 rounded-md sm:text-sm focus:ring-1"
                                // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                                value={operationEditData.sub_process[processKey].unit}
                                onChange={(e) => {
                                  setOperationEditData((prev) => {
                                    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                                    prev['sub_process'][processKey]['unit'] = e.target.value;
                                    return { ...prev };
                                  });
                                }}
                                name="qtyText"
                                id={'qtyText'}
                              />

                              <Select
                                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; name: string; value: ... Remove this comment to see the full error message
                                name="qtySelect"
                                // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                                value={operationEditData.sub_process[processKey].unit_name}
                                id={'qtySelect'}
                                onSelect={(value: any) => {
                                  setOperationEditData((prev) => {
                                    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                                    prev['sub_process'][processKey]['unit_name'] = value;
                                    return { ...prev };
                                  });
                                }}
                                className="tw-w-1/2"
                                placeholder=""
                                dropdownRender={(menu) => (
                                  <div>
                                    {menu}

                                    <div className="tw-flex tw-p-2">
                                      <Input
                                        className="tw-flex-auto"
                                        name="multi_qtySelect"
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                            // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
                                            const value = nonAccentVietnamese(e.target.value).split(' ').join('_');
                                            setOperationEditData((prev) => {
                                              return {
                                                ...prev,
                                                unit: value,
                                              };
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              >
                                <Select.Option value={'set(s)'}>set(s)</Select.Option>

                                <Select.Option value={'pc(s)'}>pc(s)</Select.Option>

                                <Select.Option value={'place(s)'}>place(s)</Select.Option>

                                <Select.Option value={'m2'}>m2</Select.Option>
                              </Select>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>

                <div className="flex justify-end">
                  <button
                    onClick={() => props.onSubmit(operationEditData)}
                    className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-9 py-2.5 mb-2 tw-text-white font-bold tw-bg-primary mr-2"
                  >
                    Select Operation
                  </button>

                  <button onClick={props.onClose} className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-9 py-2.5 mb-2 tw-text-primary font-bold tw-bg-white mr-2">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default UpdateOperationSearchModal;
