import React, { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from 'react-content-loader';
import { useHistory } from 'react-router-dom';
import { getDocHistory, shipsActions } from '../../../store/shipsSlice';
import CompleteOrderModal from '../../../components/CompleteOrderModal';

import { DockOrder } from '../DocOrder/Repairment/';

function Dashboard(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ships' does not exist on type 'DefaultRo... Remove this comment to see the full error message
  const { docHistory, getDocHistoryStatus, selectedShip } = useSelector((state) => state.ships);
  const [dockOrderHistory, setDocOrderHistory] = useState<any[]>([]);

  useEffect(() => {
    if (selectedShip && selectedShip.ship_id !== 999999) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      dispatch(getDocHistory(selectedShip.ship_id));
    }
  }, [dispatch, selectedShip]);

  useEffect(() => {
    if (getDocHistoryStatus === 'succeeded') {
      setDocOrderHistory(docHistory);
    }
  }, [getDocHistoryStatus]);

  const jumpToImportExcelScreen = () => {
    history.push(`/import-excel`);
  };
  const openReserveDock = () => {
    window.open('https://rdr.seawise.jp', '_blank');
  };
  const jumpToShipSpecScreen = () => {
    history.push(`/ship-spec`);
  };
  const jumpToDocOrderScreen = () => {
    history.push(`/basic-info`);
  };

  const jumpToCreateDocOrderScreen = (dockOrderHistory: any) => {
    if (dockOrderHistory) {
      dispatch(shipsActions.setDockOrderRepairHistory(dockOrderHistory));
      history.push(`/dock-order`);
    }
  };

  const jumpToRepairmentScreen = (dock_order_id: string) => {
    if (dock_order_id) {
      history.push({
        pathname: '/repairment',
        state: {
          dock_order_id,
        },
      });
    }
  };

  return (
    <>
      {<CompleteOrderModal />}

      <div className="tw-pt-24 tw-px-44">
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div
            className="tw-bg-primary tw-cursor-pointer tw-rounded-xl tw-text-white tw-h-32
                tw-text-2xl tw-mr-4 tw-flex tw-items-center tw-justify-center tw-flex-col tw-w-4/12"
            onClick={openReserveDock}
          >
            <img src={'/static/reseve-dock.png'} className={'tw-mb-3'} />
            Reserve Dock
          </div>

          <div
            className="tw-bg-primary tw-cursor-pointer tw-rounded-xl tw-text-white tw-h-32 tw-text-2xl
           tw-mr-4 tw-flex tw-items-center tw-justify-center tw-flex-col tw-w-4/12"
            // ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
            onClick={jumpToDocOrderScreen}
          >
            <img src={'/static/create-dock-order.png'} className={'tw-mb-3'} />
            Create Dock Order
          </div>

          <div
            className="tw-bg-primary tw-cursor-pointer tw-rounded-xl tw-text-white tw-h-32 tw-text-2xl tw-flex tw-items-center
           tw-justify-center tw-flex-col tw-w-4/12"
            onClick={jumpToShipSpecScreen}
          >
            <img src={'/static/ship-spec.png'} className={'tw-mb-3'} /> Register / Edit Ship Info
          </div>
        </div>

        <div className="tw-w-full tw-h-px tw-bg-primary tw-mt-16"></div>

        <div className="tw-mt-7">
          <h2 className="tw-text-xl tw-mb-7">Repair history</h2>

          <div className="th tw-flex tw-border-secondary tw-border-b-2 tw-px-5 tw-mb-2 tw-pb-2">
            <p className="tw-w-5/12 tw-text-center">Repair date</p>

            <p className="tw-w-2/12 tw-text-center">Purpose of repair</p>
            <p className="tw-w-5/12 tw-text-center">Status</p>
          </div>

          <div className="content tw-text-center tw-pt-5 pb-5">
            {getDocHistoryStatus !== 'loading' && selectedShip ? (
              dockOrderHistory.length > 0 ? (
                dockOrderHistory.map((history) => (
                  <>
                    <div
                      onClick={() => {
                        jumpToRepairmentScreen(history.dock_order_id);
                      }}
                      className="th tw-flex tw-px-5 tw-items-center tw-h-11 tw-rounded-md tw-border-secondary tw-border-2 hover:tw-bg-secondary tw-mb-0.5 tw-cursor-pointer"
                      //@ts-ignore
                      key={history?.dock_order_id}
                    >
                      <p className="tw-w-5/12">
                        {history?.start_at ? dayjs(history?.start_at).format('YYYY/MM/DD') : ''} ~ {history?.end_at ? dayjs(history?.end_at).format('YYYY/MM/DD') : ''}
                      </p>
                      <p className="tw-w-2/12">
                        {
                          //@ts-ignore
                          history?.dock_order_purpose
                        }
                      </p>
                      <p className="tw-w-5/12">
                        {
                          //@ts-ignore
                          history?.dock_order_status
                        }
                      </p>
                    </div>
                  </>
                ))
              ) : (
                <div className="tw-text-center tw-bg-lightdary tw-py-20 tw-rounded-md">There are no record to display</div>
              )
            ) : (
              <>
                {' '}
                <DocHistoryTablePlacehoder />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const DocHistoryTablePlacehoder = (props: any) => {
  const { rows = 3 } = props;
  const rowHeight = 44;
  return (
    // ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
    <ContentLoader viewBox={`0 0 1500 ${rowHeight * rows}`} {...props}>
      {new Array(rows).fill(' ').map((el, index) => {
        const contentVerticalPosition = (contentHeight: any) => (rows > 1 ? contentHeight + rowHeight * index : contentHeight);
        return (
          // ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
          <Fragment key={index}>
            <rect x="20" y={`${contentVerticalPosition(10)}`} rx="4" ry="4" width="40" height="20" />

            <rect x="100" y={`${contentVerticalPosition(10)}`} rx="4" ry="4" width="600" height="20" />

            <rect x="750" y={`${contentVerticalPosition(10)}`} rx="4" ry="4" width="600" height="20" />

            <rect x="1450" y={`${contentVerticalPosition(10)}`} rx="4" ry="4" width="20" height="20" />

            <rect y={`${contentVerticalPosition(40)}`} x="4" ry="4" width="1500" height="1" />
          </Fragment>
        );
      })}
    </ContentLoader>
  );
};
export default Dashboard;
