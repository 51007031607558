import React, { memo } from 'react';
import { Element, Link } from 'react-scroll';
import UploadImage from '../../../../components/UploadImage';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import SaveDraftModal from '../../../../components/SaveDraftModal';
import { mainActions } from '../../../../store/mainSlice';
import { getArrayObjectOrderByIndex } from '../../../../utils/common';
import AddItemModal from './Modal/AddItemModal';
import DirectionModal from './Modal/DirectionModal';
import DeleteItemModal from './Modal/DeleteItemModal';
import UpdateOperationSearchModal from './Modal/UpdateOperationSearchModal';
import CustomRow from './Components/CustomRow';
import SearchFormInput from './Components/SearchFormInput';
import CustomPlaceHolder from './Components/CustomPlaceHolder';
import OperationTableModal from './Modal/OperationTableModal';
import Loading from 'atoms/Loading';
import DirectionSubProcessModal from './Modal/DirectionSubProcessModal';

type Props = {
  generalFocused: boolean;
  selectOperation: {};
  isRepairHistory: boolean;
  repairParts: {};
  isConfirmOrder: boolean;
  leftMenuKeys: string[];
  openDraftModal: boolean;
  isModalUpdateOperationOpen: boolean;
  selectedParentMenu: string;
  parentKeys: string[];
  searchResult: any[];
  confirmSuccess: boolean;
  dockOrderDraft: any;
  getEngTitle(key: string): string;
  getDescription(key: string): string;
  findOperationSetting(key: string): number;
  handleChange(value: any, no: any, parentKey: any, position: any, key: any, keyStructs: any): void;
  getArrayObjectOrderByIndex(obj: any): string[];
  onFilterSubProcess(child_item: any): any;
  addOperation(data: any): void;
  deleteOperation(data: any): void;
  checkValueComplete(leftKey: any): boolean;
  onContinueEdit(): void;
  addCustomElement(data: any): void;
  handleDeletePlaceHolder(): void;
  handleDirectionSubmit(directionValue: any, addItemNo: any): void;
  handleDeleteItem(data: any): void;
  setModalUpdateOperationOpen(isModalUpdateOperationOpen: boolean): void;
  handleUpdateOperation(operationEditData: any): void;
  handleUpdateImages(data: any): void;
  setImageDeleteFlg(imageDeleteFlg: boolean): void;
  handleMultiOperationDel(mainKey: any, deleteOperationItem: any, addItemNo: any): void;
  handleUpdateCheckboxOperation(operationMultiData: any, addItemNo: any, numberOperationNo: any): void;
  setCurrentDisplayParentKey(currentDisplayParentKey: string): void;
  createMainView(shipRepairs: any, parentKey: any): void;
  setSelectedParentMenu(selectedParentMenu: string): void;
  resetSearch(): void;
  scrollToTop(elementId: string): void;
  goBack(): void;
  confirmOrder(): void;
  saveDraft(): Promise<void>;
  onPrintApplicationFormExcel(): void;
  exportZip(): void;
  bookRepair(): Promise<void>;
  onSearch(e: any): void;
  resetSearch(): void;
  onCloseDraftModal(): void;
  onChangeSearchInputCheck(e: any): void;
  getGeneralRangeDate(): string | null | undefined;
  addPlaceHolder(parentKey: any, position: any, no: any, before: any): void;
  getCylinderPistonTitle(key: any, main_key: any): string;
  isLoading: boolean;
  setIsLoading(isLoading: boolean): void;
};

const CreateDocOrder = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();

  console.log('re-render template');

  /*
        Hàm tạo giao diện tree table
    */
  const createTreeView = (nextParts: any) => {
    if (!nextParts) {
      return '';
    }
    //createStructIndex(nextParts, null);
    const keys = getArrayObjectOrderByIndex(nextParts);
    return (keys || []).map((key, i) => {
      let child_item = nextParts[key];
      const cNo = `${i + 1}`;
      const collapseId = `collapse-header-${key}-${cNo}`;
      nextParts[key]['index'] = cNo;
      return (
        <Element className="tw-rounded-lg tw-mb-2" id={key} name={key}>
          {props.generalFocused ? (
            <CustomRow
              index={`head`}
              no={cNo}
              title={props.getEngTitle(key)}
              hint={props.getDescription(key)}
              key={key + '_' + (i + 1)}
              parentKey={key + '_' + i}
              parts={child_item.parts}
              position={i}
              valueUnit={child_item?.unit}
              valueUnitName={child_item?.unit_name}
              selectOperation={props.selectOperation}
              valueSubProcess={child_item.sub_process}
              valueDirection={child_item.direction}
              isConfirmOrder={props.isConfirmOrder}
              isCustom={child_item.isCustom}
              handleChange={props.handleChange}
              images={child_item?.images || []}
              generalDateInput={key === 'docking_and_undocking'}
              generalRangeDateHeader={props.generalFocused && key === 'docking_and_undocking' ? props.getGeneralRangeDate() : null}
              isRepairHistory={props.isRepairHistory}
              collapseId={`child-${collapseId}`}
              isLeaf={Object.keys(child_item?.parts || {}).length === 0}
              //↓自動追加
              isSearched={false}
              fnOnChangeSearchInputCheck={function (e: any): void {
                throw new Error('Function not implemented.');
              }}
              keyStructs={false}
              fnAddOperation={function (data: any): void {
                throw new Error('Function not implemented.');
              }}
              fnDeleteOperation={function (data: any): void {
                throw new Error('Function not implemented.');
              }}
              isMutilOperations={false}
              selectedYear={undefined}
              selectedDay={undefined}
              keyParts={undefined}
              parentParts={undefined}
              currentParts={undefined}
              numberOperation={undefined}
              currentKey={''}
              mainKey={undefined}
            />
          ) : (
            <CustomRow
              index={`head`}
              no={`${i + 1}`}
              title={props.getEngTitle(key)}
              hint={props.getDescription(key)}
              parentKey={key}
              key={`${key}_test_${i + 1}`}
              isRepairHistory={props.isRepairHistory}
              collapseId={`child-${collapseId}`}
              isLeaf={Object.keys(child_item?.parts || {}).length === 0}
              valueSubProcess={child_item.sub_process}
              handleChange={props.handleChange}
              images={child_item?.images || []}
              isCustom={child_item.isCustom}
              isConfirmOrder={props.isConfirmOrder}
              //↓自動追加
              isSearched={false}
              fnOnChangeSearchInputCheck={function (e: any): void {
                throw new Error('Function not implemented.');
              }}
              keyStructs={false}
              fnAddOperation={function (data: any): void {
                throw new Error('Function not implemented.');
              }}
              fnDeleteOperation={function (data: any): void {
                throw new Error('Function not implemented.');
              }}
              isMutilOperations={false}
              generalDateInput={false}
              generalRangeDateHeader={undefined}
              selectedYear={undefined}
              selectedDay={undefined}
              keyParts={undefined}
              parentParts={undefined}
              currentParts={undefined}
              numberOperation={undefined}
              currentKey={''}
              mainKey={undefined}
            />
          )}

          {!!Object.keys(child_item?.parts || {}).length && createChildTree(child_item, i + 1, key, `child-${collapseId}`)}
        </Element>
      );
    });
  };

  const createChildTree = (item: any, currentNo: any, parentKey: any, collapseParentId?: any) => {
    const keys = item.parts ? getArrayObjectOrderByIndex(item.parts) : [];
    const hidden = (currentNo + '').split('-').length > 1;
    return (
      <div id={collapseParentId} className={`${hidden ? 'hidden' : ''}`}>
        {keys.map((c_key: string, j) => {
          let child_item = item.parts[c_key];
          const cNo = `${currentNo}-${j + 1}`;
          const collapseId = `collapse-header-${c_key}-${cNo}`;
          if (c_key !== 'placeHolder') {
            child_item['index'] = cNo;
          }
          const title = child_item?.main_key ? props.getCylinderPistonTitle(c_key, child_item.main_key) : props.getEngTitle(c_key);
          // || (title || '').toLowerCase().includes('pump')

          // temporary patch
          //const checkMultiOperation = !hidden && (isMultiOperation || (parentKey || '').includes('main_engine') || (parentKey || '').includes('generator_engine')) && !(parentKey || '').includes('_survey');
          const checkMultiOperation = child_item?.input_type === 'table';
          if (c_key === 'placeHolder') {
            return (
              <Element name={c_key}>
                <CustomPlaceHolder index={j + 1} no={cNo} parentItem={item} parentKey={parentKey} position={j} />
                {!!Object.keys(child_item?.parts || {}).length && createChildTree(child_item, cNo, c_key)}
              </Element>
            );
          }
          return (
            <Element name={c_key}>
              {!props.generalFocused && !props.isRepairHistory && j === 0 && (
                <div className="cursor-pointer add-new-row" onClick={() => props.addPlaceHolder(parentKey, j, cNo, true)} hidden={props.isConfirmOrder}>
                  <div className="add-new-row-container cursor-pointer">
                    <div className="blank-space" />

                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className="add-new-row-icon">
                      <path d="M6 1V6M6 6V11M6 6H11M6 6H1" stroke="#5C6BC0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </div>
              )}
              <CustomRow
                index={`${j + 1}`}
                no={cNo}
                title={title}
                hint={props.getDescription(c_key)}
                key={c_key + '_' + (j + 1)}
                parentKey={c_key + '_' + j}
                parts={child_item.parts}
                position={j}
                valueUnit={child_item?.sub_process && Object.keys(child_item.sub_process).length > 0 ? child_item.sub_process[Object.keys(child_item.sub_process)[0]].unit : null}
                valueUnitName={
                  child_item?.sub_process && Object.keys(child_item.sub_process).length > 0 ? child_item.sub_process[Object.keys(child_item.sub_process)[0]].unit_name : null
                }
                selectOperation={props.selectOperation}
                valueProcess={child_item?.sub_process && Object.keys(child_item.sub_process).length > 0 ? Object.keys(child_item.sub_process)[0] : null}
                valueSubProcess={props.onFilterSubProcess(child_item)}
                valueDirection={child_item.direction}
                isConfirmOrder={props.isConfirmOrder}
                isCustom={child_item.isCustom}
                // value={child_item ? child_item.value : ''}
                handleChange={props.handleChange}
                images={child_item?.images || []}
                generalDateInput={parentKey === 'docking_and_undocking'}
                fnAddOperation={props.addOperation}
                fnDeleteOperation={props.deleteOperation}
                isMutilOperations={checkMultiOperation}
                isRepairHistory={props.isRepairHistory}
                collapseId={`child-${collapseId}`}
                isLeaf={Object.keys(child_item?.parts || {}).length === 0}
                selectedYear={props.generalFocused && (parentKey === 'docking_and_undocking' && child_item?.unit ? child_item?.unit : null)}
                selectedDay={parentKey === 'docking_and_undocking' && child_item?.unit ? child_item?.unit : null}
                keyParts={child_item?.key_parts}
                parentParts={checkMultiOperation ? item : null}
                currentParts={checkMultiOperation && child_item?.key_parts ? _.get(props.repairParts, child_item?.key_parts) : null}
                numberOperation={checkMultiOperation ? props.findOperationSetting(parentKey) : null}
                currentKey={c_key}
                mainKey={child_item?.main_key}
                isSearched={false}
                fnOnChangeSearchInputCheck={function (e: any): void {
                  throw new Error('Function not implemented.');
                }}
                keyStructs={false}
                generalRangeDateHeader={undefined}
              />
              {/*  <div className={`tw-px-4`}>{cNo}&nbsp;&nbsp;&nbsp;&nbsp;{c_key}*/}
              {/*  </div>*/}
              {!!Object.keys(child_item?.parts || {}).length && createChildTree(child_item, cNo, c_key, `child-${collapseId}`)}
              {!props.generalFocused && !props.isRepairHistory && (
                <div
                  className="cursor-pointer add-new-row"
                  onClick={
                    //@ts-ignore
                    () => props.addPlaceHolder(parentKey, j, cNo)
                  }
                  hidden={props.isConfirmOrder}
                >
                  <div className="add-new-row-container cursor-pointer">
                    <div className="blank-space" />

                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className="add-new-row-icon">
                      <path d="M6 1V6M6 6V11M6 6H11M6 6H1" stroke="#5C6BC0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </div>
              )}
            </Element>
          );
        })}
      </div>
    );
  };

  const createSearchView = () => {
    return (
      <div className="collapse show">
        {(props.searchResult || []).map((item, j) => {
          // ts-expect-error ts-migrate(2339) FIXME: Property 'input_type' does not exist on type 'neve... Remove this comment to see the full error message
          const checkMultiOperation = item?.input_type === 'table';
          return (
            <Element
              name={
                //@ts-ignore
                item.key
              }
            >
              <CustomRow
                index={`${j + 1}`}
                // ts-expect-error ts-migrate(2339) FIXME: Property 'no' does not exist on type 'never'.
                no={item.index}
                // ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type 'never'.
                title={item.title}
                // ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type 'never'.
                hint={props.getDescription(item.key)}
                // ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type 'never'.
                key={item.key}
                // ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type 'never'.
                parentKey={item.key}
                // ts-expect-error ts-migrate(2339) FIXME: Property 'parts' does not exist on type 'never'.
                parts={item.parts}
                position={j}
                valueUnit={
                  // ts-expect-error ts-migrate(2339) FIXME: Property 'unit' does not exist on type 'never'.
                  // ts-expect-error ts-migrate(2339) FIXME: Property 'sub_process' does not exist on type 'nev... Remove this comment to see the full error message
                  item?.sub_process && Object.keys(item.sub_process).length > 0 ? item.sub_process[Object.keys(item.sub_process)[0]].unit : null
                }
                // ts-expect-error ts-migrate(2339) FIXME: Property 'unit_name' does not exist on type 'never... Remove this comment to see the full error message
                // ts-expect-error ts-migrate(2339) FIXME: Property 'sub_process' does not exist on type 'nev... Remove this comment to see the full error message
                valueUnitName={
                  // @ts-ignore
                  item?.sub_process && Object.keys(item.sub_process).length > 0
                    ? // @ts-ignore
                      item.sub_process[Object.keys(item.sub_process)[0]].unit_name
                    : // @ts-ignore
                      null
                }
                selectOperation={props.selectOperation}
                valueProcess={
                  // ts-expect-error ts-migrate(2339) FIXME: Property 'sub_process' does not exist on type 'nev... Remove this comment to see the full error message
                  item?.sub_process && Object.keys(item.sub_process).length > 0
                    ? // ts-expect-error ts-migrate(2339) FIXME: Property 'sub_process' does not exist on type 'nev... Remove this comment to see the full error message
                      Object.keys(item.sub_process)[0]
                    : null
                }
                valueSubProcess={props.onFilterSubProcess(item)}
                // ts-expect-error ts-migrate(2339) FIXME: Property 'direction' does not exist on type 'never... Remove this comment to see the full error message
                valueDirection={item.direction}
                isConfirmOrder={props.isConfirmOrder}
                // ts-expect-error ts-migrate(2339) FIXME: Property 'isCustom' does not exist on type 'never'... Remove this comment to see the full error message
                isCustom={item.isCustom}
                // ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'never'.
                // value={item ? item.value : ''}
                handleChange={props.handleChange}
                // ts-expect-error ts-migrate(2339) FIXME: Property 'images' does not exist on type 'never'.
                images={item?.images || []}
                isSearched={true}
                fnOnChangeSearchInputCheck={props.onChangeSearchInputCheck}
                // ts-expect-error ts-migrate(2339) FIXME: Property 'key_struct' does not exist on type 'neve... Remove this comment to see the full error message
                keyStructs={item?.key_struct}
                fnAddOperation={props.addOperation}
                fnDeleteOperation={props.deleteOperation}
                isMutilOperations={checkMultiOperation}
                isRepairHistory={props.isRepairHistory}
                // ts-expect-error ts-migrate(2339) FIXME: Property 'key_struct' does not exist on type 'neve... Remove this comment to see the full error message
                generalDateInput={(item?.key_struct || '').includes('docking_and_undocking')}
                // ts-expect-error ts-migrate(2339) FIXME: Property 'key_struct' does not exist on type 'neve... Remove this comment to see the full error message
                selectedYear={
                  // @ts-ignore
                  props.generalFocused && (item?.key_struct || '').includes('docking_and_undocking') && item?.unit
                    ? // @ts-ignore
                      item?.unit.split('/').length > 2
                      ? // @ts-ignore
                        item?.unit.split('/').slice(0, 2).join('/')
                      : // @ts-ignore
                        item?.unit
                    : null
                }
                // ts-expect-error ts-migrate(2339) FIXME: Property 'key_struct' does not exist on type 'neve... Remove this comment to see the full error message
                selectedDay={
                  // @ts-ignore
                  props.generalFocused && (item?.key_struct || '').includes('docking_and_undocking') && item?.unit
                    ? // @ts-ignore
                      item?.unit.split('/').length > 2
                      ? // @ts-ignore
                        item?.unit.split('/')[item?.unit.split('/').length - 1]
                      : null
                    : null
                }
                collapseId={''}
                isLeaf={false}
                generalRangeDateHeader={undefined}
                keyParts={undefined}
                parentParts={undefined}
                currentParts={undefined}
                numberOperation={undefined}
                currentKey={''}
                mainKey={undefined}
              />
            </Element>
          );
        })}
      </div>
    );
  };

  const renderLeftMenu = () => {
    if (props.leftMenuKeys) {
      return (props.leftMenuKeys || []).map((key: string, index: number) => {
        return (
          <Link
            activeClass="tw-bg-secondary"
            spy={true}
            smooth={true}
            duration={250}
            containerId="containerElement"
            to={`${key}`}
            key={`${key}_test_${index + 1}`}
            className="tw-cursor-pointer hover:tw-bg-secondary tw-h-10 tw-mt-0.5 tw-px-1 tw-flex tw-items-center"
          >
            {/* {props.generalFocused && props.checkValueComplete(key) && (
              <span className="tw-pr-1">
                <svg className="tw-stroke-current tw-text-primary" width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.5 5L4.5 6L6.5 4M9.5 5C9.5 5.59095 9.3836 6.17611 9.15746 6.72208C8.93131 7.26804 8.59984 7.76412 8.18198 8.18198C7.76412 8.59984 7.26804 8.93131 6.72208 9.15746C6.17611 9.3836 5.59095 9.5 5 9.5C4.40905 9.5 3.82389 9.3836 3.27792 9.15746C2.73196 8.93131 2.23588 8.59984 1.81802 8.18198C1.40016 7.76412 1.06869 7.26804 0.842542 6.72208C0.616396 6.17611 0.5 5.59095 0.5 5C0.5 3.80653 0.974106 2.66193 1.81802 1.81802C2.66193 0.974106 3.80653 0.5 5 0.5C6.19347 0.5 7.33807 0.974106 8.18198 1.81802C9.02589 2.66193 9.5 3.80653 9.5 5Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            )} */}
            {props.getEngTitle(key)}
          </Link>
        );
      });
    }
  };

  return (
    <>
      <Loading isLoading={props.isLoading}></Loading>
      {props.openDraftModal && <SaveDraftModal isOpen={props.openDraftModal} onClose={props.onCloseDraftModal} onContinue={props.onContinueEdit} />}
      <div className="tw-flex">
        {/* Add item to tree */}
        {<AddItemModal addCustomElement={props.addCustomElement} handleDeletePlaceHolder={props.handleDeletePlaceHolder} />}
        {/* Add direction */}
        {<DirectionModal handleDirectionSubmit={props.handleDirectionSubmit} />}
        {<DirectionSubProcessModal handleDirectionSubmit={props.handleDirectionSubmit} />}

        {/* Delete item */}
        {<DeleteItemModal onSubmit={props.handleDeleteItem} />}
        {/* Update operation */}
        {props.isModalUpdateOperationOpen && (
          <UpdateOperationSearchModal
            isOpen={props.isModalUpdateOperationOpen}
            onClose={() => props.setModalUpdateOperationOpen(false)}
            onSubmit={props.handleUpdateOperation}
            isConfirmOrder={props.isConfirmOrder}
          />
        )}
        {<UploadImage updateFilesCb={props.handleUpdateImages} setImageDeleteFlg={props.setImageDeleteFlg} onSearch={props.onSearch} />}
        {/* Update multi operations */}
        <OperationTableModal
          handleOperatonDeleted={props.handleMultiOperationDel}
          handleUpdateCheckboxOperation={props.handleUpdateCheckboxOperation}
          isConfirmOrder={props.isConfirmOrder}
        />
        <div className="tw-w-60 tw-bg-lightdary tw-h-screen tw-pt-14 tw-flex tw-flex-col tw-overflow-y-auto">
          <div className="logo tw-h-16 tw-px-3">
            <div className={'tw-font-bold tw-text-md  tw-text-2xl'}>
              <h1>Create dock order</h1>
            </div>
            <div>{props.dockOrderDraft?.basic_info?.dock_order_purpose || ''}</div>
          </div>
          {props.repairParts && props.parentKeys.length > 0 && (
            <div className="tw-h-14 tw-bg-primary tw-flex tw-items-center tw-text-white">
              <div className="tw-w-14 tw-flex tw-flex-col tw-items-center tw-justify-center tw-mr-2">
                <div className="tw-w-4 tw-h-4 tw-bg-white" style={{ margin: '0 auto' }} />
                <div className="tw-text-tiny tw-mt-1 tw-text-white">{(props.selectedParentMenu || '').toUpperCase()}</div>
              </div>
              <div>{props.getEngTitle(props.selectedParentMenu)}</div>
            </div>
          )}
          {props.repairParts && props.parentKeys.length > 0 && (
            <div className="tw-flex tw-flex-1 tw-bg-white tw-overflow-y-auto">
              <div className="tw-w-14 tw-mr-1 tw-bg-lightdary  tw-flex tw-flex-col tw-items-center tw-pt-2">
                {props.parentKeys.map((key: string, index) => {
                  // let item = repairParts[key]
                  //if (index > 0) {
                  return (
                    <div
                      className={`tw-py-1 tw-w-full tw-text-center ${props.selectedParentMenu === key ? 'tw-bg-gray-300' : 'tw-cursor-pointer'}`}
                      // value={key}
                      onClick={(event) => {
                        if (props.selectedParentMenu !== key) {
                          dispatch(mainActions.setLoading(true));
                          setTimeout(function () {
                            let pKeys = _.cloneDeep(props.parentKeys);
                            let ele = pKeys[index];
                            // let childRepair = _.cloneDeep(repairParts[ele])
                            // repairParts[ele].parts = {}
                            // delete repairParts[ele].parts
                            // repairParts[ele] = childRepair
                            // let cloneDocEntity = _.cloneDeep(repairParts)
                            // setRepairParts(cloneDocEntity)
                            // cloneDocEntity[ele] = childRepair
                            // setRepairParts(_.cloneDeep(cloneDocEntity))

                            //pKeys.splice(index, 1);
                            //setParentKeys([ele].concat(pKeys));
                            props.setCurrentDisplayParentKey(ele);
                            props.createMainView(props.repairParts, key);
                            props.setSelectedParentMenu(key);
                            props.resetSearch();
                            props.scrollToTop('containerElement');
                            dispatch(mainActions.setLoading(false));
                          }, 100);
                        }
                      }}
                    >
                      <div className="tw-w-4 tw-h-4 tw-bg-primary" style={{ margin: '0 auto' }} /> {/*  value={key} */}
                      <div className="tw-text-tiny tw-mt-1 tw-text-primary">
                        {/*  value={key} */}

                        {key.toUpperCase()}
                      </div>
                    </div>
                  );
                  //}
                })}
              </div>
              {props.leftMenuKeys && <div className="tw-w-14 tw-bg-lightdary tw-flex-1 tw-overflow-y-auto tw-whitespace-nowrap">{renderLeftMenu()}</div>}
            </div>
          )}
        </div>
        <div className="tw-pt-14 tw-flex-grow tw-max-h-screen tw-flex tw-flex-col">
          <div className="header tw-h-16 tw-px-14 tw-flex tw-items-center tw-justify-between">
            <button onClick={props.goBack} className="tw-h-full tw-flex tw-items-center tw-text-xl active:tw-bg-secondary">
              <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 15L1 8L8 1" stroke="#0F172A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="tw-px-3">Back</span>
            </button>
            <div className={'tw-mt-30 tw-ml-40'}>
              <ul className="w-full steps tw-mt-5">
                <li className="step step-success" data-content="✓">
                  Basic info
                </li>
                <li className="step step-success" data-content="✓">
                  Application form
                </li>
                <li className="step step-success">Dock order</li>
              </ul>
            </div>
            {!props.confirmSuccess ? (
              <div className={'tw-flex tw-items-center tw-justify-between'}>
                <button
                  id="save-draft"
                  onClick={() => props.saveDraft()}
                  className="tw-flex tw-items-center tw-justify-center tw-h-11 tw-rounded-2xl tw-border-2
         tw-border-primary tw-px-3 tw-text-primary tw-mr-4"
                >
                  Save Draft
                </button>
                <button
                  onClick={props.confirmOrder}
                  className="tw-flex tw-items-center tw-justify-center tw-h-11 tw-rounded-2xl tw-border-2
         tw-border-primary tw-px-3 tw-text-white tw-bg-primary"
                >
                  Confirm Order
                </button>
              </div>
            ) : (
              <div className={'tw-flex tw-items-center tw-justify-between'}>
                <button
                  onClick={props.onPrintApplicationFormExcel}
                  className="tw-flex tw-items-center tw-justify-center tw-h-11 tw-rounded-2xl tw-border-2
         tw-border-primary tw-px-3 tw-text-primar tw-mr-4"
                >
                  Print application form
                </button>
                <button
                  onClick={props.exportZip}
                  className="tw-flex tw-items-center tw-justify-center tw-h-11 tw-rounded-2xl tw-border-2
         tw-border-primary tw-px-3 tw-text-primar tw-mr-4"
                >
                  Export (Zip)
                </button>
                <button
                  onClick={props.bookRepair}
                  className="tw-flex tw-items-center tw-justify-center tw-h-11 tw-rounded-2xl tw-border-2
         tw-border-primary tw-px-3 tw-text-white tw-bg-primary"
                >
                  Submit Order
                </button>
                <span onClick={props.goBack} className={'tw-font-bold tw-text-primary tw-underline tw-mt-2 tw-cursor-pointer tw-ml-4'}>
                  Back to Edit
                </span>
              </div>
            )}
          </div>
          <div className="header tw-h-14 tw-px-14 tw-flex tw-items-center tw-justify-between">
            <div className="tw-font-bold tw-text-2xl">{props.getEngTitle(props.selectedParentMenu)}</div>
            <div className="tw-flex tw-items-center tw-justify-between">
              <div className="relative tw-ml-3">
                <SearchFormInput onSearch={props.onSearch} resetSearch={props.resetSearch} />
              </div>
            </div>
            {/*<div>*/}
            {/*  <input type="text" name="inputText"*/}
            {/*         className="mt-1 px-3 py-2 border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"*/}
            {/*         placeholder="Search" />*/}
            {/*</div>*/}
          </div>
          {props.repairParts && props.parentKeys.length > 0 && (
            <Element name="" className="tw-overflow-y-auto tw-flex-1 tw-px-14 scrollbar" id="containerElement">
              <div className="tw-flex tw-items-center tw-h-12">
                <div
                  style={{ width: props.isRepairHistory ? '43.7%' : '' }}
                  className="tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-justify-between"
                >
                  <div className="tw-flex-1 font-bold">Description</div>
                </div>

                <div className="tw-w-3/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex">
                  <div className="tw-flex-1 font-bold">Operation</div>
                </div>

                <div style={{ width: '16.666667%' }} className={`tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex`}>
                  <div className="tw-flex-1 font-bold">QTY</div>
                </div>

                {props.isRepairHistory && (
                  <div className="tw-w-2/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex">
                    <div className="tw-flex-1 font-bold">Result</div>
                  </div>
                )}

                <div style={{ width: '16.666667%' }} className="tw-w-2/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex">
                  <div className="tw-flex-1">Direction</div>
                </div>

                <div className={'tw-w-1/12 tw-px-4 tw-items-center flex'}>
                  <div className="tw-flex-1">Images</div>
                </div>
              </div>

              {props.searchResult.length === 0
                ? createTreeView(
                    // @ts-ignore
                    props.repairParts[props.selectedParentMenu]?.parts ? props.repairParts[props.selectedParentMenu].parts : '',
                  )
                : createSearchView()}
              <div className="tw-py-96 tw-w-full" />
            </Element>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(CreateDocOrder);
