import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteComponentProps, StaticContext } from 'react-router';
import api from '../../../../api';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { shipsActions } from '../../../../store/shipsSlice';
import { mainActions } from '../../../../store/mainSlice';
import moment from 'moment';
import { dockOrderAction } from '../../../../store/dockOrderSlice';
import { getArrayObjectOrderByIndex, isEmpty, nonAccentVietnamese, selectLanguage } from '../../../../utils/common';
import Template from '../../../../template/client/DocOrder/repairment/';
import DockOrderJson from './dock-order.json';

export type DockOrder = typeof DockOrderJson;

type Props = {
  dock_order_id: string;
};

const Repairment = (props: RouteComponentProps<null, StaticContext, Props>): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ships' does not exist on type 'DefaultRo... Remove this comment to see the full error message
  const { selectedShip, dockOrderDraft } = useSelector((state) => state.ships);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'dockOrders' does not exist on type 'Defa... Remove this comment to see the full error message
  const { subProcessPullDownSource, language } = useSelector((state) => state.dockOrders);

  const [dockElementDict, setDocElementDict] = useState({});
  const [parentKeys, setParentKeys] = useState<string[]>([]);
  const [repairParts, setRepairParts] = useState({});
  const [leftMenuKeys, setLeftMenuKeys] = useState<string[]>([]);
  const [currentDisplayParentKey, setCurrentDisplayParentKey] = useState('');

  const [data, setData] = useState({});
  const [selectedParentMenu, setSelectedParentMenu] = useState('');
  const [placeHolderPosition, setPlaceHolderPosition] = useState([]);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [isConfirmOrder, setIsConfirmOrder] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [isModalUpdateOperationOpen, setModalUpdateOperationOpen] = useState(false);
  const [generalFocused, setGeneralFocused] = useState(false);
  const [operationUnitDict, setOperationUnitDict] = useState({});
  const [isRepairHistory, setIsRepairHistory] = useState(false);
  const [selectOperation, setSelectOperation] = useState({});
  const [imageDeleteFlg, setImageDeleteFlg] = useState(false);

  const [numberOperationNo, setNumberOperationNo] = useState(0);

  const findOperationSetting = useCallback(
    (key: string) => {
      return (
        parseInt(dockOrderDraft?.basic_info?.specifications?.find((k: any) => key.includes(String(k.key)))?.items.find((k: any) => String(k.key) === 'Cylinder')?.value) ||
        (key === 'main_engine' ? 6 : 2)
      );
    },
    [dockOrderDraft?.basic_info?.specifications],
  );

  useEffect(() => {
    setNumberOperationNo(findOperationSetting('main_engine'));
  }, [props.location?.state?.dock_order_id, findOperationSetting]);

  const handleChange = (value: any, no: any, parentKey: any, position: any, key: any, keyStructs: any) => {
    setData({ ...data, [key]: value });
    handleSelectOperationPlaceHolder(
      {
        no: no,
        parentKey: parentKey,
        position: position,
        key: key,
        keyStructs,
      },
      { ...data, [key]: value },
    );
  };

  /*
        Hook lấy giá trị ban đầu của dock-order từ template excel hoặc draft
        Deps: (khi các state nằm trong deps thay đổi thì hook sẽ được chạy lại)
            - selectedShip: giá trị ship đang được chọn
            - dockOrderRepairHistory: giá trị repair khi xem từ màn hình repair history tại dashboard
    */
  useEffect(() => {
    async function InitialComponent() {
      if (isEmpty(selectedShip?.ship_id) || isEmpty(props.location?.state?.dock_order_id)) {
        history.push(`/`);
      }

      let dockOrder = (await api.dockOrderApis.getDockOrder(selectedShip?.ship_id, props.location?.state?.dock_order_id)) as unknown as DockOrder[];

      if (dockOrder && dockOrder.length > 0) {
        try {
          dispatch(mainActions.setLoading(true));
          const dockOrderElement = await api.dockOrderApis.getDockOrderElement();
          let dockElementDict = {};
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type 'AxiosRes... Remove this comment to see the full error message
          dockOrderElement.entity.map((item: any) => (dockElementDict[item.dock_order_element_key] = item));

          setDocElementDict({ ...dockElementDict });

          const operationUnitelement = await api.dockOrderApis.getOperationUnitElement();

          if (operationUnitelement) {
            let operationUnitDict = {};
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'map' does not exist on type 'AxiosRespon... Remove this comment to see the full error message
            operationUnitelement.map((item: any) => (operationUnitDict[item.element_key] = item));
            setOperationUnitDict({ ...operationUnitDict, ...dockElementDict });
            dispatch(dockOrderAction.setOperationUnitDict({ ...operationUnitDict, ...dockElementDict }));
          }
          await fetchDockOrderInfo(dockOrder[0]);
        } catch (e) {
          dispatch(mainActions.setLoading(false));
        }
      }
    }
    InitialComponent();
  }, [dispatch]);

  /*
        Hàm lấy giá trị dock-order template hoặc draft
    */
  const fetchDockOrderInfo = async (dockOrder: DockOrder) => {
    const dockOrderTreeContent = {
      ship_id: dockOrder.ship.ship_id,
      dock_order_entity: dockOrder.dock_order_entity,

      basic_info: dockOrder.basic_info,
      application: dockOrder.application,
    };

    const orderDraftPayload = shipsActions.setDockOrderDraft(dockOrderTreeContent);
    dispatch(orderDraftPayload);

    const shipRepairs = _.cloneDeep(dockOrder.dock_order_entity);

    buildKeyParts(shipRepairs, null);
    setRepairParts(shipRepairs);

    const parentMenus = Object.keys(shipRepairs);

    setParentKeys(parentMenus);
    setSelectedParentMenu(parentMenus[0]);
    setCurrentDisplayParentKey(parentMenus[0]);
    createMainView(shipRepairs, parentMenus[0]);
    const reSetLoading = mainActions.setLoading(false);

    dispatch(reSetLoading);
  };

  /*
        Hàm tạo giá trị truy vấn đến từng object theo cấu trúc: [parent].parts.[child]
        *** Cách thực hiện truy vấn bằng lodash: _.get(item?.key_parts)
    */
  const buildKeyParts = (treeData: any, nextKeyParts: any) => {
    Object.keys(treeData).map((node) => {
      const childParts = treeData[node];
      const key_parts = nextKeyParts ? `${nextKeyParts}.parts.${node}` : node;
      childParts['key_parts'] = key_parts;
      if (childParts?.parts) {
        buildKeyParts(childParts?.parts, key_parts);
      }
    });
  };

  /*
        Hàm tạo menu
    */

  const createMainView = (shipRepairs: any, parentKey: any) => {
    setGeneralFocused(parentKey === 'general');
    let nextParts = shipRepairs[parentKey].parts ? shipRepairs[parentKey].parts : {};
    let leftMenuKeys = getArrayObjectOrderByIndex(nextParts);
    setLeftMenuKeys(leftMenuKeys);
  };

  const onCloseDraftModal = () => {
    dispatch(shipsActions.setDockOrderDraft(null));
    history.push('/dashboard');
  };

  const onContinueEdit = () => {
    setOpenDraftModal(false);
  };

  const getEngTitle = (key: any) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return dockElementDict[key] ? dockElementDict[key][selectLanguage(language, 'dock_order')] : key;
  };

  const getDescription = (key: any) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return dockElementDict[key] ? dockElementDict[key]['description'] : key;
  };

  /*
        Hàm thêm node trạng thái thêm mới
        *** Mở lên modal nhập thông tin node mới
        TODO: Lấy giá trị của node bằng key_parts
    */

  const addPlaceHolder = (parentKey: any, position: any, no: any, before: any) => {
    let treeData = _.cloneDeep(repairParts);
    const itemPosition = no.split('-');

    /*
           Xóa các node đang là trạng thái thêm trước đó
           TODO: Nên thay thế lưu các node selected vào object để dễ cho việc truy xuất giá trị tránh việc loop nhiều lần.
        */
    if (placeHolderPosition.length > 0) {
      for (const placeHolderPositionListItem of placeHolderPosition) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'split' does not exist on type 'never'.
        const itemPositionPlaceHolder = placeHolderPositionListItem.split('-');

        // @ts-ignore
        let treeDataPart = treeData[selectedParentMenu].parts;
        for (let i = 0; i < itemPositionPlaceHolder.length - 1; i++) {
          //Find key for each level
          const arrKey = getArrayObjectOrderByIndex(treeDataPart);
          const key = arrKey[itemPositionPlaceHolder[i] - 1];
          treeDataPart = treeDataPart[key].parts;
        }
        if (treeDataPart && treeDataPart.hasOwnProperty('placeHolder')) {
          delete treeDataPart.placeHolder;
          if (itemPositionPlaceHolder.length === 2) {
            if (+itemPosition[1] > +itemPositionPlaceHolder[1]) {
              itemPosition[1] = +itemPosition[1] - 1 + '';
              no = itemPosition.join('-');
              position -= 1;
            }
          }
        }
      }
      setPlaceHolderPosition([]);
    }

    let placeHolderTempList = [];

    // @ts-ignore
    let treeDataPart = treeData[selectedParentMenu].parts;
    // Giá trị node cha
    let itemLowerLevel;
    // Giá trị của node tiếp theo
    let itemSameLevel;
    // Giá trị của node con
    let itemHigherLevel;
    for (let i = 0; i < itemPosition.length; i++) {
      //Find key for each level
      const arrKey = getArrayObjectOrderByIndex(treeDataPart);
      const key = arrKey[itemPosition[i] - 1];
      if (i === itemPosition.length - 2) {
        itemLowerLevel = treeDataPart[key];
        treeDataPart = treeDataPart[key].parts;
      } else if (i === itemPosition.length - 1) {
        itemSameLevel = treeDataPart[key];
        if (before) {
          itemSameLevel['index'] = no.split('-').slice(0, -1).join('-') + '-' + (+itemPosition[itemPosition.length - 1] + 1);
        }
      } else {
        treeDataPart = treeDataPart[key].parts;
      }
    }

    //Find higher level item
    if (itemSameLevel?.parts && Object.keys(itemSameLevel.parts || {}).length > 0) {
      const itemSameLevelPart = Object.keys(itemSameLevel.parts);
      const lastItemKey = itemSameLevelPart[itemSameLevelPart.length - 1];
      itemHigherLevel = itemSameLevel.parts[lastItemKey];
    }

    //Add placeholder for lower level
    if (itemLowerLevel) {
      const nextNo = before ? no : no.split('-').slice(0, -1).join('-') + '-' + (position + 2);
      itemLowerLevel.parts = addToObject(
        itemLowerLevel.parts,
        'placeHolder',
        {
          unit: '',
          comment: '',
          unit_name: '',
          direction: '',
          operation: '',
          parts: before
            ? {
                placeHolder: {
                  unit: '',
                  comment: '',
                  unit_name: '',
                  direction: '',
                  operation: '',
                  parts: {},
                  isPlaceHolder: true,
                },
              }
            : {},
          isPlaceHolder: true,
          index: nextNo,
        },
        before ? position : position + 1,
      );
      placeHolderTempList.push(nextNo);
      if (before) placeHolderTempList.push(no + '-1');
    }

    //Add placeholder for higher level
    if (itemHigherLevel) {
      let index = '';
      if (!itemHigherLevel.parts) {
        itemHigherLevel.parts = {};
        index = no + '-' + Object.keys(itemSameLevel.parts).length + '-1';
        placeHolderTempList.push(index);
      } else {
        index = no + '-' + Object.keys(itemSameLevel.parts).length + '-' + (Object.keys(itemHigherLevel.parts).length + 1);
        placeHolderTempList.push(index);
      }
      itemHigherLevel.parts.placeHolder = {
        unit: '',
        comment: '',
        unit_name: '',
        direction: '',
        operation: '',
        parts: {},
        isPlaceHolder: true,
        index,
      };
    }

    //Add placeholder for same level
    if (itemSameLevel && !before) {
      let index = '';
      if (!itemSameLevel?.parts) {
        itemSameLevel.parts = {};
        index = no + '-1';
        placeHolderTempList.push(index);
      } else {
        index = no + '-' + (Object.keys(itemSameLevel.parts).length + 1);
        placeHolderTempList.push(index);
      }
      itemSameLevel.parts.placeHolder = {
        unit: '',
        comment: '',
        unit_name: '',
        direction: '',
        operation: '',
        parts: {},
        isPlaceHolder: true,
        index,
      };
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setPlaceHolderPosition(placeHolderTempList);
    setRepairParts(treeData);
  };

  /*
        Hàm lấy giá trị của 1 node bằng position
        TODO: Thay thế lấy giá trị của node bằng key_parts
    */

  const getObjectWithNo = (no: any, keyStructs: any, treeDataOrigin: any) => {
    const treeData = treeDataOrigin ? treeDataOrigin : _.cloneDeep(repairParts);
    const itemPosition = no.split('-');
    let treeDataPart = treeData[selectedParentMenu].parts;
    if (!keyStructs) {
      for (let i = 0; i < itemPosition.length; i++) {
        //Find key for each level
        const arrKey = getArrayObjectOrderByIndex(treeDataPart);
        const key = arrKey[itemPosition[i] - 1];
        if (i === itemPosition.length - 1) {
          treeDataPart = treeDataPart[key];
        } else {
          treeDataPart = treeDataPart[key].parts;
        }
      }
    } else {
      //REF: update with key structures
      treeDataPart = _.get(treeData, keyStructs);
    }
    return treeDataPart;
  };

  /*
        Hàm thêm 1 operation vào sub_process
        TODO: Lấy giá trị của node bằng key_parts
    */

  const addOperation = (data: any) => {
    if (data) {
      const { no, keyStructs } = data;
      const treeData = _.cloneDeep(repairParts);

      const itemPosition = no.split('-');

      //@ts-ignore
      let treeDataPart = treeData[selectedParentMenu].parts;
      if (!keyStructs) {
        for (let i = 0; i < itemPosition.length; i++) {
          //Find key for each level
          const arrKey = getArrayObjectOrderByIndex(treeDataPart);
          const key = arrKey[itemPosition[i] - 1];
          if (i === itemPosition.length - 1) {
            treeDataPart = treeDataPart[key];
          } else {
            treeDataPart = treeDataPart[key].parts;
          }
        }
      } else {
        //REF: update with key structures
        treeDataPart = _.get(treeData, keyStructs);
      }
      if (!treeDataPart['sub_process']) treeDataPart['sub_process'] = {};
      // Tìm sub_process template nếu có
      const subProcessTemplate = findOperationTemplate(treeDataPart?.key_parts) || {};
      const keyOperationDefault = !treeDataPart['sub_process']
        ? (Object.keys(subProcessTemplate) || ['CustomKey'])[0]
        : (Object.keys(subProcessTemplate).filter((k) => !Object.keys(treeDataPart['sub_process']).includes(k) && k !== treeDataPart['process']) || ['CustomKey'])[0];

      if (treeDataPart['sub_process'][keyOperationDefault]) {
        //TODO: alter message exists new item
        //  console.log('---- sub_process: item new exists', treeDataPart);
        return;
      }
      const operationItem = {
        inspection: [],
        ship_type: [],
        unit: 1,
        unit_name: subProcessTemplate[keyOperationDefault]?.unit_name,
        comment: null,
      };

      treeDataPart['sub_process'] = Object.assign(treeDataPart['sub_process'], { [keyOperationDefault || 'default']: { ...operationItem } });

      // Cập nhật giá trị khi ở view search
      if (searchResult.length > 0 && keyStructs) {
        // ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        const index = searchResult.findIndex((item: any) => item.key_struct === keyStructs);
        if (index > -1) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(prev: null) => any[] | undefine... Remove this comment to see the full error message
          setSearchResult((prev) => {
            // ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            const treeDataPart = prev[index];
            // @ts-ignore
            if (!treeDataPart['sub_process']) treeDataPart['sub_process'] = {};
            const keyOperationDefault = !treeDataPart['sub_process']
              ? (Object.keys(subProcessTemplate) || ['CustomKey'])[0]
              : (Object.keys(subProcessTemplate).filter((k) => !Object.keys(treeDataPart['sub_process']).includes(k) && k !== treeDataPart['process']) || ['CustomKey'])[0];
            if (treeDataPart['sub_process'][keyOperationDefault]) {
              //TODO: alter message exists new item
              //  console.log('---- sub_process: item new exists', treeDataPart);
              return;
            }
            const operationItem = {
              inspection: [],
              ship_type: [],
              unit: 1,
              unit_name: subProcessTemplate[keyOperationDefault]?.unit_name,
              comment: null,
            };
            treeDataPart['sub_process'] = Object.assign(treeDataPart['sub_process'], { [keyOperationDefault || 'default']: { ...operationItem } });
            // ts-expect-error ts-migrate(2488) FIXME: Type 'null' must have a '[Symbol.iterator]()' meth... Remove this comment to see the full error message
            return [...prev];
          });
        }
      }
      setRepairParts(treeData);
    }
  };

  /*
        Hàm xóa operation trong sub_process
    */
  const deleteOperation = (data: any) => {
    if (data) {
      const { no, keyStructs, processKey } = data;
      const treeData = _.cloneDeep(repairParts);
      const itemPosition = no.split('-');

      // @ts-ignore
      let treeDataPart = treeData[selectedParentMenu].parts;
      if (!keyStructs) {
        for (let i = 0; i < itemPosition.length; i++) {
          //Find key for each level
          const arrKey = getArrayObjectOrderByIndex(treeDataPart);
          const key = arrKey[itemPosition[i] - 1];
          if (i === itemPosition.length - 1) {
            treeDataPart = treeDataPart[key];
          } else {
            treeDataPart = treeDataPart[key].parts;
          }
        }
      } else {
        //REF: update with key structures
        treeDataPart = _.get(treeData, keyStructs);
      }
      if (treeDataPart['sub_process'] || !processKey) {
        // delete process
        if (!processKey) {
          treeDataPart['process'] = null;
        } else {
          const subProcess = _.clone(treeDataPart['sub_process']);
          delete subProcess[processKey];
          treeDataPart['sub_process'] = { ...subProcess };
        }

        if (searchResult && keyStructs) {
          // ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          const index = searchResult.findIndex((item: any) => item.key_struct === keyStructs);
          if (index > -1) {
            // ts-expect-error ts-migrate(2345) FIXME: Argument of type '(prev: null) => any[]' is not as... Remove this comment to see the full error message
            setSearchResult((prev) => {
              // ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
              const treeDataPart = prev[index];
              if (!processKey) {
                // @ts-ignore
                treeDataPart['process'] = null;
              } else {
                const subProcess = _.clone(treeDataPart['sub_process']);
                delete subProcess[processKey];
                // @ts-ignore
                treeDataPart['sub_process'] = { ...subProcess };
              }
              // ts-expect-error ts-migrate(2488) FIXME: Type 'null' must have a '[Symbol.iterator]()' meth... Remove this comment to see the full error message
              return [...prev];
            });
          }
        }
      }
      setRepairParts(treeData);
    }
  };

  /*
     Hàm cập nhật giá trị direction được lấy từ modal
    */
  const handleDirectionSubmit = (directionValue: any, addItemNo: any) => {
    if (addItemNo) {
      const { keyStructs } = addItemNo;
      const treeData = _.cloneDeep(repairParts);
      const itemPosition = addItemNo.no.split('-');

      // @ts-ignore
      let treeDataPart = treeData[selectedParentMenu].parts;

      if (!keyStructs) {
        for (let i = 0; i < itemPosition.length; i++) {
          //Find key for each level
          const arrKey = getArrayObjectOrderByIndex(treeDataPart);
          const key = arrKey[itemPosition[i] - 1];
          if (i === itemPosition.length - 1) {
            treeDataPart = treeDataPart[key];
          } else {
            treeDataPart = treeDataPart[key].parts;
          }
        }
      } else {
        treeDataPart = _.get(treeData, keyStructs);
      }

      treeDataPart.direction = directionValue;

      if (searchResult && keyStructs) {
        // ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        const index = searchResult.findIndex((item: any) => item.key_struct === keyStructs);
        if (index > -1) {
          // ts-expect-error ts-migrate(2345) FIXME: Argument of type '(prev: null) => any[]' is not as... Remove this comment to see the full error message
          setSearchResult((prev) => {
            // ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            const item = prev[index];
            // @ts-ignore
            item.direction = directionValue;
            // ts-expect-error ts-migrate(2488) FIXME: Type 'null' must have a '[Symbol.iterator]()' meth... Remove this comment to see the full error message
            return [...prev];
          });
        }
      }
      setRepairParts(treeData);
      handleCloseDirection();
    }
  };

  /*
        Hàm đóng direction modal
    */
  const handleCloseDirection = () => {
    dispatch(
      mainActions.setDirectionDataModal({
        isOpen: false,
        item: null,
      }),
    );
  };

  const handleDeletePlaceHolder = () => {
    if (placeHolderPosition.length > 0) {
      let treeData = _.cloneDeep(repairParts);
      for (const placeHolderPositionListItem of placeHolderPosition) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'split' does not exist on type 'never'.
        const itemPosition = placeHolderPositionListItem.split('-');
        // @ts-ignore
        let treeDataPart = treeData[selectedParentMenu].parts;
        for (let i = 0; i < itemPosition.length - 1; i++) {
          //Find key for each level
          const arrKey = getArrayObjectOrderByIndex(treeDataPart);
          const key = arrKey[itemPosition[i] - 1];
          treeDataPart = treeDataPart[key].parts;
        }
        if (treeDataPart?.placeHolder) delete treeDataPart.placeHolder;
      }
      setPlaceHolderPosition([]);
      setRepairParts(treeData);
      dispatch(
        mainActions.setAddItemModal({
          isOpen: false,
          item: null,
        }),
      );
    }
  };

  const handleSelectOperationPlaceHolder = async (addSelectNo: any, data: any) => {
    const { no, parentKey, position, key, keyStructs } = addSelectNo;

    const treeData = _.cloneDeep(repairParts);
    const itemPosition = addSelectNo.no.split('-');

    // @ts-ignore
    let treeDataPart = treeData[selectedParentMenu].parts;
    if (!keyStructs) {
      for (let i = 0; i < itemPosition.length; i++) {
        //Find key for each level
        const arrKey = getArrayObjectOrderByIndex(treeDataPart);
        const treeDataPartKey = arrKey[itemPosition[i] - 1];
        if (i === itemPosition.length - 1) {
          treeDataPart = treeDataPart[treeDataPartKey];
        } else {
          treeDataPart = treeDataPart[treeDataPartKey].parts;
        }
      }
    } else {
      //REF: update with key structures
      treeDataPart = _.get(treeData, keyStructs);
    }
    if (key === 'operation') {
      const operationTempData = findOperationTemplate(treeDataPart?.key_parts);
      let unit_name = null;
      if (operationTempData) {
        unit_name = operationTempData[data.operation]?.unit_name;
      }
      let operationItem = {
        inspection: [],
        ship_type: [],
        unit: 1,
        unit_name: unit_name,
        comment: null,
      };
      if (!treeDataPart?.sub_process) {
        treeDataPart.sub_process = {};
      }
      if (Object.keys(treeDataPart.sub_process).length > 0) {
        operationItem = { ...treeDataPart.sub_process[Object.keys(treeDataPart.sub_process)[0]], unit: 1, unit_name: unit_name };
        delete treeDataPart.sub_process[Object.keys(treeDataPart.sub_process)[0]];
      }
      if (!treeDataPart.sub_process[data.operation]) {
        treeDataPart.sub_process[data.operation] = {};
      }
      treeDataPart.sub_process[data.operation] = operationItem;
    } else if (key === 'qtyText') {
      treeDataPart.value = data.qtyText;
    } else if (key === 'generalUnitSelect') {
      treeDataPart['unit'] = data[key];
    } else if (key === 'generalUnitYearSelect') {
      treeDataPart['unit'] = data[key];
      treeDataPart['unit_name'] = 'day';
    } else if (key === 'generalUnitDaySelect') {
      treeDataPart['unit'] = treeDataPart['unit'] ? `${treeDataPart['unit']}/${data[key]}` : null;
      treeDataPart['unit_name'] = 'day';
    } else if (key === 'sub_process_operation') {
      const elementKey = nonAccentVietnamese(data[key].value).split(' ').join('_');
      // add new operation customize
      if (data[key]?.isCustom) {
        const dict = {
          element_key: elementKey,
          element_label_jpn: data[key].value,
          element_label_eng: data[key].value,
          element_type: 'operation',
        };
        const res = await api.dockOrderApis.createOperationOrUnitCustom(dict);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
        if (res?.meta?.message) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
          alert(res.meta.message);
          return;
        } else {
          dispatch(dockOrderAction.setOperationUnitDict({ ...operationUnitDict, [dict.element_key]: { ...dict } }));
          setOperationUnitDict((prev) => {
            return { ...prev, [dict.element_key]: { ...dict } };
          });
        }
      }
      // check main process
      if (elementKey !== treeDataPart?.process) {
        // throw error exists key
        if (!treeDataPart['sub_process']) treeDataPart['sub_process'] = {};
        if (treeDataPart['sub_process'] && treeDataPart['sub_process'][elementKey] && elementKey !== data[key].prev) {
          if (treeDataPart['sub_process'][data[key].prev]) {
            treeDataPart['sub_process'][data[key].prev]['error_message'] = `${data[key].value} exists.`;
          }
        } else {
          // clone prev operation data (unit, qtyText, ...)
          if (treeDataPart['sub_process'][data[key].prev] && treeDataPart['sub_process'][data[key].prev]['error_message']) {
            delete treeDataPart['sub_process'][data[key].prev]['error_message'];
          }

          const operationTempData = findOperationTemplate(treeDataPart?.key_parts);
          let unit_name = null;
          if (operationTempData) {
            unit_name = operationTempData[elementKey]?.unit_name;
          }

          const prevOperation = { ...treeDataPart['sub_process'][data[key].prev], unit_name };
          // remove prev
          delete treeDataPart['sub_process'][data[key].prev];
          // add new operation changed
          treeDataPart['sub_process'][elementKey] = prevOperation;
        }
      } else {
        treeDataPart['sub_process'][data[key].prev]['error_message'] = `${data[key].value} exists.`;
      }
    } else if (key === 'sub_process_qtySelect') {
      const elementKey = nonAccentVietnamese(data[key].value).split(' ').join('_');
      if (data[key]?.isCustom) {
        const dict = {
          element_key: elementKey,
          element_label_jpn: data[key].value,
          element_label_eng: data[key].value,
          element_type: 'unit',
        };
        const res = await api.dockOrderApis.createOperationOrUnitCustom(dict);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
        if (res?.meta?.message) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
          alert(res.meta.message);
          return;
        } else {
          dispatch(dockOrderAction.setOperationUnitDict({ ...operationUnitDict, [dict.element_key]: { ...dict } }));
          setOperationUnitDict((prev) => {
            return { ...prev, [dict.element_key]: { ...dict } };
          });
        }
      }
      treeDataPart['sub_process'][data[key]['processKey']]['unit_name'] = elementKey;
    } else if (key === 'sub_process_qtyText') {
      treeDataPart['sub_process'][data[key]['processKey']]['unit'] = data[key]['value'];
    } else {
      treeDataPart.unit = data.qtySelect;
    }

    if (searchResult && keyStructs) {
      // ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const index = searchResult.findIndex((item: any) => item.key_struct === keyStructs);
      if (index > -1) {
        const cloneSearch = _.cloneDeep(searchResult);
        let treeDataPart = cloneSearch[index];
        if (key === 'operation') {
          let operationItem = {
            inspection: [],
            ship_type: [],
            unit: 1,
            unit_name: '',
            comment: null,
          };

          // @ts-ignore
          if (!treeDataPart.sub_process) {
            // @ts-ignore
            treeDataPart.sub_process = {};
          }

          // @ts-ignore
          if (Object.keys(treeDataPart.sub_process).length > 0) {
            // @ts-ignore
            operationItem = { ...treeDataPart.sub_process[Object.keys(treeDataPart.sub_process)[0]] };
            // @ts-ignore
            delete treeDataPart.sub_process[Object.keys(treeDataPart.sub_process)[0]];
          }
          // @ts-ignore
          if (!treeDataPart.sub_process[data.operation]) {
            // @ts-ignore
            treeDataPart.sub_process[data.operation] = {};
          }
          // @ts-ignore
          treeDataPart.sub_process[data.operation] = operationItem;
        } else if (key === 'qtyText') {
          // @ts-ignore
          treeDataPart.value = data.qtyText;
        } else if (key === 'generalUnitSelect') {
          // @ts-ignore
          treeDataPart['unit'] = data[key];
        } else if (key === 'generalUnitYearSelect') {
          // @ts-ignore
          treeDataPart['unit'] = data[key];
          // @ts-ignore
          treeDataPart['unit_name'] = 'day';
        } else if (key === 'generalUnitDaySelect') {
          // @ts-ignore
          treeDataPart['unit'] = treeDataPart['unit'] ? `${treeDataPart['unit']}/${data[key]}` : null;
          // @ts-ignore
          treeDataPart['unit_name'] = 'day';
        } else if (key === 'sub_process_operation') {
          const elementKey = nonAccentVietnamese(data[key].value).split(' ').join('_');
          // add new operation customize
          if (data[key]?.isCustom) {
            const dict = {
              element_key: elementKey,
              element_label_jpn: data[key].value,
              element_label_eng: data[key].value,
              element_type: 'operation',
            };
            const res = await api.dockOrderApis.createOperationOrUnitCustom(dict);
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
            if (res?.meta?.message) {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
              alert(res.meta.message);
              return;
            } else {
              dispatch(dockOrderAction.setOperationUnitDict({ ...operationUnitDict, [dict.element_key]: { ...dict } }));
              setOperationUnitDict((prev) => {
                return { ...prev, [dict.element_key]: { ...dict } };
              });
            }
          }
          // check main process
          // @ts-ignore
          if (elementKey !== treeDataPart?.process) {
            // throw error exists key
            if (treeDataPart['sub_process'][elementKey] && elementKey !== data[key].prev) {
              if (treeDataPart['sub_process'][data[key].prev]) {
                // @ts-ignore
                treeDataPart['sub_process'][data[key].prev]['error_message'] = `${data[key].value} exists.`;
              }
            } else {
              // clone prev operation data (unit, qtyText, ...)
              if (treeDataPart['sub_process'][data[key].prev]['error_message']) {
                delete treeDataPart['sub_process'][data[key].prev]['error_message'];
              }
              // @ts-ignore
              const prevOperation = { ...treeDataPart['sub_process'][data[key].prev] };
              // remove prev
              delete treeDataPart['sub_process'][data[key].prev];
              // add new operation changed
              // @ts-ignore
              treeDataPart['sub_process'][elementKey] = prevOperation;
            }
          } else {
            // @ts-ignore
            treeDataPart['sub_process'][data[key].prev]['error_message'] = `${data[key].value} exists.`;
          }
        } else if (key === 'sub_process_qtySelect') {
          const elementKey = nonAccentVietnamese(data[key].value).split(' ').join('_');
          if (data[key]?.isCustom) {
            const dict = {
              element_key: elementKey,
              element_label_jpn: data[key].value,
              element_label_eng: data[key].value,
              element_type: 'unit',
            };
            const res = await api.dockOrderApis.createOperationOrUnitCustom(dict);
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
            if (res?.meta?.message) {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
              alert(res.meta.message);
              return;
            } else {
              dispatch(dockOrderAction.setOperationUnitDict({ ...operationUnitDict, [dict.element_key]: { ...dict } }));
              setOperationUnitDict((prev) => {
                return { ...prev, [dict.element_key]: { ...dict } };
              });
            }
          }
          // @ts-ignore
          treeDataPart['sub_process'][data[key]['processKey']]['unit_name'] = elementKey;
        } else if (key === 'sub_process_qtyText') {
          // @ts-ignore
          treeDataPart['sub_process'][data[key]['processKey']]['unit'] = data[key]['value'];
        } else {
          // @ts-ignore
          treeDataPart.unit = data.qtySelect;
        }
        setSearchResult(cloneSearch);
      }
    }
    setRepairParts(treeData);
  };

  const handleUpdateImages = (data: any) => {
    if (data) {
      const { keyStructs } = data;
      const treeData = _.cloneDeep(repairParts);

      const itemPosition = data.no.split('-');

      // @ts-ignore
      let treeDataPart = treeData[selectedParentMenu].parts;
      if (!keyStructs) {
        for (let i = 0; i < itemPosition.length; i++) {
          //Find key for each level
          const arrKey = getArrayObjectOrderByIndex(treeDataPart);
          const key = arrKey[itemPosition[i] - 1];
          if (i === itemPosition.length - 1) {
            treeDataPart = treeDataPart[key];
          } else {
            treeDataPart = treeDataPart[key].parts;
          }
        }
      } else {
        //REF: update with key structures
        treeDataPart = _.get(treeData, keyStructs);
      }

      treeDataPart.images = data.images;
      if (searchResult && keyStructs) {
        // ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        const index = searchResult.findIndex((item: any) => item.key_struct === keyStructs);
        if (index > -1) {
          // ts-expect-error ts-migrate(2345) FIXME: Argument of type '(prev: null) => any[]' is not as... Remove this comment to see the full error message
          setSearchResult((prev) => {
            // ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            const item = prev[index];
            // @ts-ignore
            item.images = data.images;
            // ts-expect-error ts-migrate(2488) FIXME: Type 'null' must have a '[Symbol.iterator]()' meth... Remove this comment to see the full error message
            return [...prev];
          });
        }
      }
      setRepairParts(treeData);
    }
  };

  const handleDeleteItem = (data: any) => {
    let treeData = _.cloneDeep(repairParts);
    const itemPosition = data.no.split('-');

    // @ts-ignore
    let treeDataPart = treeData[selectedParentMenu].parts;
    for (let i = 0; i < itemPosition.length; i++) {
      //Find key for each level
      const arrKey = getArrayObjectOrderByIndex(treeDataPart);
      const key = arrKey[itemPosition[i] - 1];
      if (i === itemPosition.length - 1) {
        delete treeDataPart[key];
      } else {
        treeDataPart = treeDataPart[key].parts;
      }
    }
    setRepairParts(treeData);
    dispatch(
      mainActions.setDeleteItemModal({
        isOpen: false,
        item: null,
      }),
    );
  };

  const addToObject = (obj: any, key: any, value: any, index: any) => {
    // Create a temp object and index variable
    let temp = {};
    let i = 0;
    const arrKey = getArrayObjectOrderByIndex(obj);

    // If index is greater
    if (index >= arrKey.length) {
      for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          // Add the current item in the loop to the temp obj
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          temp[prop] = obj[prop];
        }
      }
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      temp[key] = value;
    } else {
      // Loop through the original object
      for (let prop of arrKey) {
        if (obj.hasOwnProperty(prop)) {
          // If the indexes match, add the new item
          if (i === index && key && value) {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            temp[key] = value;
          }
          // Add the current item in the loop to the temp obj
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          temp[prop] = obj[prop];
          // Increase the count
          i++;
        }
      }
      // If no index, add to the end
      if (!index && key && value) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        temp[key] = value;
      }
    }

    return temp;
  };

  const goBack = () => {
    history.push('/dashboard');
  };

  const resetSearch = () => {
    // setSearchResult([]);
    // const t1 = setTimeout(() => {
    //   // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    //   document.getElementById('inputTextSearch').focus();
    //   // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    //   document.getElementById('inputTextSearch').value = null;
    //   clearTimeout(t1);
    // });
    return;
  };

  const onChangeSearchInputCheck = (e: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type 'never'.
    const index = (searchResult ?? []).findIndex((item) => item.key === e.target.id);
    if (index > -1) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      searchResult[index].checked = e.target.checked;
      setSearchResult(searchResult);
    }
  };

  const handleUpdateOperation = (operationEditData: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'checked' does not exist on type 'never'.
    const updatedItems = (searchResult ?? []).filter((item) => item.checked);
    if (updatedItems.length > 0) {
      const treeData = _.cloneDeep(repairParts);
      let updatedItem = null;
      updatedItems.forEach((item) => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'key_struct' does not exist on type 'neve... Remove this comment to see the full error message
        updatedItem = _.get(treeData, item.key_struct);
        const operationItem = {
          inspection: [],
          ship_type: [],
          unit: operationEditData.value,
          unit_name: operationEditData.unit,
          comment: null,
        };
        if ((!operationEditData.sub_process || Object.keys(operationEditData.sub_process).length === 0) && !operationEditData.process) {
          // @ts-ignore
          updatedItem.sub_process = {};
        } else {
          // @ts-ignore
          updatedItem.sub_process = { [operationEditData.process]: { ...operationItem }, ...operationEditData.sub_process };
        }
        if (searchResult) {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          const index = searchResult.findIndex((t: any) => t.key_struct === item.key_struct);
          if (index > -1) {
            const operationItem = {
              inspection: [],
              ship_type: [],
              unit: operationEditData.value,
              unit_name: operationEditData.unit,
              comment: null,
            };
            if ((!operationEditData.sub_process || Object.keys(operationEditData.sub_process).length === 0) && !operationEditData.process) {
              // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
              searchResult[index].sub_process = {};
            } else {
              // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
              searchResult[index].sub_process = { [operationEditData.process]: { ...operationItem }, ...operationEditData.sub_process };
            }
          }
        }
      });
      setSearchResult(searchResult);
      setRepairParts(treeData);
      setModalUpdateOperationOpen(false);
    }
  };

  const getObjectDiff = (obj1: any, obj2: any) => {
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (_.isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));
    return diff;
  };

  // @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
  const groupByObject = (object: any) => {
    let groupResult = [];
    const data = _.cloneDeep(object);
    const current = Object.keys(data)[0];
    let matchKeys: any = [];
    Object.keys(data)
      .filter((k) => k !== current)
      .map((child) => {
        const diff = getObjectDiff(data[current], data[child]);
        if (diff.length === 0) matchKeys.push(child);
      });
    if (matchKeys.length > 0) {
      groupResult.push(`${current}/${matchKeys.join('/')}`);
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'k' implicitly has an 'any' type.
      matchKeys.map((k) => delete data[k]);
    } else {
      groupResult.push(`${current}`);
    }
    delete data[current];
    if (Object.keys(data).length > 0) {
      return (groupResult = groupResult.concat(groupByObject(data)));
    }
    return groupResult;
  };

  const handleUpdateCheckboxOperation = (operationMultiData: any, addItemNo: any, numberOperationNo: any) => {
    if (operationMultiData && Object.keys(operationMultiData).length > 0) {
      const data = groupByObject(operationMultiData);
      if (data && data.length > 0) {
        const { no, currentKey, keyStructs } = addItemNo;
        const treeData = _.cloneDeep(repairParts);
        const arrNo = no.split('-');
        let treeDataParent = getObjectWithNo(
          arrNo.slice(0, arrNo.length - 1).join('-'),
          (keyStructs || '').replace(new RegExp(`.parts.${(keyStructs || '').split('.parts.').pop()}`, 'g'), ''),
          treeData,
        );
        let treeDataPart = getObjectWithNo(no, keyStructs, treeData);
        let oldRemovedObj = {};

        const nodes = Object.keys(treeDataParent.parts).filter((k) => treeDataParent.parts[k].hasOwnProperty('main_key') && k.includes(currentKey) && k !== currentKey);
        const parentIndex = Object.keys(treeDataParent.parts).findIndex((t) => t === currentKey);

        // remove all sub keys
        if (nodes && nodes.length > 0) {
          Object.keys(treeDataParent.parts).map((t) => {
            if (t.includes(treeDataPart?.main_key)) {
              oldRemovedObj = { ...oldRemovedObj, [t]: { ...treeDataParent.parts[t] } };
              delete treeDataParent.parts[t];
            }
          });
        } else {
          delete treeDataParent.parts[currentKey];
        }
        (data || []).reverse().map((g: any) => {
          const keyGroupName = `${currentKey}_${g.replace(new RegExp(`${currentKey}_no`, 'g'), '').replace(new RegExp('/', 'g'), '_')}`;
          const groupKey = g.split('/')[0];
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          const node = _.cloneDeep(oldRemovedObj[keyGroupName] ? oldRemovedObj[keyGroupName] : treeDataPart);
          let modified = false;
          Object.keys(operationMultiData[groupKey])
            .filter((v) => v !== 'childs')
            .map((o) => {
              if (operationMultiData[groupKey][o]) {
                modified = true;
                if (!node['sub_process']) {
                  node['sub_process'] = {};
                }
                const operationItem = {
                  inspection: [],
                  ship_type: [],
                  unit: 1,
                  unit_name: '',
                  comment: null,
                  modified: true,
                };
                if (!node?.sub_process[o]) {
                  node['sub_process'][o] = {};
                }
                if (node?.key_parts) {
                  const subProcessTemp = subProcessPullDownSource.find((t: any) => t.key_parts === node.key_parts);
                  if (subProcessTemp?.sub_process) {
                    const subProcessItem = subProcessTemp?.sub_process[o];
                    if (subProcessItem) {
                      operationItem.unit = 1;
                      operationItem.unit_name = subProcessItem.unit_name;
                    }
                  }
                }
                node['sub_process'] = Object.assign({ ...node['sub_process'] }, { [o]: { ...operationItem } });
              } else {
                if (node['sub_process'] && node['sub_process'][o]) {
                  delete node['sub_process'][o];
                }
              }
            });
          if (node?.parts && operationMultiData[groupKey]['childs']) {
            handleUpdateCheckboxOperationChild(node?.parts, operationMultiData[groupKey], modified);
          }
          let keyName = currentKey;
          const arrKeys = g.split('/');
          if (arrKeys.length < numberOperationNo || (arrKeys.length === numberOperationNo && modified)) {
            arrKeys.map((k: any) => {
              keyName += `_${k.replace(`${currentKey}_no`, '')}`;
            });
          }
          if (modified || arrKeys.length < numberOperationNo) {
            treeDataParent.parts = addToObject(
              treeDataParent.parts,
              keyName,
              { ...node, main_key: node?.main_key ?? currentKey, modified: true },
              parentIndex > -1 ? parentIndex : 0,
            );
          } else if (arrKeys.length === numberOperationNo) {
            if (node?.main_key) {
              delete node.main_key;
            }
            treeDataParent.parts = addToObject(treeDataParent.parts, currentKey, { ...node, modified: true }, parentIndex > -1 ? parentIndex : 0);
          }
        });
        buildKeyParts(treeData, null);
        setRepairParts(treeData);

        dispatch(
          mainActions.setOperationTableModal({
            isOpen: false,
            item: null,
          }),
        );
      }
    }
  };

  const handleUpdateCheckboxOperationChild = (childParts: any, operationData: any, modified: any) => {
    if (childParts) {
      Object.keys(operationData['childs']).map((c) => {
        Object.keys(operationData['childs'][c])
          .filter((t) => t !== 'childs')
          .map((p) => {
            if (operationData['childs'][c][p]) {
              modified = true;
              if (!childParts[c]['sub_process']) {
                childParts[c]['sub_process'] = {};
              }
              const operationItem = {
                inspection: [],
                ship_type: [],
                unit: 1,
                unit_name: '',
                comment: null,
              };
              if (childParts[c]?.key_parts) {
                const subProcessTemp = subProcessPullDownSource.find((t: any) => t.key_parts === childParts[c].key_parts);
                if (subProcessTemp?.sub_process) {
                  const subProcessItem = subProcessTemp?.sub_process[p];
                  if (subProcessItem) {
                    operationItem.unit = 1;
                    operationItem.unit_name = subProcessItem.unit_name;
                  }
                }
              }
              childParts[c]['sub_process'] = Object.assign({ ...childParts[c]['sub_process'] }, { [p]: { ...operationItem } });
            } else {
              if (childParts[c] && childParts[c]['sub_process'] && childParts[c]['sub_process'][p]) {
                delete childParts[c]['sub_process'][p];
              }
            }
          });
        if (childParts[c]['parts'] && operationData['childs'][c]['childs']) {
          handleUpdateCheckboxOperationChild(childParts[c]['parts'], operationData['childs'][c], modified);
        }
      });
    }
  };

  const onFilterSubProcess = (child_item: any) => {
    if (child_item?.sub_process && Object.keys(child_item.sub_process).length > 0) {
      const subProcess = _.cloneDeep(child_item.sub_process);
      //delete subProcess[Object.keys(child_item.sub_process)[0]];
      return { ...subProcess };
    }
    return null;
  };

  const getCylinderPistonTitle = (key: any, main_key: any) => {
    const title = getEngTitle(main_key);
    const no = key.replace(main_key + '_', '').replace(/_/g, ',');
    return `${title} ${no.split(',').length === numberOperationNo || key === main_key ? '' : 'No.' + no}`;
  };

  const scrollToTop = (elementId: any) => {
    const elmnt = document.getElementById(elementId);
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    elmnt.scrollTop = 0;
  };

  const checkValueComplete = (leftKey: any) => {
    let result = false;
    let checkUnitName = leftKey === 'docking_and_undocking';
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (repairParts[currentDisplayParentKey]['parts'][leftKey]?.unit || checkUnitName) {
      result = true;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const childParts = repairParts[currentDisplayParentKey]['parts'][leftKey]?.parts;
      if (childParts) {
        Object.keys(childParts).map((k) => {
          if (checkUnitName) {
            if (result && (!childParts[k]?.unit || !childParts[k]?.unit_name)) {
              result = false;
            }
          } else {
            if (result && !childParts[k]?.unit) {
              result = false;
            }
          }
        });
      }
    }
    return result;
  };

  const getGeneralRangeDate = () => {
    if (generalFocused && currentDisplayParentKey === 'general') {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const childParts = repairParts[currentDisplayParentKey]['parts']['docking_and_undocking']['parts'];
      if (childParts) {
        let arrDate: any = [];
        Object.keys(childParts).map((k) => {
          if (childParts[k]?.unit) {
            arrDate.push(`${childParts[k]?.unit}`);
          }
        });
        let arrDateUnix: any = [];
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'd' implicitly has an 'any' type.
        arrDate.map((d) => {
          if (d) {
            arrDateUnix.push(new Date(d.replace(new RegExp('/', 'g'), '-')).getTime());
          }
        });
        if (arrDateUnix && arrDateUnix.length > 0) {
          arrDateUnix = arrDateUnix.sort();
          const first = arrDateUnix[0];
          const last = arrDateUnix[arrDateUnix.length - 1];
          return first && last ? `${moment(first).format('YYYY/MM/DD')}  >>  ${moment(last).format('YYYY/MM/DD')}` : null;
        }
      }
    }
  };

  const handleMultiOperationDel = (mainKey: any, deleteOperationItem: any, addItemNo: any) => {
    if (mainKey) {
      const treeData = _.cloneDeep(repairParts);
      const arrNo = addItemNo.no.split('-');
      let treeDataParent = getObjectWithNo(
        arrNo.slice(0, arrNo.length - 1).join('-'),
        (addItemNo.keyStructs || '').replace(new RegExp(`.parts.${(addItemNo.keyStructs || '').split('.parts.').pop()}`, 'g'), ''),
        treeData,
      );
      const nodeKey = `${mainKey}_${deleteOperationItem.replace('_no', '')}`;
      if (treeDataParent?.parts && treeDataParent?.parts[nodeKey]) delete treeDataParent.parts[nodeKey];
      setRepairParts(treeData);
    }
  };

  const findOperationTemplate = (keyParts: any) => {
    if (keyParts && subProcessPullDownSource) {
      return subProcessPullDownSource.find((t: any) => t.key_parts === keyParts)?.sub_process;
    }
  };

  return (
    <Template
      selectOperation={selectOperation}
      isRepairHistory={isRepairHistory}
      repairParts={repairParts}
      isConfirmOrder={isConfirmOrder}
      leftMenuKeys={leftMenuKeys}
      openDraftModal={openDraftModal}
      isModalUpdateOperationOpen={isModalUpdateOperationOpen}
      selectedParentMenu={selectedParentMenu}
      parentKeys={parentKeys}
      searchResult={searchResult}
      getEngTitle={getEngTitle}
      getDescription={getDescription}
      findOperationSetting={findOperationSetting}
      handleChange={handleChange}
      getArrayObjectOrderByIndex={getArrayObjectOrderByIndex}
      onFilterSubProcess={onFilterSubProcess}
      addOperation={addOperation}
      deleteOperation={deleteOperation}
      checkValueComplete={checkValueComplete}
      onContinueEdit={onContinueEdit}
      handleDeletePlaceHolder={handleDeletePlaceHolder}
      handleDirectionSubmit={handleDirectionSubmit}
      handleDeleteItem={handleDeleteItem}
      setModalUpdateOperationOpen={setModalUpdateOperationOpen}
      handleUpdateOperation={handleUpdateOperation}
      handleUpdateImages={handleUpdateImages}
      setImageDeleteFlg={setImageDeleteFlg}
      handleMultiOperationDel={handleMultiOperationDel}
      handleUpdateCheckboxOperation={handleUpdateCheckboxOperation}
      setCurrentDisplayParentKey={setCurrentDisplayParentKey}
      createMainView={createMainView}
      setSelectedParentMenu={setSelectedParentMenu}
      resetSearch={resetSearch}
      scrollToTop={scrollToTop}
      goBack={goBack}
      onCloseDraftModal={onCloseDraftModal}
      onChangeSearchInputCheck={onChangeSearchInputCheck}
      getGeneralRangeDate={getGeneralRangeDate}
      addPlaceHolder={addPlaceHolder}
      getCylinderPistonTitle={getCylinderPistonTitle}
      generalFocused={generalFocused}
    ></Template>
  );
};

export default Repairment;
