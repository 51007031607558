import React, { memo } from 'react';
import { Element, Link } from 'react-scroll';
import UploadImage from '../../../../components/UploadImage';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { mainActions } from '../../../../store/mainSlice';
import { getArrayObjectOrderByIndex } from '../../../../utils/common';
import DirectionModal from './Modal/DirectionModal';
import CustomRow from './Components/CustomRow';
import OperationTableModal from './Modal/OperationTableModal';

type Props = {
  generalFocused: boolean;
  selectOperation: {};
  isRepairHistory: boolean;
  repairParts: {};
  isConfirmOrder: boolean;
  leftMenuKeys: string[];
  openDraftModal: boolean;
  isModalUpdateOperationOpen: boolean;
  selectedParentMenu: string;
  parentKeys: string[];
  searchResult: any[];
  getEngTitle(key: string): string;
  getDescription(key: string): string;
  findOperationSetting(key: string): number;
  handleChange(value: any, no: any, parentKey: any, position: any, key: any, keyStructs: any): void;
  getArrayObjectOrderByIndex(obj: any): string[];
  onFilterSubProcess(child_item: any): any;
  addOperation(data: any): void;
  deleteOperation(data: any): void;
  checkValueComplete(leftKey: any): boolean;
  onContinueEdit(): void;
  handleDeletePlaceHolder(): void;
  handleDirectionSubmit(directionValue: any, addItemNo: any): void;
  handleDeleteItem(data: any): void;
  setModalUpdateOperationOpen(isModalUpdateOperationOpen: boolean): void;
  handleUpdateOperation(operationEditData: any): void;
  handleUpdateImages(data: any): void;
  setImageDeleteFlg(imageDeleteFlg: boolean): void;
  handleMultiOperationDel(mainKey: any, deleteOperationItem: any, addItemNo: any): void;
  handleUpdateCheckboxOperation(operationMultiData: any, addItemNo: any, numberOperationNo: any): void;
  setCurrentDisplayParentKey(currentDisplayParentKey: string): void;
  createMainView(shipRepairs: any, parentKey: any): void;
  setSelectedParentMenu(selectedParentMenu: string): void;
  resetSearch(): void;
  scrollToTop(elementId: string): void;
  goBack(): void;
  resetSearch(): void;
  onCloseDraftModal(): void;
  onChangeSearchInputCheck(e: any): void;
  getGeneralRangeDate(): string | null | undefined;
  addPlaceHolder(parentKey: any, position: any, no: any, before: any): void;
  getCylinderPistonTitle(key: any, main_key: any): string;
};

const CreateDocOrder = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();

  console.log('re-render template');

  /*
        Hàm tạo giao diện tree table
    */
  const createTreeView = (nextParts: any) => {
    if (!nextParts) {
      return '';
    }
    //createStructIndex(nextParts, null);
    const keys = getArrayObjectOrderByIndex(nextParts);
    return (keys || []).map((key, i) => {
      let child_item = nextParts[key];
      const cNo = `${i + 1}`;
      const collapseId = `collapse-header-${key}-${cNo}`;
      nextParts[key]['index'] = cNo;
      return (
        <Element className="tw-rounded-lg tw-mb-2" id={key} name={key}>
          {props.generalFocused ? (
            <CustomRow
              index={`head`}
              no={cNo}
              title={props.getEngTitle(key)}
              hint={props.getDescription(key)}
              key={key + '_' + (i + 1)}
              parentKey={key + '_' + i}
              parts={child_item.parts}
              position={i}
              valueUnit={child_item?.unit}
              valueUnitName={child_item?.unit_name}
              valueSubProcess={child_item.sub_process}
              valueDirection={child_item.direction}
              isConfirmOrder={props.isConfirmOrder}
              isCustom={child_item.isCustom}
              images={child_item?.images || []}
              generalFocused={props.generalFocused}
              generalDateInput={key === 'docking_and_undocking'}
              generalRangeDateHeader={props.generalFocused && key === 'docking_and_undocking' ? props.getGeneralRangeDate() : null}
              isRepairHistory={props.isRepairHistory}
              collapseId={`child-${collapseId}`}
              isLeaf={Object.keys(child_item?.parts || {}).length === 0}
              //↓自動追加
              isSearched={false}
              fnOnChangeSearchInputCheck={function (e: any): void {
                throw new Error('Function not implemented.');
              }}
              keyStructs={false}
              isMutilOperations={false}
              selectedYear={undefined}
              selectedDay={undefined}
              keyParts={undefined}
              parentParts={undefined}
              currentParts={undefined}
              numberOperation={undefined}
              currentKey={''}
              mainKey={undefined}
            />
          ) : (
            <CustomRow
              index={`head`}
              no={`${i + 1}`}
              title={props.getEngTitle(key)}
              hint={props.getDescription(key)}
              parentKey={key}
              key={`${key}_test_${i + 1}`}
              isRepairHistory={props.isRepairHistory}
              collapseId={`child-${collapseId}`}
              isLeaf={Object.keys(child_item?.parts || {}).length === 0}
              valueSubProcess={child_item.sub_process}
              images={child_item?.images || []}
              isCustom={child_item.isCustom}
              isConfirmOrder={props.isConfirmOrder}
              //↓自動追加
              isSearched={false}
              fnOnChangeSearchInputCheck={function (e: any): void {
                throw new Error('Function not implemented.');
              }}
              keyStructs={false}
              generalFocused={false}
              isMutilOperations={false}
              generalDateInput={false}
              generalRangeDateHeader={undefined}
              selectedYear={undefined}
              selectedDay={undefined}
              keyParts={undefined}
              parentParts={undefined}
              currentParts={undefined}
              numberOperation={undefined}
              currentKey={''}
              mainKey={undefined}
            />
          )}

          {!!Object.keys(child_item?.parts || {}).length && createChildTree(child_item, i + 1, key, `child-${collapseId}`)}
        </Element>
      );
    });
  };

  const createChildTree = (item: any, currentNo: any, parentKey: any, collapseParentId?: any) => {
    const keys = item.parts ? getArrayObjectOrderByIndex(item.parts) : [];
    const hidden = (currentNo + '').split('-').length > 1;
    return (
      <div id={collapseParentId} className={`${hidden ? 'hidden' : ''}`}>
        {keys.map((c_key: string, j) => {
          let child_item = item.parts[c_key];
          const cNo = `${currentNo}-${j + 1}`;
          const collapseId = `collapse-header-${c_key}-${cNo}`;
          if (c_key !== 'placeHolder') {
            child_item['index'] = cNo;
          }
          const title = child_item?.main_key ? props.getCylinderPistonTitle(c_key, child_item.main_key) : props.getEngTitle(c_key);
          const checkMultiOperation = child_item?.input_type === 'table';
          return (
            <Element name={c_key}>
              <CustomRow
                index={`${j + 1}`}
                no={cNo}
                title={title}
                hint={props.getDescription(c_key)}
                key={c_key + '_' + (j + 1)}
                parentKey={c_key + '_' + j}
                parts={child_item.parts}
                position={j}
                valueUnit={child_item?.sub_process && Object.keys(child_item.sub_process).length > 0 ? child_item.sub_process[Object.keys(child_item.sub_process)[0]].unit : null}
                valueUnitName={
                  child_item?.sub_process && Object.keys(child_item.sub_process).length > 0 ? child_item.sub_process[Object.keys(child_item.sub_process)[0]].unit_name : null
                }
                valueProcess={child_item?.sub_process && Object.keys(child_item.sub_process).length > 0 ? Object.keys(child_item.sub_process)[0] : null}
                valueSubProcess={props.onFilterSubProcess(child_item)}
                valueDirection={child_item.direction}
                isConfirmOrder={props.isConfirmOrder}
                isCustom={child_item.isCustom}
                images={child_item?.images || []}
                generalFocused={props.generalFocused}
                generalDateInput={parentKey === 'docking_and_undocking'}
                isMutilOperations={checkMultiOperation}
                isRepairHistory={props.isRepairHistory}
                collapseId={`child-${collapseId}`}
                isLeaf={Object.keys(child_item?.parts || {}).length === 0}
                selectedYear={props.generalFocused && (parentKey === 'docking_and_undocking' && child_item?.unit ? child_item?.unit : null)}
                selectedDay={parentKey === 'docking_and_undocking' && child_item?.unit ? child_item?.unit : null}
                keyParts={child_item?.key_parts}
                parentParts={checkMultiOperation ? item : null}
                currentParts={checkMultiOperation && child_item?.key_parts ? _.get(props.repairParts, child_item?.key_parts) : null}
                numberOperation={checkMultiOperation ? props.findOperationSetting(parentKey) : null}
                currentKey={c_key}
                mainKey={child_item?.main_key}
                isSearched={false}
                fnOnChangeSearchInputCheck={function (e: any): void {
                  throw new Error('Function not implemented.');
                }}
                keyStructs={false}
                generalRangeDateHeader={undefined}
              />
              {!!Object.keys(child_item?.parts || {}).length && createChildTree(child_item, cNo, c_key, `child-${collapseId}`)}
            </Element>
          );
        })}
      </div>
    );
  };

  const renderLeftMenu = () => {
    if (props.leftMenuKeys) {
      return (props.leftMenuKeys || []).map((key: string, index: number) => {
        return (
          <Link
            activeClass="tw-bg-secondary"
            spy={true}
            smooth={true}
            duration={250}
            containerId="containerElement"
            to={`${key}`}
            key={`${key}_test_${index + 1}`}
            className="tw-cursor-pointer hover:tw-bg-secondary tw-h-10 tw-mt-0.5 tw-px-1 tw-flex tw-items-center"
          >
            {/* {props.generalFocused && props.checkValueComplete(key) && (
              <span className="tw-pr-1">
                <svg className="tw-stroke-current tw-text-primary" width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.5 5L4.5 6L6.5 4M9.5 5C9.5 5.59095 9.3836 6.17611 9.15746 6.72208C8.93131 7.26804 8.59984 7.76412 8.18198 8.18198C7.76412 8.59984 7.26804 8.93131 6.72208 9.15746C6.17611 9.3836 5.59095 9.5 5 9.5C4.40905 9.5 3.82389 9.3836 3.27792 9.15746C2.73196 8.93131 2.23588 8.59984 1.81802 8.18198C1.40016 7.76412 1.06869 7.26804 0.842542 6.72208C0.616396 6.17611 0.5 5.59095 0.5 5C0.5 3.80653 0.974106 2.66193 1.81802 1.81802C2.66193 0.974106 3.80653 0.5 5 0.5C6.19347 0.5 7.33807 0.974106 8.18198 1.81802C9.02589 2.66193 9.5 3.80653 9.5 5Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            )} */}
            {props.getEngTitle(key)}
          </Link>
        );
      });
    }
  };

  return (
    <>
      <div className="tw-flex">
        {/* Add direction */}
        {<DirectionModal onSubmit={props.handleDirectionSubmit} />}

        <UploadImage
          updateFilesCb={props.handleUpdateImages}
          setImageDeleteFlg={props.setImageDeleteFlg}
          onSearch={function (e: any, isUpdImage?: boolean): void {
            throw new Error('Function not implemented.');
          }}
        />

        {/* Update multi operations */}
        <OperationTableModal
          handleOperatonDeleted={props.handleMultiOperationDel}
          handleUpdateCheckboxOperation={props.handleUpdateCheckboxOperation}
          isConfirmOrder={props.isConfirmOrder}
        />
        <div className="tw-w-60 tw-bg-lightdary tw-h-screen tw-pt-14 tw-flex tw-flex-col tw-overflow-y-auto">
          <div className="logo tw-h-16 tw-px-3">
            <div className={'tw-font-bold tw-text-md  tw-text-2xl'}>
              <h1>Repairment Info</h1>
            </div>
            {/* <div>{props.dockOrderDraft?.basic_info?.dock_order_purpose || ''}</div> */}
          </div>
          {props.repairParts && props.parentKeys.length > 0 && (
            <div className="tw-h-14 tw-bg-primary tw-flex tw-items-center tw-text-white">
              <div className="tw-w-14 tw-flex tw-flex-col tw-items-center tw-justify-center tw-mr-2">
                <div className="tw-w-4 tw-h-4 tw-bg-white" style={{ margin: '0 auto' }} />
                <div className="tw-text-tiny tw-mt-1 tw-text-white">{(props.selectedParentMenu || '').toUpperCase()}</div>
              </div>
              <div>{props.getEngTitle(props.selectedParentMenu)}</div>
            </div>
          )}
          {props.repairParts && props.parentKeys.length > 0 && (
            <div className="tw-flex tw-flex-1 tw-bg-white tw-overflow-y-auto">
              <div className="tw-w-14 tw-mr-1 tw-bg-lightdary  tw-flex tw-flex-col tw-items-center tw-pt-2">
                {props.parentKeys.map((key: string, index) => {
                  return (
                    <div
                      className={`tw-py-1 tw-w-full tw-text-center ${props.selectedParentMenu === key ? 'tw-bg-gray-300' : 'tw-cursor-pointer'}`}
                      onClick={(event) => {
                        if (props.selectedParentMenu !== key) {
                          dispatch(mainActions.setLoading(true));
                          setTimeout(function () {
                            let pKeys = _.cloneDeep(props.parentKeys);
                            let ele = pKeys[index];
                            props.setCurrentDisplayParentKey(ele);
                            props.createMainView(props.repairParts, key);
                            props.setSelectedParentMenu(key);
                            props.resetSearch();
                            props.scrollToTop('containerElement');
                            dispatch(mainActions.setLoading(false));
                          }, 100);
                        }
                      }}
                    >
                      <div className="tw-w-4 tw-h-4 tw-bg-primary" style={{ margin: '0 auto' }} /> {/*  value={key} */}
                      <div className="tw-text-tiny tw-mt-1 tw-text-primary">{key.toUpperCase()}</div>
                    </div>
                  );
                  //}
                })}
              </div>
              {props.leftMenuKeys && <div className="tw-w-14 tw-bg-lightdary tw-flex-1 tw-overflow-y-auto tw-whitespace-nowrap">{renderLeftMenu()}</div>}
            </div>
          )}
        </div>
        <div className="tw-pt-14 tw-flex-grow tw-max-h-screen tw-flex tw-flex-col">
          <div className="header tw-h-16 tw-px-14 tw-flex tw-items-center tw-justify-between">
            <button onClick={props.goBack} className="tw-h-full tw-flex tw-items-center tw-text-xl active:tw-bg-secondary">
              <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 15L1 8L8 1" stroke="#0F172A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="tw-px-3">Back</span>
            </button>
          </div>
          <div className="header tw-h-14 tw-px-14 tw-flex tw-items-center tw-justify-between">
            <div className="tw-font-bold tw-text-2xl">{props.getEngTitle(props.selectedParentMenu)}</div>
          </div>
          {props.repairParts && props.parentKeys.length > 0 && (
            <Element name="" className="tw-overflow-y-auto tw-flex-1 tw-px-14 scrollbar" id="containerElement">
              <div className="tw-flex tw-items-center tw-h-12">
                <div
                  style={{ width: props.isRepairHistory ? '43.7%' : '' }}
                  className="tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-justify-between"
                >
                  <div className="tw-flex-1 font-bold">Description</div>
                </div>

                <div className="tw-w-3/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex">
                  <div className="tw-flex-1 font-bold">Operation</div>
                </div>

                <div style={{ width: '16.666667%' }} className={`tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex`}>
                  <div className="tw-flex-1 font-bold">QTY</div>
                </div>

                {!props.generalFocused && props.isRepairHistory && (
                  <div className="tw-w-2/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex">
                    <div className="tw-flex-1 font-bold">Result</div>
                  </div>
                )}

                <div style={{ width: '16.666667%' }} className="tw-w-2/12 tw-px-4 tw-border-r-2 tw-h-full tw-border-secondary tw-items-center flex">
                  <div className="tw-flex-1">Direction</div>
                </div>

                <div className={props.generalFocused ? `"tw-w-2/12 tw-px-4 tw-items-center flex"` : 'tw-w-1/12 tw-px-4 tw-items-center flex'}>
                  <div className="tw-flex-1">Images</div>
                </div>
              </div>

              {createTreeView(
                // @ts-ignore
                props.repairParts[props.selectedParentMenu]?.parts ? props.repairParts[props.selectedParentMenu].parts : '',
              )}
              <div className="tw-py-96 tw-w-full" />
            </Element>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(CreateDocOrder);
