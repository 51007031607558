import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

export const getListUser = createAsyncThunk('users/getList', async () => {
    try {
        const users = await api.user.getList();
        return { users };
    } catch (error) {
        return { users: [] };
    }
});


const userSlice = createSlice({
    name: 'users',
    initialState: {
        userList: [],
        getListStatus: 'idle',
        getListError: null,
    },
    reducers: {

    },
    extraReducers: {
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [getListUser.pending]: (state) => {
            state.getListStatus = 'loading';
        },
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [getListUser.fulfilled]: (state, action) => {
            state.getListStatus = 'succeeded';
            state.userList = action.payload.users;
        },
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [getListUser.rejected]: (state, action) => {
            state.getListStatus = 'failed';
            state.getListError = action.error.message;
        },
    }
});

const { actions, reducer } = userSlice;

export const mainActions = actions;

export default reducer;
