import { Input, Select } from 'antd';
import Direction from 'atoms/Direction';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../../utils/common';

function OperationSubProcessComponent({
  subProcess,
  unitTitle,
  isLastItem,
  rowClass,
  leftClass,
  borderClass,
  borderClassNotGeneral,
  isConfirmOrder,
  isRepairHistory,
  index,
  key,
  subProcessTemplate,
  parentKey,
  selectOperation,
  fnDeleteOperation,
  fnAddOperation,
  operationOnSelect,
  no,
  handleEnterKeySubOperation,
  processKey,
  handleUnitValueChange,
  handleEnterKeySubUnit,
  unitOnSelect,
  addDirectionSubProcess,
  editDirection,
  lookDirection,
  editDirectionSubProcess,
}: any) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'dockOrders' does not exist on type 'Defa... Remove this comment to see the full error message
  const { operationUnitDict, language } = useSelector((state) => state.dockOrders);

  const getOperationUnitTitle = (key: any) => {
    return operationUnitDict[key] ? operationUnitDict[key][selectLanguage(language, "operation_unit")] || operationUnitDict[key][selectLanguage(language, "dock_order")] : key;
  };
  const [subOperationCustom, setSubOperationCustom] = useState({ value: '', processKey: null, prev: null, isCustom: true });
  const [subUnitCustom, setSubUnitCustom] = useState({ value: '', processKey: null, isCustom: true });

  return (
    <>
      <div className={rowClass} key={`sub_process_${key}_${processKey}`}>
        <div className={leftClass}></div>
        <div className={borderClassNotGeneral}>
          {index && (
            <div>
              {isConfirmOrder || isRepairHistory ? (
                <div className="tw-mr-5 normal-case">{getOperationUnitTitle(subProcess?.['title'])}</div>
              ) : (
                <>
                  {subProcess && subProcess?.['error_message'] && (
                    <div>
                      <span className="tw-text-red-500 tw-text-xs">{subProcess?.['error_message']}</span>
                    </div>
                  )}

                  <div>
                    <Select
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; name: string; style: ... Remove this comment to see the full error message
                      name="sub_process_operation"
                      style={{ width: '14vw', marginBottom: subProcess?.['error_message'] ? 20 : 0 }}
                      value={processKey !== 'default' ? getOperationUnitTitle(subProcess?.['title']) : null}
                      id={`sub_process_${processKey}_${parentKey}_${no}_operation`}
                      onSelect={(value: any) => operationOnSelect(value)}
                      placeholder="Select operation"
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <div className="tw-flex tw-p-2">
                            <Input
                              className="tw-flex-auto"
                              name="operation"
                              onKeyDown={(e) => handleEnterKeySubOperation(e, subOperationCustom)}
                              value={subOperationCustom.value}
                              onChange={(e) => setSubOperationCustom({ value: e.target.value, processKey: processKey, prev: processKey, isCustom: true })}
                            />
                          </div>
                        </div>
                      )}
                    >
                      {Object.keys(subProcessTemplate || {}).length > 0
                        ? Object.keys(subProcessTemplate).map((key) => <Select.Option value={key}>{getOperationUnitTitle(subProcessTemplate[key]?.['title'])}</Select.Option>)
                        : Object.keys(selectOperation).map((key) => <Select.Option value={key}>{selectOperation[key]}</Select.Option>)}
                    </Select>

                    <button className="tw-cursor-pointer tw-ml-1 tw-mb-2" hidden={isConfirmOrder} onClick={() => fnDeleteOperation()}>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ marginBottom: 4 }} width="20" height="14" fill="none" viewBox="0 0 14 14" stroke="currentColor">
                        <path stroke-linecap="round" stroke="#CBD5E1" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
                      </svg>
                    </button>

                    {isLastItem && (
                      <button className="tw-ml-2" onClick={() => fnAddOperation()} hidden={isConfirmOrder}>
                        <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 12 12" fill="none">
                          <path d="M6 1V6M6 6V11M6 6H11M6 6H1" stroke={index === 'head' ? '#FFF' : '#CBD5E1'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className={borderClass}>
          {index && (
            <>
              {isConfirmOrder || isRepairHistory ? (
                <div className="tw-w-1/2">{subProcess?.unit}</div>
              ) : (
                <input
                  type="text"
                  className="mr-2 px-1 py-2 border shadow-sm  row-start-2 row-end-3 tw-h-8
              border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block tw-w-1/2 rounded-md sm:text-sm focus:ring-1 text-right"
                  value={subProcess?.unit}
                  onChange={(e) => handleUnitValueChange(e.target.value)}
                  onBlur={() => handleUnitValueChange(subProcess?.unit)}
                  name="qtyText"
                  id={`sub_process_${processKey}_${parentKey}_${no}_qtyText`}
                />
              )}
              {isConfirmOrder || isRepairHistory ? (
                <div>{getOperationUnitTitle(subProcess?.unit_name)}</div>
              ) : (
                <>
                  <Select
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; name: string; value: ... Remove this comment to see the full error message
                    name="qtySelect"
                    value={getOperationUnitTitle(subProcess?.unit_name)}
                    id={`sub_process_${processKey}_${parentKey}_${no}_qtySelect`}
                    onSelect={(value: any) => unitOnSelect(value)}
                    className="tw-w-1/2"
                    placeholder=""
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <div className="tw-flex tw-p-2">
                          <Input
                            className="tw-flex-auto"
                            name="qtySelect"
                            onKeyDown={(e) => handleEnterKeySubUnit(e, subUnitCustom)}
                            value={subUnitCustom.value}
                            onChange={(e) => setSubUnitCustom({ value: e.target.value, processKey: processKey, isCustom: true })}
                          />
                        </div>
                      </div>
                    )}
                  >
                    <Select.Option value={'set(s)'}>set(s)</Select.Option>

                    <Select.Option value={'pc(s)'}>pc(s)</Select.Option>

                    <Select.Option value={'place(s)'}>place(s)</Select.Option>

                    <Select.Option value={'m2'}>m2</Select.Option>
                  </Select>
                </>
              )}
            </>
          )}
        </div>
        {isRepairHistory && <div className={borderClass}></div>}
        <div className={borderClass}>
          <Direction
            valueDirection={subProcess?.direction}
            addDirectionSubProcess={addDirectionSubProcess}
            isConfirmOrder={isConfirmOrder}
            isRepairHistory={isRepairHistory}
            lookDirection={lookDirection}
            editDirection={editDirection}
            editDirectionSubProcess={editDirectionSubProcess}
            processKey={processKey}
          ></Direction>
        </div>
        <div className="tw-px-4 tw-flex-1 tw-items-center flex"></div>
      </div>
    </>
  );
}

export default OperationSubProcessComponent;
