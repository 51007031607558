const mockShipSpecTemplate = {
  ship_spec_general_info: {
    index: "1",
    ship_id: null,
    classification_no: null,
    imo_no: null,
    sentai_no: null,
    official_no: null,
    signal_letters: null,
    flag: null,
    port_of_registry: null,
    ship_name: null,
    former_name: null,
    registered_owner: null,
    management_company: null,
  },
  ship_spec_notes: {
    index: "2",
    classification_characters_notations: null,
    descriptive_notes: {
      index: "2-1",
      descriptive_notes_type: null,
      descriptive_notes_value: null,
    },
    installation_characters: null,
    installation_dscriptive_notes: {
      index: "2-2",
      installation_dscriptive_notes_type: null,
      installation_dscriptive_notes_value: null,
    },
    special_description: null,
    other_classification: null,
  },
  ship_spec_type_of_ship: {
    index: "3",
    type_of_ship_purpose: null,
    type_of_ship_certificates_sc_se_sf: null,
    type_of_ship_certificates_opp: null,
    type_of_ship_certificates_ee: null,
    type_of_ship_certificates_smc_issc: null,
  },
  ship_spec_tonnage: {
    index: "4",
    tonnage_gross_registered: null,
    tonnage_net_registered: null,
    tonnage_gross_local: null,
    tonnage_net_local: null,
    tonnage_gross_tm69: null,
    tonnage_net_tm69: null,
    deadweight: null,
  },
  ship_spec_other_descriptions: {
    index: "5",
    summer_freeboard: null,
    summer_draught: null,
    lf: null,
    continuous_max_speed: null,
    equipment_no: null,
  },
  ship_spec_size: {
    index: "6",
    overall_length: null,
    moulded_lbd: null,
    registered_lbd: null,
  },
  ship_spec_capacity: {
    index: "7",
    cargo_capacity: null,
    no_of_passengers: null,
    capacity_of_tanks: null,
  },
  ship_spec_equipments: {
    index: "8",
    lifeboats_type_no_person: null,
    rescue_boats_type_no_person: null,
    liferafts_type_no_person: null,
    radio_installations: null,
    navigation_equipment: null,
  },
  ship_spec_engines: {
    index: "8",
    no_and_kind_of_engines: null,
    bore_x_stroke: null,
    power: null,
    revolution: null,
    manufacturer: null,
  },
  ship_spec_boilers: {
    index: "9",
    no_and_kind_of_boilers: null,
    pressure: null,
    evaporation: null,
    manufacturer: null,
  },
  ship_spec_generators: {
    index: "10",
    no_and_kind_of_generators: null,
    kind_of_propeller_shaft: null,
    no_shaft_diameter: null,
  },
  ship_spec_builder_and_history: {
    index: "11",
    shipbuilder: null,
    hull_no: null,
    date_of_keel_lay: null,
    date_of_launch: null,
    date_of_build: null,
    date_of_conversion: null,
  },
};
export default mockShipSpecTemplate
