import request from '../../utils/request';

const getList = () => request.get('api/v1/organization');
const delOrg = (data: any) => request.post('api/v1/organization/delete_org', data);
const addOrg = (data: any) => request.post(`api/v1/organization/create_org`, data);
const editOrg = (data: any) => request.post(`api/v1/organization/update_org`, data);

const organizationApis = {
    getList,
    delOrg,
    addOrg,
    editOrg
};

export default organizationApis;
