import { Dialog, Transition } from '@headlessui/react';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from '../../../../../store/mainSlice';

function DirectionModal(props: any) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  const { directionDataModal } = useSelector((state) => state.main);
  const [dataItem, setDataItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'directionValue' does not exist on type '... Remove this comment to see the full error message
  const [directionValue, setDirectionValue] = useState(dataItem?.directionValue);

  useEffect(() => {
    if (directionDataModal) {
      setDataItem(directionDataModal.item);
      setDirectionValue(directionDataModal.item?.directionValue);
      setIsOpen(directionDataModal.isOpen);
    }
  }, [directionDataModal]);

  const onCloseModal = () => {
    dispatch(
      mainActions.setDirectionDataModal({
        isOpen: false,
        item: null,
      }),
    );
  };

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onCloseModal}>
        <div className="flex items-center justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle max-w-screen-xl w-full">
              <div className="flex flex-col bg-white py-4 px-8">
                <div className="flex tw-justify-between">
                  <div className="tw-font-bold tw-text-xl pb-3">
                    {
                      // @ts-ignore
                      dataItem?.lock ? 'Direction' : dataItem?.updated ? 'Edit direction' : 'Add direction'
                    }
                    <span className="text-purple-400 ml-2">
                      {/* // @ts-expect-error ts-migrate(2339) FIXME: Property 'no' does not exist on type 'never'. */}
                      {
                        // @ts-ignore
                        dataItem?.no
                      }
                    </span>
                  </div>
                  <button onClick={onCloseModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 tw-text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="flex flex-row content-center py-4 mb-3">
                  <div className="flex flex-col tw-w-full pr-3 tw-h-60">
                    {/* // @ts-expect-error ts-migrate(2339) FIXME: Property 'lock' does not exist on type 'never'. */}
                    {
                      //@ts-ignore
                      dataItem?.lock ? (
                        <>
                          <div className="tw-font-bold tw-text-lg pb-2">Contents of direction</div>
                          <div className="break-words whitespace-pre-wrap overflow-y-scroll">{directionValue}</div>
                        </>
                      ) : (
                        <>
                          <div className="flex tw-justify-between">
                            <div className="tw-font-bold tw-text-lg pb-2">Contents of direction</div>
                            <p className="tw-cursor-pointer underline underline-offset-1 opacity-60" onClick={() => setDirectionValue('')}>
                              Clear
                            </p>
                          </div>
                          <textarea
                            className="resize-none rounded-md tw-px-2 tw-h-60 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-full px-3 py-4"
                            value={directionValue}
                            onChange={(e) => setDirectionValue(e.target.value)}
                            placeholder="Contents of direction"
                          />
                        </>
                      )
                    }
                  </div>
                </div>
                <div className="flex justify-end">
                  {/* // @ts-expect-error ts-migrate(2339) FIXME: Property 'lock' does not exist on type 'never'. */}
                  {
                    // @ts-ignore
                    !dataItem?.lock && (
                      <button
                        onClick={() => props.onSubmit(directionValue, dataItem)}
                        className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-9 py-2.5 mb-2 tw-text-white font-bold tw-bg-primary mr-2"
                      >
                        {/* // @ts-expect-error ts-migrate(2339) FIXME: Property 'updated' does not exist on type 'never'. */}
                        {
                          // @ts-ignore
                          dataItem?.updated ? 'Edit' : 'Add'
                        }
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default memo(DirectionModal);
