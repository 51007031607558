import { useState } from "react";

const useFormInput = ({
    initialValue,
    placeholder
}: any) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e: any) => {
        setValue(e.target.value);
    };

    const clear = () => {
        setValue("");
    };

    const set = (newValue: any) => {
        setValue(newValue);
    };

    return {
        value,
        placeholder: placeholder || "",
        onChange: handleChange,
        set: set,
        clear: clear
    };
};

export default useFormInput;
