import * as React from 'react';
import { Input, Select } from 'antd';

type Props = {
  index?: string;
  no: string;
  parentKey: string;
  position?: number;
  key: string;
  valueProcess?: any;
  valueSubProcess?: any;
  keyStructs: boolean;
  isMutilOperations: boolean;
  generalDateInput: boolean;
  subProcessTemplate: never[];
  openModalMultiOperations: () => void;
  handleEnterKeySubOperation: (e: any) => void;
  subOperationCustom: any;
  setSubOperationCustom: (e: any) => void;
  getOperationUnitTitle: (key: any) => any;
  fnAddOperation: (data: any) => void;
  selectOperation?: any | undefined;
  fnDeleteOperation(data: any): void;
  isConfirmOrder: boolean;
  operationOnSelect: (value: any, option: any, subProcess: any) => void;
};

const SelectOperation = (props: Props): React.ReactElement => {
  return (
    <>
      {
        <div className={`${props.isMutilOperations ? 'tw-flex' : ''}`}>
          {props.isMutilOperations ? (
            <div
              style={{ width: '14vw' }}
              onClick={props.openModalMultiOperations}
              className="tw-flex tw-justify-between tw-py-1 tw-px-2 tw-border-2 tw-rounded-lg tw-cursor-pointer"
            >
              <span style={{ color: props.valueProcess ? 'black' : '' }} className={`tw-text-sm tw-mr-2 ant-select-selection-placeholder tw-text-gray-300`}>
                {!props.valueProcess ? 'Select operation' : props.getOperationUnitTitle(props.valueSubProcess ? props.valueSubProcess?.[0]?.['title'] : null)}
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" style={{ marginTop: 3 }} className="h-4 w-4 tw-text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
            </div>
          ) : (
            <>
              <Select
                // name="operation"
                style={{ width: '14vw' }}
                value={props.getOperationUnitTitle(props.valueSubProcess ? props.valueSubProcess?.[0]?.['title'] : null)}
                id={props.parentKey + '_' + props.no + 'operation'}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: any, option: any, subProcess: any) =... Remove this comment to see the full error message
                onSelect={props.operationOnSelect}
                placeholder="Select operation"
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <div className="tw-flex tw-p-2">
                      <Input
                        className="tw-flex-auto"
                        name="operation"
                        onKeyDown={props.handleEnterKeySubOperation}
                        value={props.subOperationCustom.value}
                        onChange={(e) => props.setSubOperationCustom({ value: e.target.value, processKey: props.valueProcess, prev: 0, isCustom: true })}
                      />
                    </div>
                  </div>
                )}
              >
                {Object.keys(props.subProcessTemplate || []).length > 0
                  ? Object.keys(props.subProcessTemplate).map((key) => (
                      <Select.Option value={key}>{props.getOperationUnitTitle(props.subProcessTemplate[Number(key)]?.['title'])}</Select.Option>
                    ))
                  : // ts-ignore
                    Object.keys(props.selectOperation || []).map((key) => {
                      <Select.Option value={key}>{props.selectOperation[key]}</Select.Option>;
                    })}
              </Select>
            </>
          )}
          {/*Button add operation to sub_process */}

          {!props.generalDateInput && !props.isMutilOperations && props.valueProcess && (!props.valueSubProcess || Object.keys(props.valueSubProcess).length === 1) && (
            <>
              <button
                className="tw-ml-2"
                onClick={() =>
                  props.fnDeleteOperation({
                    no: props.no,
                    parentKey: props.parentKey,
                    position: props.position,
                    processKey: props.valueProcess,
                    keyStructs: props.keyStructs,
                  })
                }
                hidden={props.isConfirmOrder}
              >
                <svg xmlns="http://www.w3.org/2000/svg" style={{ marginBottom: 4 }} width="20" height="14" fill="none" viewBox="0 0 14 14" stroke="currentColor">
                  <path stroke-linecap="round" stroke="#CBD5E1" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
                </svg>
              </button>

              <button className="tw-ml-2" onClick={() => props.fnAddOperation({ no: props.no, keyStructs: props.keyStructs })} hidden={props.isConfirmOrder}>
                <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M6 1V6M6 6V11M6 6H11M6 6H1" stroke={props.index === 'head' ? '#FFF' : '#CBD5E1'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </>
          )}
        </div>
      }
    </>
  );
};

export default SelectOperation;
