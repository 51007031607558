import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import auth from '../../../api/auth';
import { mainActions } from '../../../store/mainSlice';

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState('');
  // handle button click of login form
  const handleLogin = (event: any) => {
    event.preventDefault();
    setLoading(true);
    dispatch(mainActions.setLoading(true));
    auth
      .login({
        login_id: username.value,
        password: password.value,
      })
      .then((data) => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
        if (data.meta?.status === 'success') {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type 'AxiosRes... Remove this comment to see the full error message
          dispatch(mainActions.loginSuccess({ username: data.entity.login_id }));
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type 'AxiosRes... Remove this comment to see the full error message
          dispatch(mainActions.setUserInfo({ is_admin: data.entity.is_admin }));
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type 'AxiosRes... Remove this comment to see the full error message
          const routeDirect = data.entity.is_admin ? '/admin/users' : '/dashboard';
          history.replace(routeDirect);
        } else {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
          setError(data.meta?.message || 'Login failed');
        }
        dispatch(mainActions.setLoading(false));
      })
      .catch(() => {
        setError('Login failed');
      })
      .finally(() => {
        setLoading(false);
        dispatch(mainActions.setLoading(false));
      });
  };

  return (
    <div className="tw-w-screen tw-h-screen tw-relative tw-text-center tw-bg-secondary tw-font-noto">
      <div className="tw-transform tw--translate-y-1/2 tw--translate-x-1/2 tw-top-1/2 tw-left-1/2 tw-absolute tw-rounded-lg tw-bg-white tw-justify-between tw-flex-row tw-p-16 tw-w-96">
        <h2 className="tw-font-bold tw-mb-5 tw-text-2xl">Sign in</h2>

        <form onSubmit={handleLogin}>
          <div className="tw-text-danger tw-mx-4">{error && <small className="">{error}</small>}</div>

          <input
            className="tw-w-full tw-px-2 tw-py-3 tw-border-secondary tw-border tw-rounded tw-outline-none tw-mb-4"
            type="text"
            {...username}
            name="login_id"
            placeholder="User ID"
          />

          <input
            className="tw-w-full tw-px-2 tw-py-3 tw-border-secondary tw-border tw-rounded tw-outline-none tw-mb-4"
            type="password"
            {...password}
            name="password"
            placeholder="Password"
          />

          <div className="tw-flex-row tw-text-left tw-mb-5">
            <input type="checkbox" className="tw-transform tw-scale-150 tw-mr-3 tw-ml-1" id="remember" />

            <label htmlFor="remember" className="remember-label">
              Remember you
            </label>
          </div>

          <button
            type="submit"
            className="tw-bg-primary tw-w-full tw-flex tw-py-3 tw-justify-center tw-items-center
             tw-text-white tw-mt-10 tw-mb-16 tw-rounded focus:tw-ring-inset focus:tw-ring-secondary focus:tw-outline-none"
            disabled={loading}
          >
            {loginSVG}

            <p className="tw-ml-4 tw-block">Sign in</p>
          </button>
        </form>
      </div>
    </div>
  );
}

const loginSVG = (() => {
  return (
    <svg style={{ transform: 'rotate(180deg)' }} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 4L7.6 5.4L10.2 8H0V10H10.2L7.6 12.6L9 14L14 9L9 4ZM18 16H10V18H18C19.1 18 20 17.1 20 16V2C20 0.9 19.1 0 18 0H10V2H18V16Z" fill="white" />
    </svg>
  );
})();

const useFormInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default Login;
