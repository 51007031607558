import Loading from 'atoms/Loading';
import RadioButton from 'atoms/RadioButton';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Element } from 'react-scroll';
import api from '../../../api';
import SaveDraftModal from '../../../components/SaveDraftModal';
import { mainActions } from '../../../store/mainSlice';
import { shipsActions } from '../../../store/shipsSlice';
import { StatutorySurveyItem, StatutorySurveyItemKey, StatutorySurveyItemProps } from './StatutorySurveys';

function ApplicationForm(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [shipId, setShipId] = useState(null);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ships' does not exist on type 'DefaultRo... Remove this comment to see the full error message
  const { applicationForm, selectedShip, dockOrderDraft } = useSelector((state) => state.ships);
  const [draftId, setDraftId] = useState(null);
  const [draftData, setDraftData] = useState(null);

  //Applicant
  const applicantPurchaseOrderNo = useFormInput({ initialValue: '', placeholder: '1234567890' });
  const applicantDateOfSurvey = useFormInput({ initialValue: '', placeholder: '' });
  const applicantName = useFormInput({ initialValue: '', placeholder: 'Sailor Smith' });
  const applicantAddress = useFormInput({ initialValue: '', placeholder: 'Adress123' });
  const applicantTel = useFormInput({ initialValue: '', placeholder: '123-4567-890' });
  const applicantFax = useFormInput({ initialValue: '', placeholder: '123-4567-890' });
  const applicantEmail = useFormInput({ initialValue: '', placeholder: 'example@xmail.com' });
  const applicantSignatureOfApplicant = useFormInput({ initialValue: '', placeholder: '' });
  const applicantNameInBlockCapitals = useFormInput({ initialValue: '', placeholder: '' });

  //Billing Contact
  const billingName = useFormInput({ initialValue: '', placeholder: 'Sailor Smith' });
  const billingTel = useFormInput({ initialValue: '', placeholder: '123-4567-890' });
  const billingFax = useFormInput({ initialValue: '', placeholder: '123-4567-890' });
  const billingEmail = useFormInput({ initialValue: '', placeholder: 'example@xmail.com' });
  const billingSignatureOfApplicant = useFormInput({ initialValue: '', placeholder: '' });
  const billingNameInBlockCapitals = useFormInput({ initialValue: '', placeholder: '' });
  const billingShipName = useFormInput({ initialValue: '', placeholder: 'Sailor Smith' });
  const billingGT = useFormInput({ initialValue: '', placeholder: 'Address123' });
  const billingClassNo = useFormInput({ initialValue: '', placeholder: '1234567890' });
  const billingShipOwner = useFormInput({ initialValue: '', placeholder: 'Sailor Smith' });
  const billingIMONo = useFormInput({ initialValue: '', placeholder: '1234567890' });
  const billingFlag = useFormInput({ initialValue: '', placeholder: 'Japan' });
  const billingOfficialNo = useFormInput({ initialValue: '', placeholder: '1234567890' });
  const billingCallSign = useFormInput({ initialValue: '', placeholder: '' });
  const billingPurposeOfShip = useFormInput({ initialValue: '', placeholder: '' });
  const billingServiceArea = useFormInput({ initialValue: '', placeholder: '' });
  const billingOtherClass = useFormInput({ initialValue: '', placeholder: '' });
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Application htmlForm
  //1-1
  const [classMaintenanceSurveys, setClassMaintenanceSurveys] = useState({
    docking_surbet: false,
    normal_DS: false,
    in_water_survey_in_lieu_of_DS: false,

    propeller_shaft_survey: false,
    propeller_shaft_survey_no_1: false,
    propeller_shaft_survey_no_1_occasional_survey: false,
    propeller_shaft_survey_no_1_partial_survey: false,
    propeller_shaft_survey_no_1_survey_once_every: false,
    propeller_shaft_survey_no_1_extension_up_to_1_year: false,
    propeller_shaft_survey_no_1_extension_up_to_3_months: false,

    propeller_shaft_survey_no_2: false,
    propeller_shaft_survey_no_2_occasional_survey: false,
    propeller_shaft_survey_no_2_partial_survey: false,
    propeller_shaft_survey_no_2_survey_once_every: false,
    propeller_shaft_survey_no_2_extension_up_to_1_year: false,
    propeller_shaft_survey_no_2_extension_up_to_3_months: false,

    ships_with_the_notation_no_1: false,
    ships_with_the_notation_no_1_method_1: false,
    ships_with_the_notation_no_1_method_2: false,
    ships_with_the_notation_no_1_method_3: false,
    ships_with_the_notation_no_1_survey_once_every_15_years: false,
    ships_with_the_notation_no_1_extension_up_to_2_5_year: false,
    ships_with_the_notation_no_1_extension_up_to_1_year: false,
    ships_with_the_notation_no_1_extension_up_to_3_months: false,

    ships_with_the_notation_no_2: false,
    ships_with_the_notation_no_2_method_1: false,
    ships_with_the_notation_no_2_method_2: false,
    ships_with_the_notation_no_2_method_3: false,
    ships_with_the_notation_no_2_survey_once_every_15_years: false,
    ships_with_the_notation_no_2_extension_up_to_2_5_year: false,
    ships_with_the_notation_no_2_extension_up_to_1_year: false,
    ships_with_the_notation_no_2_extension_up_to_3_months: false,

    boiler_survey: false,
    boiler_survey_no_1: false,
    boiler_survey_no_2: false,
    boiler_survey_no_3: false,

    planned_machinery_surveys: false,
    continuous_machinery_survey: false,
    periodical_surveys: false,
    planned_machinery_surveys_commence: false,
    planned_machinery_surveys_incomplete: false,
    planned_machinery_surveys_complete: false,

    continuous_hull_survey: false,

    occasional_survey: false,
  });
  //Class maintenance surveys
  const occasionalContents = useFormInput({ initialValue: 'ABC Certificate', placeholder: '' });

  //1-2
  const [installationSurveys, setInstallationSurveys] = useState({
    cargoHandlingAppliances: false,
    annualThorough: false,
    loadTest: false,
    automatic_and_remote_control_systems: false,
    cargo_refrigerating_installations: false,
    continuous: false,
    crew_accommodation_arrangement: false,
    navigation_bridge_system: false,
    preventive_machinery_maintenance_systems: false,
    integrated_fire_control_systems: false,
    hull_monitoring_system: false,
    diving_systems: false,
  });
  //Automatic and remote control systems
  const [automaticAndRemoteControlSystemsValue, setAutomaticAndRemoteControlSystemsValue] = useState(null);

  //1-3
  const [statutorySurveys, setStatutorySurveys] = useState<{
    [key in StatutorySurveyItemKey]: boolean;
  }>({
    load_line: false,
    safety_construction: false,
    safety_equipment: false,
    safety_radio: false,
    chemical_fitness_cert: false,
    gas_fitness_cert: false,
    dangerous_goods_fitness: false,
    oil_pollution_prevention: false,
    noxious_liquid_substances: false,
    sewage_pollution_prevention: false,
    air_pollution_prevention: false,
    energy_efficiency: false,
    anti_fouling_system: false,
    ballast_water_management: false,
  });
  const [loadLineValue, setLoadLineValue] = useState<string | null>(null);
  const [safetyConstructionValue, setSafetyConstructionValue] = useState<string | null>(null);
  const [safetyEquipmentValue, setSafetyEquipmentValue] = useState<string | null>(null);
  const [safetyRadioValue, setSafetyRadioValue] = useState<string | null>(null);
  const [chemicalFitnessCertValue, setChemicalFitnessCertValue] = useState<string | null>(null);
  const [gasFitnessCertValue, setGasFitnessCertValue] = useState<string | null>(null);
  const [dangerousGoodsFitnessValue, setDangerousGoodsFitnessValue] = useState<string | null>(null);
  const [oilPollutionPreventionValue, setOilPollutionPreventionValue] = useState<string | null>(null);
  const [noxiousLiquidSubstancesValue, setNoxiousLiquidSubstancesValue] = useState<string | null>(null);
  const [sewagePollutionPreventionValue, setSewagePollutionPreventionValue] = useState<string | null>(null);
  const [airPollutionPreventionValue, setAirPollutionPreventionValue] = useState<string | null>(null);
  const [energyEfficiencyValue, setEnergyEfficiencyValue] = useState<string | null>(null);
  const [antiFoulingSystemValue, setAntiFoulingSystemValue] = useState<string | null>(null);
  const [ballastWaterManagementValue, setBallastWaterManagementValue] = useState<string | null>(null);

  const statutorySurveyItems: Omit<StatutorySurveyItemProps, 'leftItemValue' | 'onChangeLeftItemValue' | 'bgColor'>[] = [
    {
      itemKey: 'load_line',
      label: 'Load line (LL)',
      rightItemValue: loadLineValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setLoadLineValue(e.target.value),
      options: ['Initial', 'Renewal', 'Annual', 'Occasional'],
    },
    {
      itemKey: 'safety_construction',
      label: 'Safety construction (SC)',
      rightItemValue: safetyConstructionValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setSafetyConstructionValue(e.target.value),
      options: ['Initial', 'Renewal', 'Intermediate', 'Annual', 'Occasional'],
    },
    {
      itemKey: 'safety_equipment',
      label: 'Safety equipment (SE)',
      rightItemValue: safetyEquipmentValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setSafetyEquipmentValue(e.target.value),
      options: ['Initial', 'Renewal', 'Intermediate', 'Periodical', 'Annual', 'Occasional'],
    },
    {
      itemKey: 'safety_radio',
      label: 'Safety radio (SR)',
      rightItemValue: safetyRadioValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setSafetyRadioValue(e.target.value),
      options: ['Initial', 'Renewal', 'Periodical', 'Occasional'],
    },
    {
      itemKey: 'chemical_fitness_cert',
      label: 'Chemical fitness cert (CHM)',
      rightItemValue: chemicalFitnessCertValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setChemicalFitnessCertValue(e.target.value),
      options: ['Initial', 'Renewal', 'Intermediate', 'Annual', 'Occasional'],
    },
    {
      itemKey: 'gas_fitness_cert',
      label: 'Gas fitness cert. (GAS)',
      rightItemValue: gasFitnessCertValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setGasFitnessCertValue(e.target.value),
      options: ['Initial', 'Renewal', 'Intermediate', 'Annual', 'Occasional'],
    },
    {
      itemKey: 'dangerous_goods_fitness',
      label: 'Dangerous goods fitness (DG)',
      rightItemValue: dangerousGoodsFitnessValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setDangerousGoodsFitnessValue(e.target.value),
      options: ['Initial', 'Renewal', 'Occasional'],
    },
    {
      itemKey: 'oil_pollution_prevention',
      label: 'Oil pollution prevention (OPP)',
      rightItemValue: oilPollutionPreventionValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setOilPollutionPreventionValue(e.target.value),
      options: ['Initial', 'Renewal', 'Intermediate', 'Annual', 'Occasional'],
    },
    {
      itemKey: 'noxious_liquid_substances',
      label: 'Noxious liquid substances in bulk (NLS)',
      rightItemValue: noxiousLiquidSubstancesValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setNoxiousLiquidSubstancesValue(e.target.value),
      options: ['Initial', 'Renewal', 'Intermediate', 'Annual', 'Occasional'],
    },
    {
      itemKey: 'sewage_pollution_prevention',
      label: 'Sewage pollution prevention (SPP)',
      rightItemValue: sewagePollutionPreventionValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setSewagePollutionPreventionValue(e.target.value),
      options: ['Initial', 'Renewal', 'Occasional'],
    },
    {
      itemKey: 'air_pollution_prevention',
      label: 'Air pollution prevention (APP)',
      rightItemValue: airPollutionPreventionValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setAirPollutionPreventionValue(e.target.value),
      options: ['Initial', 'Renewal', 'Intermediate', 'Annual', 'Occasional'],
    },
    {
      itemKey: 'energy_efficiency',
      label: 'Energy efficiency (EE)',
      rightItemValue: energyEfficiencyValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setEnergyEfficiencyValue(e.target.value),
      options: ['Initial', 'Occasional'],
    },
    {
      itemKey: 'anti_fouling_system',
      label: 'Anti-fouling system (AFS)',
      rightItemValue: antiFoulingSystemValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setAntiFoulingSystemValue(e.target.value),
      options: ['Initial', 'Periodical', 'Occasional'],
    },
    {
      itemKey: 'ballast_water_management',
      label: 'Ballast water management (BWM)',
      rightItemValue: ballastWaterManagementValue,
      onChangeRightItemValue: (e: React.ChangeEvent<HTMLInputElement>) => setBallastWaterManagementValue(e.target.value),
      options: ['Initial', 'Renewal', 'Intermediate', 'Annual', 'Occasional'],
    },
  ];

  //1-4
  const [otherSurveys, setOtherSurveys] = useState({
    inventory_of_hazardous_material: false,
    initial_renewal: false,
    additional: false,
    other_survey: false,
  });
  //Other Survey
  const otherSurveyContents = useFormInput({ initialValue: 'ABC Certificate', placeholder: '' });

  //Certificate issued
  const [certificateIssued, setCertificateIssued] = useState({
    classification_cert: false,
    dangerous_Goods_fitness_cert: false,
    installation_registration_cert: false,
    opp_certificate: false,
    cargo_gear_load_test_certificate: false,
    nls_certificate: false,
    ll_certificate: false,
    spp_certificate: false,
    sc_certificate: false,
    app_certificate: false,
    se_certificate: false,
    ee_certificate: false,
    sr_certificate: false,
    afs_certificate: false,
    chemical_fitness_certificate: false,
    bmw_certificate: false,
    gas_fitness_certificate: false,
    international_tonnage_cert: false,
    sf_certificate: false,
    ihm_certificate: false,
    hong_kong_convention: false,
    eu_regulation: false,
  });
  const exemptionCertificate = useFormInput({ initialValue: '', placeholder: '' });
  const otherCertificate = useFormInput({ initialValue: 'ABC Certificate', placeholder: '' });

  //Date and Place of Survey
  const placeOfSurvey = useFormInput({ initialValue: '', placeholder: '' });
  const dateOfSurvey = useFormInput({ initialValue: '', placeholder: '' });
  const dateOfSurveyETA = useFormInput({ initialValue: '', placeholder: '' });
  const dateOfSurveyETD = useFormInput({ initialValue: '', placeholder: '' });
  const nameOfLocalAgent = useFormInput({ initialValue: '', placeholder: '' });
  const contactPerson = useFormInput({ initialValue: '', placeholder: '' });
  const contactPersonTel = useFormInput({ initialValue: '', placeholder: '' });
  const contactPersonFax = useFormInput({ initialValue: '', placeholder: '' });
  const contactPersonEmail = useFormInput({ initialValue: '', placeholder: '' });

  //Message
  const message = useFormInput({
    initialValue:
      'Applicants can write some messages in this area if they have something to write. Applicants can write some messages in this area if they have someting to write.',
    placeholder: '',
  });

  const listApplicant = [
    {
      title: 'Purchase order no',
      hint: '',
      inputProps: applicantPurchaseOrderNo,
    },
    {
      title: 'Date of Survey',
      hint: '',
      inputProps: applicantDateOfSurvey,
    },
    {
      title: 'Name',
      hint: '',
      inputProps: applicantName,
    },
    {
      title: 'Address',
      hint: '',
      inputProps: applicantAddress,
    },
    {
      title: 'Tel',
      hint: '',
      inputProps: applicantTel,
    },
    {
      title: 'Fax',
      hint: '',
      inputProps: applicantFax,
    },
    {
      title: 'E-mail',
      hint: '',
      inputProps: applicantEmail,
    },
    {
      title: 'Signature of applicant',
      hint: '',
      inputProps: applicantSignatureOfApplicant,
    },
    {
      title: 'Name in block capitals',
      hint: '',
      inputProps: applicantNameInBlockCapitals,
    },
  ];

  const listBillingContact = [
    {
      title: 'Name',
      hint: '',
      inputProps: billingName,
    },
    {
      title: 'Tel',
      hint: '',
      inputProps: billingTel,
    },
    {
      title: 'Fax',
      hint: '',
      inputProps: billingFax,
    },
    {
      title: 'Email',
      hint: '',
      inputProps: billingEmail,
    },
    {
      title: 'Signature of applicant',
      hint: '',
      inputProps: billingSignatureOfApplicant,
    },
    {
      title: 'Name in block capitals',
      hint: '',
      inputProps: billingNameInBlockCapitals,
    },
    {
      title: "Ship's name",
      hint: '',
      inputProps: billingShipName,
    },
    {
      title: 'G.T',
      hint: '',
      inputProps: billingGT,
    },
    {
      title: 'Class no.',
      hint: '',
      inputProps: billingClassNo,
    },
    {
      title: 'Ship owner',
      hint: '',
      inputProps: billingShipOwner,
    },
    {
      title: 'IMO no.',
      hint: '',
      inputProps: billingIMONo,
    },
    {
      title: 'Flag',
      hint: '',
      inputProps: billingFlag,
    },
    {
      title: 'Official no.',
      hint: '',
      inputProps: billingOfficialNo,
    },
    {
      title: 'Call sign',
      hint: '',
      inputProps: billingCallSign,
    },
    {
      title: 'Purpose of ship',
      hint: '',
      inputProps: billingPurposeOfShip,
    },
    {
      title: 'Service area',
      hint: '',
      inputProps: billingServiceArea,
    },
    {
      title: 'Other class (if any)',
      hint: '',
      inputProps: billingOtherClass,
    },
  ];

  const listStatutory = [
    {
      keyLeft: 'load_line',
      textLeft: 'Load line (LL)',
      keyRight: 'load_line_initial',
      textRight: '*Initial / Renewal',
    },
    {
      keyLeft: 'safety_construction',
      textLeft: 'Safety construction (SC)',
      keyRight: 'safety_construction_initial',
      textRight: '*Initial / Renewal',
    },
    {
      keyLeft: 'safety_equipment',
      textLeft: 'Safety equipment (SE)',
      keyRight: 'safety_equipment_initial',
      textRight: '*Initial / Renewal',
    },
    {
      keyLeft: 'safety_radio',
      textLeft: 'Safety radio (SR)',
      keyRight: 'safety_radio_initial',
      textRight: '*Initial / Renewal',
    },
    {
      keyLeft: 'chemical_fitness_cert',
      textLeft: 'Chemical fitness cert (CHM)',
      keyRight: 'chemical_fitness_cert_initial',
      textRight: '*Initial / Renewal',
    },
    {
      keyLeft: 'gas_fitness_cert',
      textLeft: 'Gas fitness cert. (GAS)',
      keyRight: 'gas_fitness_cert_initial',
      textRight: '*Initial / Renewal',
    },
    {
      keyLeft: 'dangerous_goods_fitness',
      textLeft: 'Dangerous goods fitness (DG)',
      keyRight: 'dangerous_goods_fitness_initial',
      textRight: '*Initial / Renewal',
    },
    {
      keyLeft: 'oil_pollution_prevention',
      textLeft: 'Oil pollution prevention (OPP)',
      keyRight: 'oil_pollution_prevention_initial',
      textRight: '*Initial / Renewal',
    },
    {
      keyLeft: 'noxious_liquid_substances',
      textLeft: 'Noxious liquid substances in bulk (NLS)',
      keyRight: 'noxious_liquid_substances_initial',
      textRight: '*Initial / Renewal',
    },
    {
      keyLeft: 'sewage_pollution_prevention',
      textLeft: 'Sewage pollution prevention (SPP)',
      keyRight: 'sewage_pollution_prevention_initial',
      textRight: '*Initial / Renewal',
    },
    {
      keyLeft: 'air_pollution_prevention',
      textLeft: 'Air pollution prevention (APP)',
      keyRight: 'air_pollution_prevention_initial',
      textRight: '*Initial / Renewal',
    },
    {
      keyLeft: 'energy_efficiency',
      textLeft: 'Energy efficiency (EE)',
      keyRight: 'energy_efficiency_initial',
      textRight: 'Initial',
    },
    {
      keyLeft: 'anti_fouling_system',
      textLeft: 'Anti-fouling system (AFS)',
      keyRight: 'anti_fouling_system_initial',
      textRight: 'Initial',
    },
    {
      keyLeft: 'ballast_water_management',
      textLeft: 'Ballast water management (BWM)',
      keyRight: 'ballast_water_management_initial',
      textRight: '*Initial / Renewal',
    },
  ];

  const listCertificate = [
    {
      keyLeft: 'classification_cert',
      textLeft: 'Classification Cert.',
      keyRight: 'dangerous_Goods_fitness_cert',
      textRight: 'Dangerous Goods Fitness Cert.',
    },
    {
      keyLeft: 'installation_registration_cert',
      textLeft: 'Installation Registration Cert.',
      keyRight: 'opp_certificate',
      textRight: 'OPP Certificate',
    },
    {
      keyLeft: 'cargo_gear_load_test_certificate',
      textLeft: 'Cargo Gear Load Test Certificate',
      keyRight: 'nls_certificate',
      textRight: 'NLS Certificate',
    },
    {
      keyLeft: 'll_certificate',
      textLeft: 'LL Certificate',
      keyRight: 'spp_certificate',
      textRight: 'SPP Certificate',
    },
    {
      keyLeft: 'sc_certificate',
      textLeft: 'SC Certificate',
      keyRight: 'app_certificate',
      textRight: 'APP Certificate',
    },
    {
      keyLeft: 'se_certificate',
      textLeft: 'SE Certificate',
      keyRight: 'ee_certificate',
      textRight: 'EE Certificate',
    },
    {
      keyLeft: 'sr_certificate',
      textLeft: 'SR Certificate',
      keyRight: 'afs_certificate',
      textRight: 'AFS Certificate',
    },
    {
      keyLeft: 'chemical_fitness_certificate',
      textLeft: 'Chemical Fitness Cert.',
      keyRight: 'bmw_certificate',
      textRight: 'BWM Certificate',
    },
    {
      keyLeft: 'gas_fitness_certificate',
      textLeft: 'Gas Fitness Certificate',
      keyRight: 'international_tonnage_cert',
      textRight: 'International Tonnage Cert. (ITC)',
    },
  ];

  const handleChangeClassMaintenanceSurveys = (e: any) => {
    setClassMaintenanceSurveys({
      ...classMaintenanceSurveys,
      [e.target.name]: e.target.checked,
    });
  };

  const handleChangeInstallationSurveys = (e: any) => {
    setInstallationSurveys({
      ...installationSurveys,
      [e.target.name]: e.target.checked,
    });
  };

  const handleChangeStatutorySurveys = (e: any) => {
    setStatutorySurveys({
      ...statutorySurveys,
      [e.target.name]: e.target.checked,
    });
  };

  const handleChangeOtherSurveys = (e: any) => {
    setOtherSurveys({
      ...otherSurveys,
      [e.target.name]: e.target.checked,
    });
  };

  const handleChangeCertificateIssued = (e: any) => {
    setCertificateIssued({
      ...certificateIssued,
      [e.target.name]: e.target.checked,
    });
  };

  const getApplicationFormData = () => {
    return {
      //Applicant
      applicantPurchaseOrderNo: applicantPurchaseOrderNo.value,
      applicantDateOfSurvey: applicantDateOfSurvey.value,
      applicantName: applicantName.value,
      applicantAddress: applicantAddress.value,
      applicantTel: applicantTel.value,
      applicantFax: applicantFax.value,
      applicantEmail: applicantEmail.value,
      applicantSignatureOfApplicant: applicantSignatureOfApplicant.value,
      applicantNameInBlockCapitals: applicantNameInBlockCapitals.value,
      //Billing Contact
      billingName: billingName.value,
      billingTel: billingTel.value,
      billingFax: billingFax.value,
      billingEmail: billingEmail.value,
      billingSignatureOfApplicant: billingSignatureOfApplicant.value,
      billingNameInBlockCapitals: billingNameInBlockCapitals.value,
      billingShipName: billingShipName.value,
      billingGT: billingGT.value,
      billingClassNo: billingClassNo.value,
      billingShipOwner: billingShipOwner.value,
      billingIMONo: billingIMONo.value,
      billingFlag: billingFlag.value,
      billingOfficialNo: billingOfficialNo.value,
      billingCallSign: billingCallSign.value,
      billingPurposeOfShip: billingPurposeOfShip.value,
      billingServiceArea: billingServiceArea.value,
      billingOtherClass: billingOtherClass.value,
      //Application htmlForm
      //1-1
      classMaintenanceSurveys,
      occasionalContents: occasionalContents.value,
      //1-2
      installationSurveys,
      automaticAndRemoteControlSystemsValue,
      //1-3
      statutorySurveys,
      loadLineValue,
      safetyConstructionValue,
      safetyEquipmentValue,
      safetyRadioValue,
      chemicalFitnessCertValue,
      gasFitnessCertValue,
      dangerousGoodsFitnessValue,
      oilPollutionPreventionValue,
      noxiousLiquidSubstancesValue,
      sewagePollutionPreventionValue,
      airPollutionPreventionValue,
      energyEfficiencyValue,
      antiFoulingSystemValue,
      ballastWaterManagementValue,
      //1-4
      otherSurveys,
      otherSurveyContents: otherSurveyContents.value,
      //Certificate issued
      certificateIssued,
      exemptionCertificate: exemptionCertificate.value,
      otherCertificate: otherCertificate.value,
      //Date and Place of Survey
      placeOfSurvey: placeOfSurvey.value,
      dateOfSurvey: dateOfSurvey.value,
      dateOfSurveyETA: dateOfSurveyETA.value,
      dateOfSurveyETD: dateOfSurveyETD.value,
      nameOfLocalAgent: nameOfLocalAgent.value,
      contactPerson: contactPerson.value,
      contactPersonTel: contactPersonTel.value,
      contactPersonFax: contactPersonFax.value,
      contactPersonEmail: contactPersonEmail.value,
      message: message.value,
    };
  };

  useEffect(() => {
    async function InitialComponent() {
      try {
        setIsLoading(true);
        const draftId = new URLSearchParams(window.location.search).get('id');
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        setDraftId(draftId);
        if (dockOrderDraft && dockOrderDraft.ship_id === selectedShip?.ship_id && Object.keys(dockOrderDraft.application || {}).length > 0) {
          handleSetData(dockOrderDraft.application);
          setDraftId(dockOrderDraft.dock_order_id);
        } else {
          const listDrafts = await api.ships.getListDraft(selectedShip?.ship_id);
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'length' does not exist on type 'AxiosRes... Remove this comment to see the full error message
          if (listDrafts !== null && listDrafts.length > 0) {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            const draft = listDrafts[listDrafts.length - 1];
            setDraftData(draft);
            handleSetData(draft.application);
            let dockOrder = {
              ship_id: selectedShip?.ship_id,
              dock_order_id: draft.dock_order_id,
              application: draft.application,
              dock_order_entity: draft.dock_order_entity,
              basic_info: draft.basic_info,
            };
            dispatch(shipsActions.setDockOrderDraft(dockOrder));
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
    InitialComponent();
  }, []);

  const handleSetData = (applicationForm: any) => {
    //Applicant
    applicantPurchaseOrderNo.set(applicationForm.applicantPurchaseOrderNo);
    applicantDateOfSurvey.set(applicationForm.applicantDateOfSurvey);
    applicantName.set(applicationForm.applicantName);
    applicantAddress.set(applicationForm.applicantAddress);
    applicantTel.set(applicationForm.applicantTel);
    applicantFax.set(applicationForm.applicantFax);
    applicantEmail.set(applicationForm.applicantEmail);
    applicantSignatureOfApplicant.set(applicationForm.applicantSignatureOfApplicant);
    applicantNameInBlockCapitals.set(applicationForm.applicantNameInBlockCapitals);
    //Billing Contact
    billingName.set(applicationForm.billingName);
    billingTel.set(applicationForm.billingTel);
    billingFax.set(applicationForm.billingFax);
    billingEmail.set(applicationForm.billingEmail);
    billingSignatureOfApplicant.set(applicationForm.billingSignatureOfApplicant);
    billingNameInBlockCapitals.set(applicationForm.billingNameInBlockCapitals);
    billingShipName.set(applicationForm.billingShipName);
    billingGT.set(applicationForm.billingGT);
    billingClassNo.set(applicationForm.billingClassNo);
    billingShipOwner.set(applicationForm.billingShipOwner);
    billingIMONo.set(applicationForm.billingIMONo);
    billingFlag.set(applicationForm.billingFlag);
    billingOfficialNo.set(applicationForm.billingOfficialNo);
    billingCallSign.set(applicationForm.billingCallSign);
    billingPurposeOfShip.set(applicationForm.billingPurposeOfShip);
    billingServiceArea.set(applicationForm.billingServiceArea);
    billingOtherClass.set(applicationForm.billingOtherClass);
    //Application htmlForm
    setClassMaintenanceSurveys(applicationForm.classMaintenanceSurveys);
    setInstallationSurveys(applicationForm.installationSurveys);
    // StatutorySurveys
    setStatutorySurveys(applicationForm.statutorySurveys);
    setLoadLineValue(applicationForm.loadLineValue);
    setSafetyConstructionValue(applicationForm.safetyConstructionValue);
    setSafetyEquipmentValue(applicationForm.safetyEquipmentValue);
    setSafetyRadioValue(applicationForm.safetyRadioValue);
    setChemicalFitnessCertValue(applicationForm.chemicalFitnessCertValue);
    setGasFitnessCertValue(applicationForm.gasFitnessCertValue);
    setDangerousGoodsFitnessValue(applicationForm.dangerousGoodsFitnessValue);
    setOilPollutionPreventionValue(applicationForm.oilPollutionPreventionValue);
    setNoxiousLiquidSubstancesValue(applicationForm.noxiousLiquidSubstancesValue);
    setSewagePollutionPreventionValue(applicationForm.sewagePollutionPreventionValue);
    setAirPollutionPreventionValue(applicationForm.airPollutionPreventionValue);
    setEnergyEfficiencyValue(applicationForm.energyEfficiencyValue);
    setAntiFoulingSystemValue(applicationForm.antiFoulingSystemValue);
    setBallastWaterManagementValue(applicationForm.ballastWaterManagementValue);

    setOtherSurveys(applicationForm.otherSurveys);
    occasionalContents.set(applicationForm.occasionalContents);
    setAutomaticAndRemoteControlSystemsValue(applicationForm.automaticAndRemoteControlSystemsValue);
    otherSurveyContents.set(applicationForm.otherSurveyContents);
    //Certificate issued
    setCertificateIssued(applicationForm.certificateIssued);
    exemptionCertificate.set(applicationForm.exemptionCertificate);
    otherCertificate.set(applicationForm.otherCertificate);
    //Date and Place of Survey
    placeOfSurvey.set(applicationForm.placeOfSurvey);
    dateOfSurvey.set(applicationForm.dateOfSurvey);
    dateOfSurveyETA.set(applicationForm.dateOfSurveyETA);
    dateOfSurveyETD.set(applicationForm.dateOfSurveyETD);
    nameOfLocalAgent.set(applicationForm.nameOfLocalAgent);
    contactPerson.set(applicationForm.contactPerson);
    contactPersonTel.set(applicationForm.contactPersonTel);
    contactPersonFax.set(applicationForm.contactPersonFax);
    contactPersonEmail.set(applicationForm.contactPersonEmail);
    //Message
    message.set(applicationForm.message);
  };

  const handleSaveDraft = async () => {
    let dockOrder = _.cloneDeep(dockOrderDraft);
    dockOrder.application = getApplicationFormData();
    dockOrder.is_draft = true;
    try {
      dispatch(mainActions.setLoading(true));
      let response = await api.dockOrderApis.saveDraft(dockOrder);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
      if (response.meta.status === 'success') {
        dispatch(mainActions.setLoading(false));
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type 'AxiosRes... Remove this comment to see the full error message
        dispatch(shipsActions.setDockOrderDraft(response?.entity));
        // history.push(`/dashboard`);
        // localStorage.setItem("openDraft", true);
        setOpenDraftModal(true);
      } else {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
        alert(response.meta.message);
      }
    } catch (err) {
      console.error(err);
      dispatch(mainActions.setLoading(false));
    }
  };

  const handleSave = () => {
    saveTempDockOrder();
    history.push(`/dock-order`);
  };

  const saveTempDockOrder = () => {
    let dockOrder = _.cloneDeep(dockOrderDraft);
    dockOrder.application = getApplicationFormData();
    dispatch(shipsActions.setDockOrderDraft(dockOrder));
    history.push('/basic-info');
  };

  const onCloseDraftModal = () => {
    dispatch(shipsActions.setDockOrderDraft(null));
    history.push('/dashboard');
  };

  const onContinueEdit = () => {
    setOpenDraftModal(false);
  };
  // prettier-ignore
  return (
    <>
      <Loading isLoading={isLoading}></Loading>
      {openDraftModal && <SaveDraftModal isOpen={openDraftModal} onClose={onCloseDraftModal} onContinue={onContinueEdit} />}

      <div className="tw-flex">
        <div className="tw-pt-14 tw-flex-grow tw-max-h-screen tw-flex tw-flex-col">
          <div className="header tw-px-44 tw-flex tw-items-center tw-justify-between tw-bg-lightdary">
            <button
              onClick={() => {
                saveTempDockOrder();
              }}
              className="tw-h-full tw-flex tw-items-center tw-text-xl active:tw-bg-secondary"
            >
              <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 15L1 8L8 1" stroke="#0F172A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="tw-px-3">Back</span>
            </button>

            <div className={'tw-mt-30 tw-ml-40'}>
              <ul className="w-full steps tw-mt-5">
                <li className="step step-success" data-content="✓">
                  Basic info
                </li>
                <li className="step step-success">Application Form</li>

                <li className="step">Dock order</li>
              </ul>
            </div>

            <button
              onClick={handleSaveDraft}
              className="tw-flex tw-items-center tw-justify-center tw-h-11 tw-rounded-lg tw-rounded-2xl tw-border-2
           tw-border-primary tw-px-3 tw-text-primary"
            >
              Save Draft
            </button>
          </div>

          <div className="header tw-px-44 tw-flex tw-items-center tw-bg-lightdary pb-5 pt-3">
            <div className="tw-font-bold tw-text-3xl">Create dock order</div>
          </div>

          <div className="tw-px-44 tw-bg-lightdary">
            <div className="tw-flex">
              <div className="tw-font-bold tw-text-xl tw-w-1/6">Applicant</div>

              <Element name="Applicant" className="tw-w-full rounded-lg">
                <div className="tw-flex-1 tw-overflow-y-auto tw-whitespace-nowrap rounded-lg">
                  {listApplicant.map((field, j) => {
                    return <CustomRow index={`${j + 1}`} title={field.title} hint={field.hint} ship={{ ship_id: shipId }} key={'field_' + j} inputProps={field.inputProps} />;
                  })}
                </div>

                <p className="tw-font-thin tw-text-xs tw-py-2">
                  We hereby request that you carry out survey(s) and/or issue certificate(s) described below. This request is made on the basis that we accept the provisions of
                  REGULATIONS htmlFor THE CLASSIFICATION AND REGISTRY OF SHIPS, CONDITIONS OF SERVICE htmlFor CLASSIFICATION OF SHIPS AND REGISTRATION OF INSTALLATIONS and
                  REGULATIONS htmlFor THE ISSUE OF STATUTORY CERTIFICATES (as well as the provisions of REGULATIONS htmlFor TECHNICAL SERVICES when requesting technical services)
                  of NIPPON KAIJI KYOKAI and that we allow flag authorities and authorized audit teams on board htmlFor the purpose of their assessing NIPPON KAIJI KYOKAI’s
                  compliance with applicable rules, regulations and quality standard. Whether surveys are completed or not, we agree to pay all survey fees and expenses incurred as
                  a result of the above-mentioned survey(s) and/or issuance of relevant certificate(s) within the payment term designated on your invoice.{' '}
                </p>
              </Element>
            </div>

            <div className="th tw-flex tw-border-secondary tw-border-b-2 tw-px-5 tw-my-3" />

            <div className="tw-flex">
              <div className="tw-font-bold tw-text-xl tw-w-1/6">Billing Contact</div>

              <Element name="BillingContact" className="tw-w-full">
                <p className="font-thin text-xs pb-3">
                  We ensure the payment of all survey fees and expenses incurred in the below-mentioned survey(s) and/or issue of relevant certificate(s).
                </p>

                <div className="tw-flex-1 tw-overflow-y-auto tw-whitespace-nowrap rounded-lg">
                  {listBillingContact.map((field, j) => {
                    return <CustomRow index={`${j + 1}`} title={field.title} hint={field.hint} ship={{ ship_id: shipId }} key={'field_' + j} inputProps={field.inputProps} />;
                  })}
                </div>
              </Element>
            </div>

            <div className="th tw-flex tw-border-secondary tw-border-b-2 tw-px-5 tw-my-3" />

            <div className="tw-flex">
              <div className="tw-font-bold tw-text-xl">Application Form</div>

              <div className="tw-ml-12 tw-flex-1 tw-flex-row-reverse">
                <div className="tw-text-lg tw-mb-0.5">
                  <label className="tw-mr-3">1</label>

                  <label className="">Survey(s) to be carried out</label>
                </div>

                <div className="tw-flex-1 tw-overflow-y-auto tw-whitespace-nowrap rounded-lg" id="maintenance">
                  <div
                    className="th accordion-button bg-gray-300 tw-cursor-pointer tw-text-black tw-px-2 tw-py-1 tw-text-black tw-justify-around rounded-t-lg"
                    data-bs-toggle="collapse"
                    data-bs-target="#class-maintenance"
                    aria-expanded="true"
                    aria-controls="class-maintenance"
                  >
                    <label className="tw-pr-5 tw-font-normal tw-text-lg">1 - 1</label>

                    <label className="tw-text-bold tw-text-lg">Class maintenance surveys</label>
                  </div>

                  <div className="tw-table tw-w-full accordion-collapse collapse show rounded-b-lg" id="class-maintenance" aria-labelledby="dropdownButton">
                    <div className={'tw-bg-lightdary tw-grid tw-grid-cols-3'}>
                      <div className=" tw-border-r-2 tw-border-secondary tw-p-2 ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="dockingSurbey"
                            id="dockingSurbey"
                            name="docking_surbet"
                            onChange={handleChangeClassMaintenanceSurveys}
                            checked={classMaintenanceSurveys?.docking_surbet}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="dockingSurbey">
                            Docking Survey (DS)
                          </label>
                        </div>
                      </div>

                      <div className=" tw-col-span-2">
                        <div className="tw-grid tw-grid-cols-3">
                          <div className="tw-form-check tw-flex tw-items-center tw-border-r-2 tw-border-dashed tw-border-gray-300 tw-p-2">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="docking-normalDS"
                              id="docking-normalDS"
                              name="in_water_survey_in_lieu_of_DS"
                              onChange={handleChangeClassMaintenanceSurveys}
                              checked={classMaintenanceSurveys?.in_water_survey_in_lieu_of_DS}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="docking-normalDS">
                              Normal DS
                            </label>
                          </div>

                          <div className="tw-col-span-2 tw-form-check tw-flex tw-items-center tw-p-2 ">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="normal_DS"
                              id="docking-normalDS"
                              name="normal_DS"
                              onChange={handleChangeClassMaintenanceSurveys}
                              checked={classMaintenanceSurveys?.normal_DS}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="docking-inWater">
                              In-water survey in lieu of DS
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tw-bg-slate-200 tw-grid tw-grid-cols-3 bg-white">
                      <div className=" tw-border-r-2 tw-border-secondary tw-p-2  ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="propeller"
                            id="propeller"
                            name="propeller_shaft_survey"
                            onChange={handleChangeClassMaintenanceSurveys}
                            checked={classMaintenanceSurveys?.propeller_shaft_survey}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="propeller">
                            Propeller shaft survey (PS)
                          </label>
                        </div>
                      </div>

                      <div className=" tw-col-span-2">
                        <div className="tw-grid tw-grid-cols-6 tw-flex tw-border-b-2 tw-border-dashed tw-border-gray-300 ">
                          <div className="tw-form-check tw-border-r-2 tw-border-dashed tw-border-gray-300 tw-p-2">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="propeller-no1"
                              id="propeller-no1"
                              name="propeller_shaft_survey_no_1"
                              onChange={handleChangeClassMaintenanceSurveys}
                              checked={classMaintenanceSurveys?.propeller_shaft_survey_no_1}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="propeller-no1">
                              No.1
                            </label>
                          </div>

                          <div className="tw-col-start-2 tw-col-end-7 ">
                            <div className="tw-items-center tw-flex-wrap">
                              <div className="tw-flex tw-item-center tw-flex-wrap">
                                <div className="tw-form-check tw-p-2 tw-mr-44">
                                  <input
                                    className="tw-focus:outline-hidden tw-checked:bg-blue-600 tw-checked:border-blue-600 tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="propeller-no1-ordinary"
                                    id="propeller-no1-ordinary"
                                    name="propeller_shaft_survey_no_1_occasional_survey"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.propeller_shaft_survey_no_1_occasional_survey}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="propeller-no1-ordinary">
                                    Ordinary Survey
                                  </label>
                                </div>

                                <div className="tw-form-check tw-p-2">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="propeller-no1-partial"
                                    id="propeller-no1-partial"
                                    name="propeller_shaft_survey_no_1_partial_survey"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.propeller_shaft_survey_no_1_partial_survey}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="propeller-no1-partial">
                                    Partial Survey
                                  </label>
                                </div>
                              </div>

                              <div className="tw-form-check tw-border-b-2 tw-border-dashed tw-border-gray-300 tw-flex tw-items-center tw-p-2 ">
                                <input
                                  className="tw-mr-2 tw-p-1"
                                  type="checkbox"
                                  value="propeller-no1-15-18-year"
                                  id="propeller-no1-15-18-year"
                                  name="propeller_shaft_survey_no_1_survey_once_every"
                                  onChange={handleChangeClassMaintenanceSurveys}
                                  checked={classMaintenanceSurveys?.propeller_shaft_survey_no_1_survey_once_every}
                                />

                                <label className="tw-form-check-label tw-inline-block" htmlFor="propeller-no1-15-18-year">
                                  Survey once every 15 or 18* year
                                </label>
                              </div>
                            </div>

                            <div className="tw-items-center tw-flex-wrap">
                              <div className="tw-flex tw-item-center">
                                <div className="tw-form-check tw-p-2 tw-mr-44">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="propeller-no1-upto-1-year"
                                    id="propeller-no1-upto-1-year"
                                    name="propeller_shaft_survey_no_1_extension_up_to_1_year"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.propeller_shaft_survey_no_1_extension_up_to_1_year}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="propeller-no1-upto-1-year">
                                    Extension up to 1 year
                                  </label>
                                </div>
                              </div>

                              <div className="tw-form-check tw-flex tw-items-center tw-p-2 ">
                                <input
                                  className="tw-mr-2 tw-p-1"
                                  type="checkbox"
                                  value="propeller-no1-upto-3-month"
                                  id="propeller-no1-upto-3-month"
                                  name="propeller_shaft_survey_no_1_extension_up_to_3_months"
                                  onChange={handleChangeClassMaintenanceSurveys}
                                  checked={classMaintenanceSurveys?.propeller_shaft_survey_no_1_extension_up_to_3_months}
                                />

                                <label className="tw-form-check-label tw-inline-block" htmlFor="propeller-no1-upto-3-month">
                                  Extension up to 3 months
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tw-grid tw-grid-cols-6 tw-flex">
                          <div className="tw-form-check tw-p-2 tw-border-r-2 tw-boder-b-2 tw-border-dashed tw-border-gray-300">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="propeller-no2"
                              id="propeller-no2"
                              name="propeller_shaft_survey_no_2"
                              onChange={handleChangeClassMaintenanceSurveys}
                              checked={classMaintenanceSurveys?.propeller_shaft_survey_no_2}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="propeller-no2">
                              No.2
                            </label>
                          </div>

                          <div className="tw-col-start-2 tw-col-end-7 ">
                            <div className="tw-items-center">
                              <div className="tw-flex tw-item-center tw-flex-wrap">
                                <div className="tw-form-check tw-p-2 tw-mr-44">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="propeller-no2-ordinary"
                                    id="propeller-no2-ordinary"
                                    name="propeller_shaft_survey_no_2_occasional_survey"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.propeller_shaft_survey_no_2_occasional_survey}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="propeller-no2-ordinary">
                                    Ordinary Survey
                                  </label>
                                </div>

                                <div className="tw-form-check tw-p-2">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="propeller-no2-partial"
                                    id="propeller-no2-partial"
                                    name="propeller_shaft_survey_no_2_partial_survey"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.propeller_shaft_survey_no_2_partial_survey}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="propeller-no2-partial">
                                    Partial Survey
                                  </label>
                                </div>
                              </div>

                              <div className="tw-form-check tw-border-b-2 tw-border-dashed tw-border-gray-300 tw-flex tw-items-center tw-p-2 ">
                                <input
                                  className="tw-mr-2 tw-p-1"
                                  type="checkbox"
                                  value="propeller-no2-15-18-year"
                                  id="propeller-no2-15-18-year"
                                  name="propeller_shaft_survey_no_2_survey_once_every"
                                  onChange={handleChangeClassMaintenanceSurveys}
                                  checked={classMaintenanceSurveys?.propeller_shaft_survey_no_2_survey_once_every}
                                />

                                <label className="tw-form-check-label tw-inline-block" htmlFor="propeller-no2-15-18-year">
                                  Survey once every 15 or 18* year
                                </label>
                              </div>
                            </div>

                            <div className="tw-items-center tw-flex-wrap">
                              <div className="tw-flex tw-item-center">
                                <div className="tw-form-check tw-p-2 tw-mr-44">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="propeller-no2-upto-1-year"
                                    id="propeller-no2-upto-1-year"
                                    name="propeller_shaft_survey_no_2_extension_up_to_1_year"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.propeller_shaft_survey_no_2_extension_up_to_1_year}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="propeller-no2-upto-1-year">
                                    Extension up to 1 year
                                  </label>
                                </div>
                              </div>

                              <div className="tw-form-check tw-flex tw-items-center tw-p-2 ">
                                <input
                                  className="tw-mr-2 tw-p-1"
                                  type="checkbox"
                                  value="propeller-no2-upto-3-month"
                                  id="propeller-no2-upto-3-month"
                                  name="propeller_shaft_survey_no_2_extension_up_to_3_months"
                                  onChange={handleChangeClassMaintenanceSurveys}
                                  checked={classMaintenanceSurveys?.propeller_shaft_survey_no_2_extension_up_to_3_months}
                                />

                                <label className="tw-form-check-label tw-inline-block" htmlFor="propeller-no2-upto-3-month">
                                  Extension up to 3 months
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tw-grid tw-grid-cols-6 tw-flex tw-border-b-2 tw-border-t-2 tw-border-dashed tw-border-gray-300 tw-py-2">
                          <label className="tw-text-gray-400 tw-text-sm">* htmlFor non-Japanese flagged inland waterway ships</label>
                        </div>
                      </div>
                    </div>

                    <div className="tw-bg-slate-200 tw-grid tw-grid-cols-3 bg-white">
                      <div className=" tw-border-r-2 tw-border-secondary tw-p-2  ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <label className="tw-form-check-label tw-inline-block">Ships with the notation APSS・O or APSS・W</label>
                        </div>
                      </div>

                      <div className=" tw-col-span-2">
                        <div className="tw-grid tw-grid-cols-6 tw-flex tw-border-b-2 tw-border-dashed tw-border-gray-300 ">
                          <div className="tw-form-check tw-border-r-2 tw-border-dashed tw-border-gray-300 tw-p-2">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="ship-no1"
                              id="ship-no1"
                              name="ships_with_the_notation_no_1"
                              onChange={handleChangeClassMaintenanceSurveys}
                              checked={classMaintenanceSurveys?.ships_with_the_notation_no_1}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no1">
                              No.1
                            </label>
                          </div>

                          <div className="tw-col-start-2 tw-col-end-7 ">
                            <div className="tw-items-center">
                              <div className="tw-flex tw-item-center tw-justify-between tw-flex-wrap">
                                <div className="tw-form-check tw-p-2 tw-mr-44">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="ship-no1-method1"
                                    id="ship-no1-method1"
                                    name="ships_with_the_notation_no_1_method_1"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.ships_with_the_notation_no_1_method_1}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no1-method1">
                                    Method 1
                                  </label>
                                </div>

                                <div className="tw-form-check tw-p-2">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="ship-no1-method2"
                                    id="ship-no1-method2"
                                    name="ships_with_the_notation_no_1_method_2"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.ships_with_the_notation_no_1_method_2}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no1-method2">
                                    Method 2
                                  </label>
                                </div>

                                <div className="tw-form-check tw-p-2">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="ship-no1-method3"
                                    id="ship-no1-method3"
                                    name="ships_with_the_notation_no_1_method_3"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.ships_with_the_notation_no_1_method_3}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no1-method3">
                                    Method 3
                                  </label>
                                </div>
                              </div>

                              <div className="tw-form-check tw-border-b-2 tw-border-dashed tw-border-gray-300 tw-flex tw-items-center tw-p-2 ">
                                <input
                                  className="tw-mr-2 tw-p-1"
                                  type="checkbox"
                                  value="ship-no1-15-year"
                                  id="ship-no1-15-year"
                                  name="ships_with_the_notation_no_1_survey_once_every_15_years"
                                  onChange={handleChangeClassMaintenanceSurveys}
                                  checked={classMaintenanceSurveys?.ships_with_the_notation_no_1_survey_once_every_15_years}
                                />

                                <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no1-15-year">
                                  Survey once every 15 years
                                </label>
                              </div>
                            </div>

                            <div className="tw-items-center tw-flex-wrap">
                              <div className="tw-flex tw-item-center">
                                <div className="tw-form-check tw-p-2 tw-mr-44">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="ship-no1-upto-2-5-year"
                                    id="ship-no1-upto-2-5-year"
                                    name="ships_with_the_notation_no_1_extension_up_to_2_5_year"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.ships_with_the_notation_no_1_extension_up_to_2_5_year}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no1-upto-2-5-year">
                                    Extension up to 2.5 year
                                  </label>
                                </div>
                              </div>

                              <div className="tw-form-check tw-flex tw-items-center tw-p-2 ">
                                <input
                                  className="tw-mr-2 tw-p-1"
                                  type="checkbox"
                                  value="ship-no1-upto-1-year"
                                  id="ship-no1-upto-1-year"
                                  name="ships_with_the_notation_no_1_extension_up_to_1_year"
                                  onChange={handleChangeClassMaintenanceSurveys}
                                  checked={classMaintenanceSurveys?.ships_with_the_notation_no_1_extension_up_to_1_year}
                                />

                                <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no1-upto-1-year">
                                  Extension up to 1 year
                                </label>
                              </div>

                              <div className="tw-form-check tw-flex tw-items-center tw-p-2 ">
                                <input
                                  className="tw-mr-2 tw-p-1"
                                  type="checkbox"
                                  value="ship-no1-upto-3-months"
                                  id="ship-no1-upto-3-months"
                                  name="ships_with_the_notation_no_1_extension_up_to_3_months"
                                  onChange={handleChangeClassMaintenanceSurveys}
                                  checked={classMaintenanceSurveys?.ships_with_the_notation_no_1_extension_up_to_3_months}
                                />

                                <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no1-upto-3-months">
                                  Extension up to 3 months
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tw-grid tw-grid-cols-6 tw-flex">
                          <div className="tw-form-check tw-p-2 tw-border-r-2 tw-boder-b-2 tw-border-dashed tw-border-gray-300">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="ship-no2"
                              id="ship-no2"
                              name="ships_with_the_notation_no_2"
                              onChange={handleChangeClassMaintenanceSurveys}
                              checked={classMaintenanceSurveys?.ships_with_the_notation_no_2}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no2">
                              No.2
                            </label>
                          </div>

                          <div className="tw-col-start-2 tw-col-end-7 ">
                            <div className="tw-items-center">
                              <div className="tw-flex tw-item-center tw-justify-between tw-flex-wrap">
                                <div className="tw-form-check tw-p-2 tw-mr-44">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="ship-no2-method1"
                                    id="ship-no2-method1"
                                    name="ships_with_the_notation_no_2_method_1"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.ships_with_the_notation_no_2_method_1}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no2-method1">
                                    Method 1
                                  </label>
                                </div>

                                <div className="tw-form-check tw-p-2">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="ship-no2-method2"
                                    id="ship-no2-method2"
                                    name="ships_with_the_notation_no_2_method_2"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.ships_with_the_notation_no_2_method_2}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no2-method2">
                                    Method 2
                                  </label>
                                </div>

                                <div className="tw-form-check tw-p-2">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="ship-no2-method3"
                                    id="ship-no2-method3"
                                    name="ships_with_the_notation_no_2_method_3"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.ships_with_the_notation_no_2_method_3}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no2-method3">
                                    Method 3
                                  </label>
                                </div>
                              </div>

                              <div className="tw-form-check tw-border-b-2 tw-border-dashed tw-border-gray-300 tw-flex tw-items-center tw-p-2 ">
                                <input
                                  className="tw-mr-2 tw-p-1"
                                  type="checkbox"
                                  value="ship-no2-15-year"
                                  id="ship-no2-15-year"
                                  name="ships_with_the_notation_no_2_survey_once_every_15_years"
                                  onChange={handleChangeClassMaintenanceSurveys}
                                  checked={classMaintenanceSurveys?.ships_with_the_notation_no_2_survey_once_every_15_years}
                                />

                                <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no2-15-year">
                                  Survey once every 15 years
                                </label>
                              </div>
                            </div>

                            <div className="tw-items-center tw-flex-wrap">
                              <div className="tw-flex tw-item-center">
                                <div className="tw-form-check tw-p-2 tw-mr-44">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="ship-no2-upto-2-5-year"
                                    id="ship-no2-upto-2-5-year"
                                    name="ships_with_the_notation_no_2_extension_up_to_2_5_year"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.ships_with_the_notation_no_2_extension_up_to_2_5_year}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no2-upto-2-5-year">
                                    Extension up to 2.5 years
                                  </label>
                                </div>
                              </div>

                              <div className="tw-form-check tw-flex tw-items-center tw-p-2 ">
                                <input
                                  className="tw-mr-2 tw-p-1"
                                  type="checkbox"
                                  value="ship-no2-upto-1-year"
                                  id="ship-no2-upto-1-year"
                                  name="ships_with_the_notation_no_2_extension_up_to_1_year"
                                  onChange={handleChangeClassMaintenanceSurveys}
                                  checked={classMaintenanceSurveys?.ships_with_the_notation_no_2_extension_up_to_1_year}
                                />

                                <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no2-upto-1-year">
                                  Extension up to 1 year
                                </label>
                              </div>
                            </div>

                            <div className="tw-form-check tw-flex tw-items-center tw-p-2 ">
                              <input
                                className="tw-mr-2 tw-p-1"
                                type="checkbox"
                                value="ship-no2-upto-3-months"
                                id="ship-no2-upto-3-months"
                                name="ships_with_the_notation_no_2_extension_up_to_3_months"
                                onChange={handleChangeClassMaintenanceSurveys}
                                checked={classMaintenanceSurveys?.ships_with_the_notation_no_2_extension_up_to_3_months}
                              />

                              <label className="tw-form-check-label tw-inline-block" htmlFor="ship-no2-upto-3-months">
                                Extension up to 3 months
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={'tw-bg-lightdary tw-grid tw-grid-cols-3'}>
                      <div className=" tw-border-r-2 tw-border-secondary tw-p-2 ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="boiler-survey"
                            id="boiler-survey"
                            name="boiler_survey"
                            onChange={handleChangeClassMaintenanceSurveys}
                            checked={classMaintenanceSurveys?.boiler_survey}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="boiler-survey">
                            Boiler Survey (BS)
                          </label>
                        </div>
                      </div>

                      <div className=" tw-col-span-2">
                        <div className="tw-grid tw-grid-cols-6">
                          <div className="tw-form-check tw-flex tw-items-center tw-border-r-2 tw-border-dashed tw-border-gray-300 tw-p-2">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="boiler-no1"
                              id="boiler-no1"
                              name="boiler_survey_no_1"
                              onChange={handleChangeClassMaintenanceSurveys}
                              checked={classMaintenanceSurveys?.boiler_survey_no_1}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="boiler-no1">
                              No.1
                            </label>
                          </div>

                          <div className="tw-col-start-2 tw-col-end-3 tw-form-check tw-border-r-2 tw-border-dashed tw-border-gray-300 tw-flex tw-items-center tw-p-2 ">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="boiler-no2"
                              id="boiler-no2"
                              name="boiler_survey_no_2"
                              onChange={handleChangeClassMaintenanceSurveys}
                              checked={classMaintenanceSurveys?.boiler_survey_no_2}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="boiler-no2">
                              No.2
                            </label>
                          </div>

                          <div className="tw-col-start-3 tw-col-end-4 tw-form-check tw-flex tw-items-center tw-p-2 ">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="boiler-no3"
                              id="boiler-no3"
                              name="boiler_survey_no_3"
                              onChange={handleChangeClassMaintenanceSurveys}
                              checked={classMaintenanceSurveys?.boiler_survey_no_3}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="boiler-no3">
                              No.3
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tw-bg-slate-200 tw-grid tw-grid-cols-3 bg-white">
                      <div className=" tw-border-r-2 tw-border-secondary tw-p-2  ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="planned"
                            id="planned"
                            name="planned_machinery_surveys"
                            onChange={handleChangeClassMaintenanceSurveys}
                            checked={classMaintenanceSurveys?.planned_machinery_surveys}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="planned">
                            Planned machinery surveys
                          </label>
                        </div>
                      </div>

                      <div className=" tw-col-span-2 tw-w-full">
                        <div className="tw-max-w-full tw-flex tw-items-center tw-border-b-2 tw-border-dashed tw-border-gray-300 tw-form-check tw-p-2">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="planned-CMS-PMS-CBM"
                            id="planned-CMS-PMS-CBM"
                            name="continuous_machinery_survey"
                            onChange={handleChangeClassMaintenanceSurveys}
                            checked={classMaintenanceSurveys?.continuous_machinery_survey}
                          />

                          <label className="tw-whitespace-normal tw-form-check-label" htmlFor="planned-CMS-PMS-CBM">
                            Continuous Machinery Survey (CMS)* / Planned Machinery Maintenance Scheme (PMS) / Condition Based Maintenance Scheme (CBM)
                          </label>
                        </div>

                        <div className="tw-grid tw-grid-cols-3 tw-flex">
                          <div className="tw-form-check tw-p-2 tw-border-r-2 tw-boder-b-2 tw-border-dashed tw-border-gray-300">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="planned-periodical"
                              id="planned-periodical"
                              name="periodical_surveys"
                              onChange={handleChangeClassMaintenanceSurveys}
                              checked={classMaintenanceSurveys?.periodical_surveys}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="planned-periodical">
                              Periodical Surveys
                            </label>
                          </div>

                          <div className="tw-col-start-2 tw-col-end-4 ">
                            <div className="tw-items-center">
                              <div className="tw-flex tw-item-center tw-flex-wrap">
                                <div className="tw-form-check tw-p-2 tw-mr-44">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="planned-commence"
                                    id="planned-commence"
                                    name="planned_machinery_surveys_commence"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.planned_machinery_surveys_commence}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="planned-commence">
                                    Commence
                                  </label>
                                </div>

                                <div className="tw-form-check tw-p-2">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="checkbox"
                                    value="planned-incomplete"
                                    id="planned-incomplete"
                                    name="planned_machinery_surveys_incomplete"
                                    onChange={handleChangeClassMaintenanceSurveys}
                                    checked={classMaintenanceSurveys?.planned_machinery_surveys_incomplete}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="planned-incomplete">
                                    Incomplete
                                  </label>
                                </div>
                              </div>

                              <div className="tw-form-check tw-flex tw-items-center tw-p-2 ">
                                <input
                                  className="tw-mr-2 tw-p-1"
                                  type="checkbox"
                                  value="planned-complete"
                                  id="planned-complete"
                                  name="planned_machinery_surveys_complete"
                                  onChange={handleChangeClassMaintenanceSurveys}
                                  checked={classMaintenanceSurveys?.planned_machinery_surveys_complete}
                                />

                                <label className="tw-form-check-label tw-inline-block" htmlFor="planned-complete">
                                  Complete
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tw-flex tw-border-t-2 tw-border-dashed tw-border-gray-300 tw-py-2">
                          <label className="tw-text-gray-400 tw-whitespace-normal tw-text-sm">
                            * Please confirm that all machinery and equipment whose overhaul inspections have been carried out are included in the inspection report.
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="tw-bg-lightdary tw-grid tw-grid-cols-3">
                      <div className=" tw-border-r-2 tw-border-secondary tw-p-2  ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="continuous-hull"
                            id="continuous-hull"
                            name="continuous_hull_survey"
                            onChange={handleChangeClassMaintenanceSurveys}
                            checked={classMaintenanceSurveys?.continuous_hull_survey}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="continuous-hull">
                            Continuous Hull Survey (CHS)
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="tw-bg-slate-200 tw-grid tw-grid-cols-3 bg-white">
                      <div className=" tw-border-r-2 tw-border-secondary tw-p-2  ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="occasional"
                            id="occasional"
                            name="occasional_survey"
                            onChange={handleChangeClassMaintenanceSurveys}
                            checked={classMaintenanceSurveys?.occasional_survey}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="occasional">
                            Occasional Survey (OS)
                          </label>
                        </div>
                      </div>

                      <div className=" tw-col-span-2 tw-w-full">
                        <div className="tw-max-w-full tw-flex tw-items-center tw-form-check tw-p-2">
                          <label className="tw-pr-5">Contents</label>

                          <input
                            className="tw-form-control tw-block tw-w-1/2 tw-px-3 tw-py-1.5 tw-text-base tw-font-normal tw-text-gray-700 tw-bg-white bg-clip-padding tw-border border-solid border-gray-300 tw-rounded tw-m-0 tw-focus:text-gray-700 tw-focus:bg-white tw-focus:outline-none"
                            {...occasionalContents}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tw-mt-4 tw-flex-1 tw-overflow-y-auto tw-whitespace-nowrap rounded-lg" id="installation">
                  <div
                    className="th accordion-button bg-gray-300 tw-cursor-pointer tw-text-black tw-px-2 tw-py-1 tw-text-black tw-justify-around rounded-t-lg"
                    data-bs-toggle="collapse"
                    data-bs-target="#installation-surveys"
                    aria-expanded="true"
                    aria-controls="installation-surveys"
                  >
                    <label className="tw-pr-5 tw-font-normal tw-text-lg">1 - 2</label>

                    <label className="tw-text-bold tw-text-lg">Installation surveys</label>
                  </div>

                  <div className="tw-table tw-w-full accordion-collapse collapse show rounded-b-lg" id="installation-surveys" aria-labelledby="dropdownButton">
                    <div className={'tw-bg-lightdary tw-grid tw-grid-cols-2'}>
                      <div className=" tw-border-r-2 tw-border-secondary tw-p-2 ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="1-2-CHG"
                            id="1-2-CHG"
                            name="cargoHandlingAppliances"
                            onChange={handleChangeInstallationSurveys}
                            checked={installationSurveys?.cargoHandlingAppliances}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-CHG">
                            Cargo handling appliances (CHG)
                          </label>
                        </div>
                      </div>

                      <div className=" tw-col-span-1">
                        <div className="tw-grid tw-grid-cols-2">
                          <div className="tw-form-check tw-flex tw-items-center tw-p-2">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="1-2-CHG-annual"
                              id="1-2-CHG-annual"
                              name="annualThorough"
                              onChange={handleChangeInstallationSurveys}
                              checked={installationSurveys?.annualThorough}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-CHG-annual">
                              Annual thorough
                            </label>
                          </div>

                          <div className="tw-col-span-1 tw-form-check tw-flex tw-items-center tw-p-2 ">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="1-2-CHG-load"
                              id="1-2-CHG-load"
                              name="loadTest"
                              onChange={handleChangeInstallationSurveys}
                              checked={installationSurveys?.loadTest}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-CHG-load">
                              Load test
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={'tw-bg-slate-200 tw-grid tw-grid-cols-2 bg-white'}>
                      <div className=" tw-border-r-2 tw-border-secondary tw-p-2 ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="1-2-M0"
                            id="1-2-M0"
                            name="automatic_and_remote_control_systems"
                            onChange={handleChangeInstallationSurveys}
                            checked={installationSurveys?.automatic_and_remote_control_systems}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-M0">
                            Automatic and remote control systems(M0)
                          </label>
                        </div>
                      </div>

                      <div className=" tw-col-span-1">
                        <div
                          className="tw-grid tw-grid-cols-3 tw-flex"
                          onChange={(e) => {
                            // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
                            setAutomaticAndRemoteControlSystemsValue(e.target.value);
                          }}
                        >
                          <div className="w-col-start-1 tw-items-center">
                            <div className="tw-form-check tw-p-2">
                              <input
                                className="tw-mr-2 tw-p-1"
                                type="radio"
                                id="1-2-M0-MC"
                                value="MC"
                                checked={automaticAndRemoteControlSystemsValue === 'MC'}
                              />
                              <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-M0-MC">
                                MC
                              </label>
                            </div>
                            <div className="tw-form-check tw-p-2">
                              <input
                                className="tw-mr-2 tw-p-1"
                                type="radio"
                                id="1-2-M0-MC"
                                value="M0"
                                checked={automaticAndRemoteControlSystemsValue === 'M0'}
                              />
                              <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-M0">
                                M0
                              </label>
                            </div>
                          </div>

                          <div className="tw-col-start-2 tw-col-end-4">
                            <div className="tw-items-center">
                              <div className="tw-flex tw-item-center tw-flex-wrap">
                                <div className="tw-form-check tw-p-2 tw-mr-44">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="radio"
                                    id="1-2-M0-A"
                                    value="M0(A)"
                                    checked={automaticAndRemoteControlSystemsValue === 'M0(A)'}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-M0-A">
                                    M0(A)
                                  </label>
                                </div>

                                <div className="tw-form-check tw-p-2">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="radio"
                                    id="1-2-M0-B"
                                    value="M0(B)"
                                    checked={automaticAndRemoteControlSystemsValue === 'M0(B)'}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-M0-B">
                                    M0(B)
                                  </label>
                                </div>
                              </div>

                              <div className="tw-flex tw-item-center tw-flex-wrap">
                                <div className="tw-form-check tw-p-2 tw-mr-44">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="radio"
                                    id="1-2-M0-C"
                                    value="M0(C)"
                                    checked={automaticAndRemoteControlSystemsValue === 'M0(C)'}
                                  />
                                  <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-M0-C">
                                    M0(C)
                                  </label>
                                </div>

                                <div className="tw-form-check tw-p-2">
                                  <input
                                    className="tw-mr-2 tw-p-1"
                                    type="radio"
                                    id="1-2-M0-D"
                                    value="M0(D)"
                                    checked={automaticAndRemoteControlSystemsValue === 'M0(D)'}
                                  />

                                  <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-M0-D">
                                    M0(D)
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={'tw-bg-lightdary tw-grid tw-grid-cols-2'}>
                      <div className=" tw-border-r-2 tw-border-secondary tw-p-2 ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="1-2-RMC"
                            id="1-2-RMC"
                            name="cargo_refrigerating_installations"
                            onChange={handleChangeInstallationSurveys}
                            checked={installationSurveys?.cargo_refrigerating_installations}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-RMC">
                            Cargo refrigerating installations (RMC))
                          </label>
                        </div>
                      </div>

                      <div className=" tw-col-span-1">
                        <div className="tw-form-check tw-flex tw-items-center tw-p-2">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="1-2-CRS"
                            id="1-2-CRS"
                            name="continuous"
                            onChange={handleChangeInstallationSurveys}
                            checked={installationSurveys?.continuous}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-CRS">
                            Continuous (CRS)
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className={'tw-bg-slate-200 tw-grid tw-grid-cols-2 bg-white'}>
                      <div className="tw-p-2 ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="1-2-CAA"
                            id="1-2-CAA"
                            name="crew_accommodation_arrangement"
                            onChange={handleChangeInstallationSurveys}
                            checked={installationSurveys?.crew_accommodation_arrangement}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-CAA">
                            Crew accommodation arrangement (CAA)
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className={'tw-bg-lightdary tw-grid tw-grid-cols-2'}>
                      <div className="tw-p-2 ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="1-2-BRS"
                            id="1-2-BRS"
                            name="navigation_bridge_system"
                            onChange={handleChangeInstallationSurveys}
                            checked={installationSurveys?.navigation_bridge_system}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-BRS">
                            Navigation bridge system (BRS)
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className={'tw-bg-slate-200 tw-grid tw-grid-cols-2 bg-white'}>
                      <div className="tw-p-2 ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="1-2-PMM"
                            id="1-2-PMM"
                            name="preventive_machinery_maintenance_systems"
                            onChange={handleChangeInstallationSurveys}
                            checked={installationSurveys?.preventive_machinery_maintenance_systems}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-PMM">
                            Preventive machinery maintenance systems (PMM)
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className={'tw-bg-lightdary tw-grid tw-grid-cols-2'}>
                      <div className="tw-p-2 ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="1-2-IFC"
                            id="1-2-IFC"
                            name="integrated_fire_control_systems"
                            onChange={handleChangeInstallationSurveys}
                            checked={installationSurveys?.integrated_fire_control_systems}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-IFC">
                            Integrated fire control systems (IFC)
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className={'tw-bg-slate-200 tw-grid tw-grid-cols-2 bg-white'}>
                      <div className="tw-p-2 ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="1-2-HMS"
                            id="1-2-HMS"
                            name="hull_monitoring_system"
                            onChange={handleChangeInstallationSurveys}
                            checked={installationSurveys?.hull_monitoring_system}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-HMS">
                            Hull monitoring system (HMS)
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className={'tw-bg-lightdary tw-grid tw-grid-cols-2'}>
                      <div className="tw-p-2 ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="1-2-DVS"
                            id="1-2-DVS"
                            name="diving_systems"
                            onChange={handleChangeInstallationSurveys}
                            checked={installationSurveys?.diving_systems}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="1-2-DVS">
                            Diving systems (DVS)
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tw-mt-4 tw-flex-1 tw-overflow-y-auto tw-whitespace-nowrap rounded-lg" id="statutory">
                  <div
                    className="th accordion-button bg-gray-300 tw-cursor-pointer tw-text-black tw-px-2 tw-py-1 tw-text-black tw-justify-around rounded-t-lg"
                    data-bs-toggle="collapse"
                    data-bs-target="#statutory-surveys"
                    aria-expanded="true"
                    aria-controls="statutory-surveys"
                  >
                    <label className="tw-pr-5 tw-font-normal tw-text-lg">1 - 3</label>

                    <label className="tw-text-bold tw-text-lg">Statutory surveys</label>
                  </div>

                  <div className="tw-table tw-w-full accordion-collapse collapse show rounded-b-lg" id="statutory-surveys" aria-labelledby="dropdownButton">
                    {statutorySurveyItems.map((item, i) => (
                      <StatutorySurveyItem
                        {...item}
                        leftItemValue={statutorySurveys?.[item.itemKey]}
                        onChangeLeftItemValue={handleChangeStatutorySurveys}
                        bgColor={i % 2 === 0 ? "bg-white" : "tw-bg-lightdary"}
                      />
                    ))}
                  </div>
                  <div className="tw-text-gray-400 tw-whitespace-normal tw-text-sm tw-mt-2 tw-ml-2">
                    Remarks: The above survey items apply htmlFor HSSC certificates. Please tick the equivalent survey items htmlFor Non-HSSC certificates
                  </div>
                </div>

                <div className="tw-mt-4 tw-flex-1 tw-overflow-y-auto tw-whitespace-nowrap rounded-lg" id="other">
                  <div
                    className="th accordion-button bg-gray-300 tw-cursor-pointer tw-text-black tw-px-2 tw-py-1 tw-text-black tw-justify-around rounded-t-lg"
                    data-bs-toggle="collapse"
                    data-bs-target="#other-survey"
                    aria-expanded="true"
                    aria-controls="other-survey"
                  >
                    <label className="tw-pr-5 tw-font-normal tw-text-lg">1 - 4</label>

                    <label className="tw-text-bold tw-text-lg">Other Survey(s)</label>
                  </div>

                  <div className="tw-table tw-w-full accordion-collapse collapse show rounded-b-lg" id="other-survey" aria-labelledby="dropdownButton">
                    <div className={'tw-bg-lightdary tw-grid tw-grid-cols-2'}>
                      <div className=" tw-border-r-2 tw-border-secondary tw-p-2 ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="1-4-IHM"
                            id="1-4-IHM"
                            name="inventory_of_hazardous_material"
                            onChange={handleChangeOtherSurveys}
                            checked={otherSurveys?.inventory_of_hazardous_material}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="1-4-IHM">
                            Inventory of hazardous material (IHM)
                          </label>
                        </div>
                      </div>

                      <div className=" tw-col-span-1">
                        <div className="tw-grid tw-grid-cols-2">
                          <div className="tw-form-check tw-flex tw-items-center tw-p-2">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="1-4-IHM-initial-renewal"
                              id="1-4-IHM-initial-renewal"
                              name="initial_renewal"
                              onChange={handleChangeOtherSurveys}
                              checked={otherSurveys?.initial_renewal}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="1-4-IHM-initial-renewal">
                              *Initial / Renewal
                            </label>
                          </div>

                          <div className="tw-col-span-1 tw-form-check tw-flex tw-items-center tw-p-2 ">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="1-4-IHM-additonal"
                              id="1-4-IHM-additional"
                              name="additional"
                              onChange={handleChangeOtherSurveys}
                              checked={otherSurveys?.additional}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="1-4-IHM-additional">
                              Additional
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tw-bg-slate-200 tw-grid tw-grid-cols-2 bg-white">
                      <div className=" tw-border-r-2 tw-border-secondary tw-p-2  ">
                        <div className="tw-form-check tw-flex tw-items-center">
                          <input
                            className="tw-mr-2 tw-p-1"
                            type="checkbox"
                            value="1-4-other"
                            id="1-4-other"
                            name="other_survey"
                            onChange={handleChangeOtherSurveys}
                            checked={otherSurveys?.other_survey}
                          />

                          <label className="tw-form-check-label tw-inline-block" htmlFor="1-4-other">
                            Other Survey(s)
                          </label>
                        </div>
                      </div>

                      <div className=" tw-col-span-1 tw-w-full">
                        <div className="tw-max-w-full tw-flex tw-items-center tw-form-check tw-p-2">
                          <label className="tw-pr-5">Contents</label>

                          <input
                            className="tw-form-control tw-block tw-w-1/2 tw-px-3 tw-py-1.5 tw-text-base tw-font-normal tw-text-gray-700 tw-bg-white bg-clip-padding tw-border border-solid border-gray-300 tw-rounded tw-m-0 tw-focus:text-gray-700 tw-focus:bg-white tw-focus:outline-none"
                            {...otherSurveyContents}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tw-text-gray-400 tw-whitespace-normal tw-text-sm tw-mt-2 tw-ml-2">
                  Remarks: Periodical Survey/Audit htmlFor Classification Notation(s) of Guidelines (EA, NVC, MVA, HVS, BWTS etc.) are carried out at the occasion of Annual
                  (AS)/Intermediate（IS）/Special Survey(SS) htmlFor Classification. If you do not intend to maintain these Notation(s), please inform to our Survey Site in
                  advance.
                </div>

                <div className="pt-3 my-5">
                  <h5 className="tw-w-full tw-text-lg">2 Certificate(s) to be issued</h5>

                  <div className="tw-flex-1 tw-overflow-y-auto tw-whitespace-nowrap rounded-lg">
                    <Element name="Element2">
                      {listCertificate.map((field, i) => {
                        return (
                          <CustomRowCheckBox
                            key={'field_' + i}
                            indexCertificate={`${i + 1}`}
                            keyLeft={field.keyLeft}
                            keyRight={field.keyRight}
                            textLeft={field.textLeft}
                            textRight={field.textRight}
                            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                            checkedLeft={certificateIssued ? certificateIssued[field.keyLeft] : false}
                            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                            checkedRight={certificateIssued ? certificateIssued[field.keyRight] : false}
                            fnHandleChange={handleChangeCertificateIssued}
                          />
                        );
                      })}
                    </Element>

                    <div className="tw-form-check tw-flex tw-items-center tw-p-2 tw-bg-lightdary">
                      <input
                        className="tw-mr-2 tw-p-1"
                        type="checkbox"
                        value="2-certificate-SF"
                        id="2-certificate-SF"
                        name="sf_certificate"
                        onChange={handleChangeCertificateIssued}
                        checked={certificateIssued?.sf_certificate}
                      />

                      <label className="tw-form-check-label tw-inline-block" htmlFor="2-certificate-SF">
                        SF Certificate (The combination htmlForms of SC, SE and SR)
                      </label>
                    </div>

                    <div className="tw-form-check tw-flex tw-items-center tw-p-2 bg-white">
                      <input
                        className="tw-mr-2 tw-p-1"
                        type="checkbox"
                        value="2-certificate-IHM"
                        id="2-certificate-IHM"
                        name="ihm_certificate"
                        onChange={handleChangeCertificateIssued}
                        checked={certificateIssued?.ihm_certificate}
                      />

                      <label className="tw-form-check-label tw-flex tw-items-center" htmlFor="2-certificate-IHM">
                        <label> IHM Certificate htmlFor ( </label>

                        <label className="tw-flex">
                          <div className="tw-form-check tw-flex tw-items-center tw-p-2">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="2-certificate-Hong-Kong"
                              id="2-certificate-Hong-Kong"
                              name="hong_kong_convention"
                              onChange={handleChangeCertificateIssued}
                              checked={certificateIssued?.hong_kong_convention}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="2-certificate-Hong-Kong">
                              Hong Kong Convention
                            </label>
                          </div>

                          <div className="tw-form-check tw-flex tw-items-center tw-p-2">
                            <input
                              className="tw-mr-2 tw-p-1"
                              type="checkbox"
                              value="2-certificate-EU"
                              id="2-certificate-EU"
                              name="eu_regulation"
                              onChange={handleChangeCertificateIssued}
                              checked={certificateIssued?.eu_regulation}
                            />

                            <label className="tw-form-check-label tw-inline-block" htmlFor="2-certificate-EU">
                              EU Regulation
                            </label>
                          </div>
                        </label>

                        <label>)</label>
                      </label>
                    </div>

                    <div className="tw-form-check tw-flex tw-items-center tw-p-2 tw-bg-lightdary">
                      <label className="tw-pr-5">Exemption Cert.</label>

                      <input
                        className="tw-form-control tw-block tw-w-1/4 tw-px-3 tw-py-1.5 tw-text-base tw-font-normal tw-text-gray-700 tw-bg-white bg-clip-padding tw-border border-solid border-gray-300 tw-rounded tw-m-0 tw-focus:text-gray-700 tw-focus:bg-white tw-focus:outline-none"
                        {...exemptionCertificate}
                      />
                    </div>

                    <div className="tw-form-check tw-flex tw-items-center tw-p-2 bg-white">
                      <input className="tw-mr-2 tw-p-1" type="checkbox" value="2-certificate-Other" id="2-certificate-Other" />

                      <label className="tw-pr-5" htmlFor="2-certificate-Other">
                        Other
                      </label>

                      <input
                        className="tw-form-control tw-block tw-w-1/4 tw-px-3 tw-py-1.5 tw-text-base tw-font-normal tw-text-gray-700 tw-bg-white bg-clip-padding tw-border border-solid border-gray-300 tw-rounded tw-m-0 tw-focus:text-gray-700 tw-focus:bg-white tw-focus:outline-none"
                        {...otherCertificate}
                      />
                    </div>
                  </div>

                  <div className="tw-text-gray-400 tw-whitespace-normal tw-text-sm tw-mt-2 tw-ml-2">
                    Remarks: Please submit an “APPLICATION htmlFor ISSUE OF CERTIFICATES (Form 4A)” separately, if LL, SC, SE, SR, SF, GAS and AFS are to be issued to Japanese flag
                    ships. htmlFor Statutory Certificates other than LL, SC, SE, SR, SF, GAS and AFS htmlFor Japanese flag ships, please apply to the Japanese Government.
                  </div>
                </div>

                <div className="pt-3 tw-flex mb-5">
                  <div className="tw-w-full">
                    <h5 className="tw-text-lg">3 Date and Place of Survey</h5>

                    <div className="tw-flex-1 tw-overflow-y-auto tw-whitespace-nowrap rounded-lg">
                      <div className="tw-flex tw-bg-white tw-items-center tw-h-11">
                        <div className="tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between">
                          <div className="tw-flex-1">Place of Survey</div>

                          <div className="hint tw-cursor-help" title="explain ....">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                          </div>
                        </div>

                        <div className="tw-px-4 tw-flex-1">
                          <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-1/2" {...placeOfSurvey} />
                        </div>
                      </div>

                      <div className="tw-flex tw-bg-lightdary tw-items-center tw-h-11 ">
                        <div className="tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between">
                          <div className="tw-flex-1">Date of Survey</div>

                          <div className="hint tw-cursor-help" title="explain ....">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                          </div>
                        </div>

                        <div className="tw-px-4 tw-flex grid grid-cols-3 gap-3  tw-w-1/5">
                          <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-20 " {...dateOfSurvey} />

                          <div className="tw-flex">
                            <div className="px-2 pt-1 border-dotted border-l-2">ETA</div>

                            <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-20" {...dateOfSurveyETA} />
                          </div>

                          <div className="tw-flex">
                            <div className="px-2 pt-1 border-dotted border-l-2">ETD</div>

                            <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-20" {...dateOfSurveyETD} />
                          </div>
                        </div>
                      </div>

                      <div className="tw-flex tw-bg-white tw-items-center tw-h-11">
                        <div className="tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between">
                          <div className="tw-flex-1">Name of local agent</div>

                          <div className="hint tw-cursor-help" title="explain ....">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                          </div>
                        </div>

                        <div className="tw-px-4 tw-flex-1">
                          <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-1/2" {...nameOfLocalAgent} />
                        </div>
                      </div>

                      <div className="tw-flex tw-bg-lightdary tw-items-center tw-h-11">
                        <div className="tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between">
                          <div className="tw-flex-1">Contact person</div>

                          <div className="hint tw-cursor-help" title="explain ....">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                          </div>
                        </div>

                        <div className="tw-px-4 tw-flex-1">
                          <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-1/2" {...contactPerson} />
                        </div>
                      </div>

                      <div className="tw-flex tw-bg-white tw-items-center tw-h-11">
                        <div className="tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between">
                          <div className="tw-flex-1">(Tel)</div>

                          <div className="hint tw-cursor-help" title="explain ....">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                          </div>
                        </div>

                        <div className="tw-px-4 tw-flex-1">
                          <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-1/2" {...contactPersonTel} />
                        </div>
                      </div>

                      <div className="tw-flex tw-bg-lightdary tw-items-center tw-h-11">
                        <div className="tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between">
                          <div className="tw-flex-1">(Fax)</div>

                          <div className="hint tw-cursor-help" title="explain ....">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                          </div>
                        </div>

                        <div className="tw-px-4 tw-flex-1">
                          <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-1/2" {...contactPersonFax} />
                        </div>
                      </div>

                      <div className="tw-flex tw-bg-white tw-items-center tw-h-11">
                        <div className="tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between">
                          <div className="tw-flex-1">(E-mail)</div>

                          <div className="hint tw-cursor-help" title="explain ....">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                          </div>
                        </div>

                        <div className="tw-px-4 tw-flex-1">
                          <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-1/2" {...contactPersonEmail} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-3 mb-5">
                  <h5 className="tw-w-full tw-text-lg">4 Message (if any)</h5>

                  <div className="tw-flex tw-bg-white tw-items-center p-2 rounded-lg">
                    <textarea className="w-full p-2.5 border-solid border-2 rounded-lg" rows={2} {...message} />
                  </div>
                </div>

                <div className="pt-3 tw-flex mb-5">
                  <Element name="BillingContact" className="w-4/5">
                    <h5 className="tw-text-lg">5 Supplementary note</h5>

                    <div className="tw-flex tw-items-center tw-h-11 tw-ml-4">
                      <ul className="list-disc pl-4 ">
                        <li className="font-thin text-xs">This htmlForm is also availabe htmlFor Japanese flag ships.</li>

                        <li className="font-thin text-xs">Please attach a copy of latest SHIP INSPECTION CERTIFICATE in case of Japanese flag ships.</li>
                      </ul>
                    </div>
                  </Element>
                </div>
              </div>
            </div>
          </div>

          <div className={'tw-text-center tw-w-full pb-5 tw-px-44 tw-bg-lightdary'}>
            <button
              onClick={handleSave}
              className="tw-w-full tw-rounded-2xl tw-items-center mx-auto
             tw-justify-center tw-h-11 tw-bg-primary tw-px-3 tw-text-white tw-font-bold"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const CustomRowCheckBox = ({ index, keyLeft, keyRight, textLeft, textRight, checkedLeft, checkedRight, indexCertificate, fnHandleChange }: any) => {
  let rowClass = '';
  let leftClass = '';
  let rightClass = '';

  if (index) {
    if (index % 2 !== 0) {
      rowClass = 'tw-grid tw-grid-cols-2 ';
      leftClass = 'tw-border-r-2 tw-border-secondary tw-p-2 tw-bg-lightdary';
      rightClass = 'tw-col-span-1 tw-bg-lightdary';
    } else {
      rowClass = ' tw-grid tw-grid-cols-2 ';
      leftClass = 'tw-border-r-2 tw-border-secondary tw-p-2 bg-white';
      rightClass = 'tw-col-span-1 bg-white';
    }
  }

  if (indexCertificate) {
    if (indexCertificate % 2 !== 0) {
      rowClass = 'tw-grid tw-grid-cols-2';
      leftClass = 'tw-border-r-2 tw-p-2 tw-bg-lightdary';
      rightClass = 'tw-col-span-1 bg-white';
    } else {
      rowClass = 'tw-grid tw-grid-cols-2';
      leftClass = 'tw-border-r-2 tw-p-2 bg-white';
      rightClass = 'tw-col-span-1 tw-bg-lightdary';
    }
  }

  return (
    <div className={rowClass}>
      <div className={leftClass}>
        <div className="tw-form-check tw-flex tw-items-center">
          <input className="tw-mr-2 tw-p-1" type="checkbox" name={keyLeft} value={keyLeft} id={keyLeft} onChange={fnHandleChange} checked={checkedLeft} />
          <label className="tw-form-check-label tw-inline-block" htmlFor={keyLeft}>
            {textLeft}
          </label>
        </div>
      </div>
      <div className={rightClass}>
        <div className="tw-form-check tw-flex tw-items-center tw-p-2">
          <input className="tw-mr-2 tw-p-1" type="checkbox" name={keyRight} value={keyRight} id={keyRight} onChange={fnHandleChange} checked={checkedRight} />
          <label className="tw-form-check-label tw-inline-block" htmlFor={keyRight}>
            {textRight}
          </label>
        </div>
      </div>
    </div>
  );
};

const CustomRow = ({ index, ship, title, hint, inputProps = {} }: any) => {
  let rowClass = 'tw-flex tw-bg-secondary tw-items-center tw-h-12';
  let leftClass = 'tw-w-4/12 tw-border-r-2 tw-h-full tw-border-white tw-flex tw-items-center tw-px-4 tw-justify-between';
  let titleClass = 'tw-font-bold tw-flex-1';
  const [editing, setEditing] = useState(false);
  const [oldValue, setOldValue] = useState(false);

  if (index) {
    if (index % 2 !== 0) {
      rowClass = `tw-flex tw-bg-white tw-items-center tw-h-11`;
      leftClass = `tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between`;
      titleClass = 'tw-flex-1';
    } else {
      rowClass = `tw-flex tw-bg-lightdary tw-items-center tw-h-11`;
      leftClass = `tw-w-4/12 tw-border-r-2 tw-h-full tw-border-secondary tw-flex tw-items-center tw-px-4 tw-justify-between`;
      titleClass = 'tw-flex-1';
    }
  }

  const contentSaved = () => {
    if (!editing) {
      return (
        <div className="tw-flex tw-justify-between">
          <div>{inputProps.value}</div>
          <div
            className="tw-cursor-pointer"
            onClick={() => {
              setOldValue(inputProps.value);
              setEditing(true);
            }}
          >
            Edit
          </div>
        </div>
      );
    } else {
      return (
        <div className="tw-flex tw-justify-between">
          <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-full tw-mr-4" type="text" {...inputProps} />
          <div
            className="tw-cursor-pointer"
            onClick={() => {
              inputProps.onChange({ target: { value: oldValue } });
              setEditing(false);
            }}
          >
            Cancel
          </div>
        </div>
      );
    }
  };

  return (
    <div className={rowClass}>
      <div className={leftClass}>
        <div className={titleClass}>{title}</div>
        <div className="hint tw-cursor-help" title={hint || 'explain ....'}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
        </div>
      </div>
      <div className="tw-px-4 tw-flex-1">
        {inputProps.onChange &&
          (ship.ship_id ? contentSaved() : <input className="tw-px-2 tw-h-8 tw-border-secondary tw-border tw-rounded tw-outline-none tw-w-1/2" type="text" {...inputProps} />)}
      </div>
    </div>
  );
};

const useFormInput = ({ initialValue, placeholder }: any) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  const set = (value: any) => {
    setValue(value);
  };

  return {
    value,
    placeholder: placeholder || '',
    onChange: handleChange,
    set: set,
  };
};

export default ApplicationForm;
