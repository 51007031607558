import { Dialog, Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListOrganization } from '../../../store/organizationSlice';
import { mainActions } from '../../../store/mainSlice';
import organization from '../../../api/organization';
import useFormInput from '../../../hooks/useFormInput';

function Organizations() {
  const dispatch = useDispatch();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'organization' does not exist on type 'De... Remove this comment to see the full error message
  const { organizationList } = useSelector((state) => state.organization);
  const [isModalOpenAdd, setModalOpenAddOrg] = useState(false);
  const [isModalOpenDel, setModalOpenDelOrg] = useState(false);
  const [isModalOpenEdit, setModalOpenEditOrg] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);

  const organization_name = useFormInput('');
  const nationality = useFormInput('');

  useEffect(() => {
    dispatch(getListOrganization());
  }, [dispatch]);

  const openModalAddOrg = () => {
    organization_name.set('');
    nationality.set('');
    setModalOpenAddOrg(true);
  };

  const openModalDelOrg = (organization: any) => {
    setSelectedOrg(organization);
    setModalOpenDelOrg(true);
  };

  const openModalEditOrg = (organization: any) => {
    setSelectedOrg(organization);
    organization_name.set(organization.organization_name);
    nationality.set(organization.nationality);
    setModalOpenEditOrg(true);
  };

  const validateAddUser = () => {
    if (!organization_name.value) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"OrganizationName is required."'... Remove this comment to see the full error message
      setError('OrganizationName is required.');
      return true;
    }
    if (!nationality.value) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Nationality is required."' is n... Remove this comment to see the full error message
      setError('Nationality is required.');
      return true;
    }
    return false;
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmitAddOrg = () => {
    if (!validateAddUser() && !loading) {
      dispatch(mainActions.setLoading(true));
      organization
        .addOrg({
          organization_name: organization_name.value,
          nationality: nationality.value,
        })
        .then((data) => {
          dispatch(mainActions.setLoading(false));
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
          if (data.meta?.status === 'success') {
            window.location.reload();
          } else {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
            setError(data.meta?.message || 'failed');
          }
        })
        .catch(() => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"failed"' is not assignable to p... Remove this comment to see the full error message
          setError('failed');
        })
        .finally(() => {
          setLoading(false);
          dispatch(mainActions.setLoading(false));
        });
    }
  };

  const onSubmitEditOrg = () => {
    if (!validateAddUser() && !loading) {
      dispatch(mainActions.setLoading(true));
      organization
        .editOrg({
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          organization_id: selectedOrg.organization_id,
          organization_name: organization_name.value,
          nationality: nationality.value,
        })
        .then((data) => {
          dispatch(mainActions.setLoading(false));
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
          if (data.meta?.status === 'success') {
            window.location.reload();
          } else {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
            setError(data.meta?.message || 'failed');
          }
        })
        .catch(() => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"failed"' is not assignable to p... Remove this comment to see the full error message
          setError('failed');
        })
        .finally(() => {
          setLoading(false);
          dispatch(mainActions.setLoading(false));
        });
    }
  };

  const onSubmitDelOrg = () => {
    setLoading(true);
    dispatch(mainActions.setLoading(true));
    organization
      .delOrg({
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        organization_id: selectedOrg.organization_id,
      })
      .then((data) => {
        setModalOpenDelOrg(false);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
        if (data.meta?.status === 'success') {
          window.location.reload();
        } else {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
          setError(data.meta?.message || 'failed');
        }
        dispatch(mainActions.setLoading(false));
      })
      .catch(() => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"failed"' is not assignable to p... Remove this comment to see the full error message
        setError('failed');
      })
      .finally(() => {
        setLoading(false);
        dispatch(mainActions.setLoading(false));
      });
  };

  return (
    // ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
    <div className="tw-w-10/12 tw-bg-white tw-h-screen tw-pt-28 tw-pl-16">
      <h3 className="tw-text-3xl tw-font-bold">Organizations</h3>

      <button
        onClick={() => openModalAddOrg()}
        className="tw-flex tw-items-center tw-justify-center tw-h-11 tw-rounded-2xl tw-border-2
           tw-border-primary tw-px-3 tw-text-primary"
      >
        Add Organizations
      </button>

      <table className="tw-table-auto tw-w-11/12 tw-mt-4">
        <thead className="tw-bg-secondary">
          <th className="tw-p-3 tw-border">Organization ID</th>

          <th className="tw-p-3 tw-border">Organization name</th>

          <th className="tw-p-3 tw-border">Nationality</th>

          <th className="tw-p-3 tw-border  tw-w-44"></th>
        </thead>

        <tbody>
          {(organizationList || []).map((organization: any, i: any) => {
            return (
              // ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
              <tr className={`${i % 2 === 0 ? 'tw-bg-lightdary' : ''}`}>
                <td className="tw-p-3 tw-border">{i + 1}</td>

                <td className="tw-p-3 tw-border">{organization.organization_name}</td>

                <td className="tw-p-3 tw-border">{organization.nationality}</td>

                <td className="tw-p-3 tw-border tw-w-44 tw-flex">
                  <span onClick={() => openModalEditOrg(organization)} className="tw-cursor-pointer" title="Delete user">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                  </span>

                  <span onClick={() => openModalDelOrg(organization)} className="tw-cursor-pointer" title="Delete user">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Transition.Root show={isModalOpenDel} as={React.Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setModalOpenDelOrg}>
          <div className="flex items-center justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}

            <span className="hidden" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle">
                <div className="flex flex-col bg-white py-4 px-8">
                  <React.Fragment>
                    <div className="">
                      <div className="flex flex-col bg-white py-4 px-8">
                        <div className="tw-font-bold tw-text-xl pb-3"></div>

                        <div className="flex flex-row content-center py-4 mb-3">
                          <div className="flex flex-col tw-w-full">
                            <div className="tw-font-bold tw-text-lg pb-2">Delete Organization</div>
                          </div>
                        </div>

                        <div className="flex justify-end">
                          <button
                            onClick={() => onSubmitDelOrg()}
                            className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-9 py-2.5 mb-2 tw-text-white font-bold tw-bg-primary mr-2"
                          >
                            OK
                          </button>

                          <button
                            onClick={() => {
                              setModalOpenDelOrg(false);
                            }}
                            className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-6 py-2.5 mb-2 tw-text-primary font-bold"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={isModalOpenAdd} as={React.Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setModalOpenAddOrg}>
          <div className="flex items-center justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}

            <span className="hidden" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle">
                <div className="flex flex-col bg-white py-4 px-8">
                  <React.Fragment>
                    <div className="">
                      <div className="flex flex-col bg-white py-4 px-8">
                        <div className="tw-text-danger tw-mx-4">{error && <small className="">{error}</small>}</div>

                        <div className="flex flex-row content-center py-4 mb-3">
                          <div className="flex flex-col tw-w-full">
                            <div className="tw-font-bold tw-text-lg pb-2">Add Organization</div>

                            <div>Organization Name</div>

                            <input className="form-control" type="text" name="organization_name" {...organization_name} placeholder="Organization_name" />

                            <div>Nationality</div>

                            <input className="form-control" type="text" name="nationality" {...nationality} placeholder="Nationality" />
                          </div>
                        </div>

                        <div className="flex justify-end">
                          <button
                            onClick={() => onSubmitAddOrg()}
                            className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-9 py-2.5 mb-2 tw-text-white font-bold tw-bg-primary mr-2"
                          >
                            OK
                          </button>

                          <button
                            onClick={() => {
                              setModalOpenAddOrg(false);
                            }}
                            className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-6 py-2.5 mb-2 tw-text-primary font-bold"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={isModalOpenEdit} as={React.Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setModalOpenEditOrg}>
          <div className="flex items-center justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle">
                <div className="flex flex-col bg-white py-4 px-8">
                  <React.Fragment>
                    <div className="">
                      <div className="flex flex-col bg-white py-4 px-8">
                        <div className="tw-text-danger tw-mx-4">{error && <small className="">{error}</small>}</div>
                        <div className="flex flex-row content-center py-4 mb-3">
                          <div className="flex flex-col tw-w-full">
                            <div className="tw-font-bold tw-text-lg pb-2">Edit Organization</div>
                            <div>Organization Name</div>
                            <input className="form-control" type="text" name="organization_name" {...organization_name} placeholder="Organization_name" />
                            <div>Nationality</div>
                            <input className="form-control" type="text" name="nationality" {...nationality} placeholder="Nationality" />
                          </div>
                        </div>
                        <div className="flex justify-end">
                          <button
                            onClick={() => onSubmitEditOrg()}
                            className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-9 py-2.5 mb-2 tw-text-white font-bold tw-bg-primary mr-2"
                          >
                            OK
                          </button>
                          <button
                            onClick={() => {
                              setModalOpenEditOrg(false);
                            }}
                            className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-6 py-2.5 mb-2 tw-text-primary font-bold"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default Organizations;
