import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import api from '../../api';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { convertTimeZoneJapan } from '../../utils/common';
import { AxiosResponse } from 'axios';

function SaveDraftModal(props: any) {
  type Item = {
    ship: {
      ship_spec: {
        ship_code: string;
      };
    };
    basic_info: {
      dock_order_purpose: string;
    };
    update_at: string;
  };

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ships' does not exist on type 'DefaultRo... Remove this comment to see the full error message
  const { selectedShip } = useSelector((state) => state.ships);
  const [isModalOpen, setModalOpen] = useState(false);
  const [draftItem, setDraftItem] = useState<Item>();

  useEffect(() => {
    async function getDraft() {
      if (props.isOpen) {
        const listDrafts = await api.ships.getListDraft(selectedShip?.ship_id);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'length' does not exist on type 'AxiosRes... Remove this comment to see the full error message
        if (listDrafts !== null && listDrafts.length > 0) {
          // @ts-ignore
          listDrafts[0].update_at = moment(convertTimeZoneJapan(listDrafts[0].update_at)).format('YYYY/MM/DD');
          // @ts-ignore
          setDraftItem(listDrafts[0]);
        }
        setModalOpen(props.isOpen);
      }
    }
    getDraft();
  }, []);

  const continueEditing = () => {
    // history.push(`/basic-info`);
  };

  // const onClose = () => {
  //   setModalOpen(false);
  //   // localStorage.removeItem("openDraft");
  // };

  return (
    <Transition.Root show={isModalOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={props.onClose}>
        <div className="flex items-center justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}

          <span className="hidden" aria-hidden="true">
            &#8203;
          </span>
          {
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              {/* // @ts-expect-error ts-migrate(2339) FIXME: Property 'ship' does not exist on type '{}'. */}
              {/* {draftItem && draftItem.ship && ( */}
              {draftItem && (
                <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle max-w-screen-xl w-full">
                  <div className="flex flex-col bg-white py-4 px-8">
                    <React.Fragment>
                      <div className="flex items-center justify-end tw-text-gray-400 pb-4 tw-text-center">
                        <svg onClick={props.onClose} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 tw-cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>

                      <div className="flex items-center justify-center tw-text-primary pb-4 tw-text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-20 tw-w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          />
                        </svg>
                      </div>

                      <div className="tw-font-bold tw-text-xl pb-4 tw-text-center">Your order was saved as a draft.</div>

                      <div className="flex flex-row justify-between content-center px-3 py-4 mb-5 tw-rounded-xl tw-border-2 border-slate-500">
                        <div className="flex flex-col">
                          <span className="text-sm mb-2 text-gray-400">Ship</span>

                          <div className="flex items-center">
                            <span className="text-lg">{draftItem.ship.ship_spec.ship_code}</span>
                            {
                              // @ts-expect-error ts-migrate(2339) FIXME: Property 'shipCode' does not exist on type '{}'.
                              draftItem?.shipCode && <span className="text-sm ml-2 text-gray-400">({draftItem.ship.ship_spec.ship_code})</span>
                            }
                          </div>
                        </div>

                        <div className="flex flex-col">
                          <span className="text-sm mb-2 text-gray-400">Purpose of order</span>

                          <span className="text-lg">{draftItem.basic_info.dock_order_purpose}</span>
                        </div>

                        <div className="flex flex-col">
                          <span className="text-sm mb-2 text-gray-400">Last edited</span>

                          <span className="text-lg">{draftItem.update_at}</span>
                        </div>

                        <div>
                          <button onClick={props.onContinue} className="tw-rounded-lg h-full tw-bg-primary tw-px-4 tw-text-white tw-font-bold mx-auto">
                            Continue Editing
                          </button>
                        </div>
                      </div>

                      <button onClick={props.onClose} className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-6 py-2.5 mb-2 tw-text-primary font-bold">
                        Close
                      </button>
                    </React.Fragment>
                  </div>
                </div>
              )}
            </Transition.Child>
          }
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SaveDraftModal;
