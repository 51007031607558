import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';
import _ from 'lodash';
import { sortObjectByIndex } from '../utils/common';

export const getListShip = createAsyncThunk('ships/getList', async () => {
  try {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    const ships = await api.ships.getList();
    return { ships };
  } catch (error) {
    return { ships: [] };
  }
});
export const saveShipSpecEntity = createAsyncThunk('ships/saveShipSpecEntity', async (data, { getState, dispatch }) => {
  try {
    const response = await api.ships.storeShipSpecEntity(data);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
    if (response?.meta?.status !== 'success') {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
      alert(response?.meta?.message || 'save ship spec failure');
      return [];
    }

    // Reload after save
    const selectedShip = (getState() as any).ships.selectedShip;
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    dispatch(getShipSpecEntity(selectedShip));
  } catch (error) {
    console.log(error);
    return [];
  }
});
export const getDocHistory = createAsyncThunk('ships/getDocHistory', async (shipId) => {
  try {
    let history = await api.ships.getHistory(shipId);
    //@ts-ignore
    history = _.orderBy(history, 'dock_order_id', 'desc');
    return history;
  } catch (error) {
    return [];
  }
});

export const getShipSpecEntity = createAsyncThunk('ships/getShipSpecEntity', async (ship) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'entity' does not exist on type 'AxiosRes... Remove this comment to see the full error message
  let { entity } = await api.ships.getShipSpecEntity(ship?.ship_spec?.ship_spec_id);
  if (!entity || Object.keys(entity).length < 1) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ship_type' does not exist on type 'void'... Remove this comment to see the full error message
    entity = await api.ships.getShipSpecTemplate(ship?.ship_type?.ship_type_code);
  }
  entity = sortObjectByIndex(entity);
  const element = await api.ships.getShipSpecElement();
  return { entity, element };
});
export const shipslice = createSlice({
  name: 'ships',
  initialState: {
    shipList: [],
    getListStatus: 'idle',
    getListError: null,
    selectedShip: null,
    docHistory: [],
    getDocHistoryStatus: 'idle',
    getDocHistoryError: null,
    shipSpecEntity: {},
    shipSpecElement: [],
    getShipSpecEntityStatus: 'idle',
    getShipSpecEntityError: null,
    applicationForm: null,
    dockOrderDraft: null,
    saveShipSpecEntityStatus: 'idle',
    saveShipSpecEntityError: null,
    dockOrderRepairHistory: null,
    shipSpecComfirmed: false,
  },
  reducers: {
    setDocHistory: (state, action) => {
      state.docHistory = action.payload;
    },
    setSelectedShip: (state, action) => {
      state.selectedShip = action.payload;
    },
    setShipSpecEntity: (state, action) => {
      _.set(state.shipSpecEntity, action.payload.key, action.payload.value);
    },
    setApplicationForm: (state, action) => {
      state.applicationForm = action.payload;
    },
    setDockOrderDraft: (state, action) => {
      state.dockOrderDraft = action.payload;
    },
    setSaveShipSpecEntityStatus: (state, action) => {
      state.saveShipSpecEntityStatus = action.payload;
    },
    setDockOrderRepairHistory: (state, action) => {
      state.dockOrderRepairHistory = action.payload;
    },
    setNewShip: (state, action) => {
      if (action.payload?.remove) {
        state.shipList.pop();
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        state.shipList.push(action.payload.data);
      } else {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        state.shipList.push(action.payload);
      }
    },
    discardNewShip: (state) => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'ship_id' does not exist on type 'never'.
      state.shipList = state.shipList.filter((t) => t.ship_id !== 999999);
    },
    setShipSpecComfirmed: (state, action) => {
      state.shipSpecComfirmed = action.payload;
    },
  },
  extraReducers: {
    // ship
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getListShip.pending]: (state) => {
      state.getListStatus = 'loading';
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getListShip.fulfilled]: (state, action) => {
      state.getListStatus = 'succeeded';
      state.shipList = action.payload.ships;
      state.selectedShip = action.payload.ships[0];
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getListShip.rejected]: (state, action) => {
      state.getListStatus = 'failed';
      state.getDocHistoryError = action.error.message;
    },
    // history
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getDocHistory.pending]: (state) => {
      state.docHistory = [];
      state.getDocHistoryStatus = 'loading';
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getDocHistory.fulfilled]: (state, action) => {
      state.getDocHistoryStatus = 'succeeded';
      state.docHistory = action.payload;
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getDocHistory.rejected]: (state, action) => {
      state.getDocHistoryStatus = 'failed';
      state.getDocHistoryError = action.error.message;
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getShipSpecEntity.pending]: (state) => {
      state.shipSpecEntity = {};
      state.getShipSpecEntityError = null;
      state.getShipSpecEntityStatus = 'loading';
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getShipSpecEntity.fulfilled]: (state, action) => {
      state.getShipSpecEntityStatus = 'succeeded';
      state.shipSpecEntity = action.payload.entity;
      state.shipSpecElement = action.payload.element;
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getShipSpecEntity.rejected]: (state, action) => {
      state.getShipSpecEntityStatus = 'failed';
      state.getShipSpecEntityError = action.error.message;
    },

    // save shipSpec
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [saveShipSpecEntity.pending]: (state) => {
      state.saveShipSpecEntityStatus = 'loading';
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [saveShipSpecEntity.fulfilled]: (state, action) => {
      if (action.payload?.meta?.status === 'success') {
        state.saveShipSpecEntityStatus = 'succeeded';
      } else {
        state.saveShipSpecEntityStatus = 'failed';
        state.saveShipSpecEntityError = action.payload?.meta?.message;
      }
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [saveShipSpecEntity.rejected]: (state, action) => {
      state.saveShipSpecEntityStatus = 'failed';
      state.saveShipSpecEntityError = action.payload?.meta?.message;
    },
  },
});

const { actions, reducer } = shipslice;

export const shipsActions = actions;

export default reducer;
