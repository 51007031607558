import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../../api/index';
function UploadExcel() {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ships' does not exist on type 'DefaultRo... Remove this comment to see the full error message
  const { selectedShip } = useSelector((state) => state.ships);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleChangeFile = (e: any) => {
    setSelectedFile(e.target?.files?.[0]);
  };
  const sendExcelToServer = () => {
    if (selectedFile) {
      const data = new FormData();
      // ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message

      //@ts-ignore
      data.append('file', selectedFile);
      //@ts-ignore
      data.append('ship_id', selectedShip.ship_id);
      api.ships
        .importFromExcel(data)
        .then((res) => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
          if (res.meta.status === 'success') {
            alert('OK: ファイルインポートが完了しました。');
          } else {
            alert('NG: ファイルインポートが失敗しました。');
          }
        })
        .catch(() => alert('NG: ファイルインポートが失敗しました。'));
    }
  };
  return (
    <div className="tw-pt-24 tw-px-44">
      <p className="tw-text-2xl tw-font-bold tw-mb-5">ドックオーダー作成</p>

      <input
        type="file"
        className="tw-w-full"
        onChange={handleChangeFile}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />

      <button className="tw-bg-primary tw-rounded tw-px-5 tw-py-3 tw-text-white tw-mt-3" onClick={sendExcelToServer}>
        ファイルを読み込み
      </button>
    </div>
  );
}

export default UploadExcel;
