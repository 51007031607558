import * as React from 'react';
import { truncateString } from 'utils/common';

type Props = {
  addDirectionSubProcess: (directionValue: any, processKey: any) => void;
  valueDirection: any;
  isConfirmOrder: boolean;
  isRepairHistory: boolean;
  index?: string;
  lookDirection: (directionValue: any) => void;
  editDirection: (directionValue: any) => void;
  editDirectionSubProcess: (directionValue: any, processKey: any) => void;
  processKey: string;
};

const Direction = (props: Props): React.ReactElement => {
  return (
    <>
      <div className="flex tw-w-full tw-justify-between">
        <button onClick={() => props.addDirectionSubProcess(props.valueDirection, props.processKey)} hidden={props.valueDirection || props.isConfirmOrder || props.isRepairHistory}>
          <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M6 1V6M6 6V11M6 6H11M6 6H1" stroke={props.index === 'head' ? '#FFF' : '#CBD5E1'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>

        <p
          className="tw-w-5/6 tw-h-12 tw-py-2 text-sm tw-cursor-po inter"
          style={{ overflow: 'hidden', textOverflow: 'ellipsis', WebkitLineClamp: '2', display: '-webkit-box', WebkitBoxOrient: 'vertical' }}
          onClick={() => props.lookDirection(props.valueDirection)}
        >
          {truncateString(props.valueDirection || '', 100)}
        </p>

        <div
          className="tw-cursor-pointer opacity-60 tw-py-4"
          hidden={!props.valueDirection || props.isConfirmOrder || props.isRepairHistory}
          onClick={() => props.editDirectionSubProcess(props.valueDirection, props.processKey)}
        >
          Edit
        </div>
      </div>
    </>
  );
};

export default Direction;
