import React from 'react';
import { useSelector } from 'react-redux';

function Loader() {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  const { loading } = useSelector((state) => state.main);
  return (
    <>
      {loading && (
        <div className="tw-fixed tw-flex tw-justify-center tw-items-center tw-bg-gray-500 tw-bg-opacity-25 tw-z-50 tw-w-full tw-h-full">
          <div style={{ borderTopColor: 'transparent' }} className="w-16 h-16 tw-border-4 tw-border-primary border-solid rounded-full animate-spin"></div>
        </div>
      )}
    </>
  );
}

export default Loader;
