import { useSelector } from 'react-redux';
import { truncateString, selectLanguage } from 'utils/common';

function OperationSubProcessComponent({ subProcess, unitTitle, rowClass, leftClass, borderClass, borderClassNotGeneral, index, key, processKey, lookDirection }: any) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'dockOrders' does not exist on type 'Defa... Remove this comment to see the full error message
  const { operationUnitDict, language } = useSelector((state) => state.dockOrders);
  const getOperationUnitTitle = (key: any) => {
    return operationUnitDict[key] ? operationUnitDict[key][selectLanguage(language, 'operation_unit')] || operationUnitDict[key][selectLanguage(language, 'dock_order')] : key;
  };

  return (
    <>
      <div className={rowClass} key={`sub_process_${key}_${processKey}`}>
        <div className={leftClass}></div>
        <div className={borderClassNotGeneral}>
          {index && (
            <div>
              <div className="tw-mr-5 normal-case">{getOperationUnitTitle(subProcess?.['title'])}</div>
            </div>
          )}
        </div>

        <div className={borderClass}>
          <div className="tw-w-1/2">{subProcess?.unit}</div>
          <div>{getOperationUnitTitle(subProcess?.unit_name)}</div>
        </div>

        <div className={borderClass}>
          <p
            className="tw-w-5/6 tw-h-12 tw-py-2 text-sm tw-cursor-po inter"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', WebkitLineClamp: '2', display: '-webkit-box', WebkitBoxOrient: 'vertical' }}
            onClick={() => lookDirection(subProcess.direction)}
          >
            {truncateString(subProcess.direction || '', 100)}
          </p>
        </div>

        <div className="tw-px-4 tw-flex-1 tw-items-center flex"></div>
      </div>
    </>
  );
}

export default OperationSubProcessComponent;
