import React from 'react';
// ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Route, Switch } from 'react-router-dom';
// ts-expect-error ts-migrate(6142) FIXME: Module './SideBar' was resolved to '/Users/jmb/dev... Remove this comment to see the full error message
import SideBar from './SideBar';
// ts-expect-error ts-migrate(6142) FIXME: Module './Users' was resolved to '/Users/jmb/dev/o... Remove this comment to see the full error message
import Users from './Users';
// ts-expect-error ts-migrate(6142) FIXME: Module './Organizations' was resolved to '/Users/j... Remove this comment to see the full error message
import Organizations from './Organizations';

function Admin() {
  return (
    // ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
    <div className="tw-flex">
      <SideBar />

      <Switch>
        <Route path="/admin/users" component={Users} />

        <Route path="/admin/organizations" component={Organizations} />
      </Switch>
    </div>
  );
}

export default Admin;
