import { memo, useState } from 'react';

function SearchFormInput({ ...props }) {
  const [searchValue, setSearchValue] = useState('');
  const resetSearch = () => {
    setSearchValue('');
    props.resetSearch();
  };
  return (
    <>
      <input
        type="text"
        name="inputText"
        id="inputTextSearch"
        className="mt-1 px-3 py-2 border shadow-sm  row-start-2 row-end-3 text-lg
                  border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
        placeholder="Search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyDown={props.onSearch}
      />

      <div className="tw-cursor-pointer mr-2 absolute right-0 top-4" hidden={!searchValue} onClick={resetSearch}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99967 2.66659C6.58519 2.66659 5.22863 3.22849 4.22844 4.22868C3.22824 5.22888 2.66634 6.58543 2.66634 7.99992C2.66634 8.7003 2.80429 9.39383 3.07232 10.0409C3.34034 10.688 3.73319 11.2759 4.22844 11.7712C4.72368 12.2664 5.31163 12.6593 5.9587 12.9273C6.60577 13.1953 7.29929 13.3333 7.99967 13.3333C8.70006 13.3333 9.39358 13.1953 10.0407 12.9273C10.6877 12.6593 11.2757 12.2664 11.7709 11.7712C12.2662 11.2759 12.659 10.688 12.927 10.0409C13.1951 9.39383 13.333 8.7003 13.333 7.99992C13.333 6.58543 12.7711 5.22888 11.7709 4.22868C10.7707 3.22849 9.41416 2.66659 7.99967 2.66659ZM3.28563 3.28587C4.53587 2.03563 6.23156 1.33325 7.99967 1.33325C9.76779 1.33325 11.4635 2.03563 12.7137 3.28587C13.964 4.53612 14.6663 6.23181 14.6663 7.99992C14.6663 8.8754 14.4939 9.7423 14.1589 10.5511C13.8238 11.36 13.3328 12.0949 12.7137 12.714C12.0947 13.333 11.3597 13.8241 10.5509 14.1591C9.74206 14.4941 8.87515 14.6666 7.99967 14.6666C7.1242 14.6666 6.25729 14.4941 5.44845 14.1591C4.63961 13.8241 3.90469 13.333 3.28563 12.714C2.66657 12.0949 2.17551 11.36 1.84048 10.5511C1.50545 9.7423 1.33301 8.8754 1.33301 7.99992C1.33301 6.23181 2.03539 4.53612 3.28563 3.28587ZM6.19494 6.19518C6.45529 5.93483 6.8774 5.93483 7.13775 6.19518L7.99967 7.05711L8.8616 6.19518C9.12195 5.93483 9.54406 5.93483 9.80441 6.19518C10.0648 6.45553 10.0648 6.87764 9.80441 7.13799L8.94248 7.99992L9.80441 8.86185C10.0648 9.1222 10.0648 9.54431 9.80441 9.80466C9.54406 10.065 9.12195 10.065 8.8616 9.80466L7.99967 8.94273L7.13775 9.80466C6.8774 10.065 6.45529 10.065 6.19494 9.80466C5.93459 9.54431 5.93459 9.1222 6.19494 8.86185L7.05687 7.99992L6.19494 7.13799C5.93459 6.87764 5.93459 6.45553 6.19494 6.19518Z"
            fill="#CBD5E1"
          />
        </svg>
      </div>
    </>
  );
}

export default memo(SearchFormInput);
