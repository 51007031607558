import * as React from 'react';

type Props = {
  isLoading: boolean;
};

const Loading = (props: Props): React.ReactElement => {
  if (!props.isLoading) {
    return <div />;
  }

  return (
    <>
      {props.isLoading && (
        <div className="tw-fixed tw-flex tw-justify-center tw-items-center tw-bg-gray-500 tw-bg-opacity-25 tw-z-50 tw-w-full tw-h-full x-50">
          <div style={{ borderTopColor: 'transparent' }} className="w-16 h-16 tw-border-4 tw-border-primary border-solid rounded-full animate-spin"></div>
        </div>
      )}
    </>
  );
};

export default Loading;
