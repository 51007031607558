import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import auth from '../../../api/auth';

function ChangePassword() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const currentPassword = useFormInput('');
  const newPassword = useFormInput('');
  const confirmPassword = useFormInput('');
  const [error, setError] = useState(null);
  const validateChangePasswordForm = () => {
    if (!currentPassword.value) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Current password is required."'... Remove this comment to see the full error message
      setError('Current password is required.');
      return true;
    }
    if (!newPassword.value) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"New password is required."' is ... Remove this comment to see the full error message
      setError('New password is required.');
      return true;
    }
    if (!confirmPassword.value) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Repeat password is required."' ... Remove this comment to see the full error message
      setError('Repeat password is required.');
      return true;
    }
    if (newPassword.value !== confirmPassword.value) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Repeat password is mismatched."... Remove this comment to see the full error message
      setError('Repeat password is mismatched.');
      return true;
    }
    return false;
  };
  const handleChangePassword = (event: any) => {
    event.preventDefault();
    if (!validateChangePasswordForm() && !loading) {
      setLoading(true);
      auth
        .changePassword({
          current_password: currentPassword.value,
          new_password: newPassword.value,
          new_password_confirm: confirmPassword.value,
        })
        .then((data) => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
          if (data.meta?.status === 'success') {
            history.replace('/dashboard');
            alert('Update password success');
          } else {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
            setError(data.meta?.message || 'Update password failed');
          }
        })
        .catch(() => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Update password failed"' is not... Remove this comment to see the full error message
          setError('Update password failed');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="tw-w-screen tw-h-screen tw-relative tw-text-center tw-bg-secondary tw-font-noto">
      <div
        className="tw-transform tw--translate-y-1/2 tw--translate-x-1/2 tw-top-1/2
       tw-left-1/2 tw-absolute tw-rounded-lg tw-bg-white tw-justify-between
       tw-flex-row tw-p-8 tw-w-96"
      >
        <div className="tw-h-16 tw-px-0 tw-flex tw-items-center tw-justify-between">
          <button
            onClick={() => {
              history.goBack();
            }}
            className="tw-h-full tw-flex tw-items-center tw-text-xl active:tw-bg-secondary"
          >
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 15L1 8L8 1" stroke="#0F172A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

            <span className="tw-px-3">Back</span>
          </button>
        </div>

        <h2 className="tw-font-bold tw-mb-10 tw-text-2xl">Change password</h2>

        <form onSubmit={handleChangePassword}>
          <div className="tw-text-danger tw-mx-4">{error && <small className="">{error}</small>}</div>

          <div className="tw-relative tw-mb-4">
            <input
              className="tw-w-full tw-px-2 tw-py-3 tw-border-secondary tw-border tw-rounded tw-outline-none"
              type="password"
              {...currentPassword}
              placeholder="Current password"
            />
          </div>

          <div className="tw-relative tw-mb-4">
            <input className="tw-w-full tw-px-2 tw-py-3 tw-border-secondary tw-border tw-rounded tw-outline-none" type="password" {...newPassword} placeholder="New password" />
          </div>

          <div className="tw-relative tw-mb-4">
            <input
              className="tw-w-full tw-px-2 tw-py-3 tw-border-secondary tw-border tw-rounded tw-outline-none"
              type="password"
              {...confirmPassword}
              placeholder="New password (Confirmation)"
            />
          </div>

          <button
            type="submit"
            className="tw-bg-primary tw-w-full tw-flex tw-py-3 tw-justify-center tw-items-center
             tw-text-white tw-mb-8 tw-rounded focus:tw-ring-inset focus:tw-ring-secondary focus:tw-outline-none"
            disabled={loading}
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
}

const useFormInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e: any) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default ChangePassword;
