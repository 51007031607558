import request from "../../utils/request";

const uploadFile = (formData: any) => request.post(`api/v1/user_file_upload/file_upload`, formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});
const downloadFile = (file_name: any) => request.get(`api/v1/user_file_upload/file_download`, { params: { file_name: file_name }, responseType: 'blob' });
const deleteFile = (formData: any) => request.post(`api/v1/user_file_upload/file_delete`, formData, {});

const fileApi = {
    uploadFile,
    downloadFile,
    deleteFile
};

export default fileApi;
