import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

export const getListOrganization = createAsyncThunk('organizations/getList', async () => {
    try {
        const organization = await api.organization.getList();
        return { organization };
    } catch (error) {
        return { organization: [] };
    }
});


const organizationSlice = createSlice({
    name: 'organization',
    initialState: {
        organizationList: [],
        getListStatus: 'idle',
        getListError: null,
    },
    reducers: {

    },
    extraReducers: {
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [getListOrganization.pending]: (state) => {
            state.getListStatus = 'loading';
        },
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [getListOrganization.fulfilled]: (state, action) => {
            state.getListStatus = 'succeeded';
            state.organizationList = action.payload.organization;
        },
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [getListOrganization.rejected]: (state, action) => {
            state.getListStatus = 'failed';
            state.getListError = action.error.message;
        },
    }
});

const { actions, reducer } = organizationSlice;

export const mainActions = actions;

export default reducer;
