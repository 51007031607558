import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';
import _ from 'lodash';

export const getDockOrderInfo = createAsyncThunk('dockOrder/getInfo', async (ship_id, dock_order_id) => {
  try {
    return await api.dockOrderApis.getDockOrder(ship_id, dock_order_id);
  } catch (error) {
    return {};
  }
});

export const getDockOrderElement = createAsyncThunk('dockOrder/element', async () => {
  try {
    return await api.dockOrderApis.getDockOrderElement();
  } catch (error) {
    return {};
  }
});

export const saveDraft = createAsyncThunk('dockOrder/saveDraft', async (id, data) => {
  try {
    const response = await api.dockOrderApis.saveDraft(data);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
    if (response?.meta?.status) {
      alert('update draft success');
    }
    return response;
  } catch (error) {
    return [];
  }
});

export const dockOrderlice = createSlice({
  name: 'dockOrders',
  initialState: {
    language: 'eng',
    dockOrderInfo: null,
    dockOrderElement: null,
    operationUnitDict: {},
    subProcessPullDownSource: {},
  },
  reducers: {
    setDockOrderInfo: (state, action) => {
      state.dockOrderInfo = action.payload;
    },
    setDockOrderElement: (state, action) => {
      state.dockOrderElement = action.payload;
    },
    setOperationUnitDict: (state, action) => {
      state.operationUnitDict = action.payload;
    },
    setSubProcessPullDownSource: (state, action) => {
      state.subProcessPullDownSource = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
  extraReducers: {},
});

const { actions, reducer } = dockOrderlice;

export const dockOrderAction = actions;

export default reducer;
