import axios from 'axios';
import { getToken, removeUserSession, setUserSession, tokenExpired } from '../utils/common';

const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

service.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      config.headers.Authorization = `Bearer public`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

service.interceptors.response.use(
  (response) => {
    // check token expired
    const expired = getToken() && tokenExpired(response?.data?.meta?.code);
    if (expired) {
      removeUserSession();
      window.location.href = "/login";
    }
    const success = response.data?.meta?.status === 'success';
    const entity = response.data?.entity;
    const token = response.data?.entity?.access_token;
    if (success && token) {
      setUserSession(token, { username: entity.login_id });
    }
    return response.data;
  },
  (error) => Promise.reject(error),
);

export default service;
