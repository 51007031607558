import { Dialog, Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getListUser } from '../../../store/userSlice';
import { getListOrganization } from '../../../store/organizationSlice';
import { mainActions } from '../../../store/mainSlice';
import user from '../../../api/user';
import useFormInput from '../../../hooks/useFormInput';

function Users() {
  const history = useHistory();
  const dispatch = useDispatch();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'users' does not exist on type 'DefaultRo... Remove this comment to see the full error message
  const { userList } = useSelector((state) => state.users);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'organization' does not exist on type 'De... Remove this comment to see the full error message
  const { organizationList } = useSelector((state) => state.organization);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpenAdd, setModalOpenAddUser] = useState(false);
  const [isModalOpenDel, setModalOpenDelUser] = useState(false);
  const [isModalOpenEdit, setModalOpenEditUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const login_id = useFormInput('');
  const password = useFormInput('');
  const organization_id = useFormInput('');
  const email = useFormInput('');

  useEffect(() => {
    dispatch(getListUser());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getListOrganization());
  }, [dispatch]);

  const handleChangeOrganization = (event: any) => {
    setSelectedOrganization(event.target.value);
    organization_id.set(event.target.value);
  };

  const openModalAddUser = () => {
    setModalOpenAddUser(true);
    login_id.set('');
    email.set('');
    password.set('');
    organization_id.set(1);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '1' is not assignable to paramete... Remove this comment to see the full error message
    setSelectedOrganization(1);
  };

  const openModalDelUser = (user: any) => {
    setSelectedUser(user);
    setModalOpenDelUser(true);
  };

  const openModalEditUser = (user: any) => {
    setSelectedUser(user);
    login_id.set(user.login_id);
    email.set(user.email);
    password.set('');
    organization_id.set(user.shipowneruser.organization);
    setModalOpenEditUser(true);
  };

  const validateAddUser = () => {
    if (!login_id.value) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"LoginID is required."' is not a... Remove this comment to see the full error message
      setError('LoginID is required.');
      return true;
    }
    if (!password.value) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"password is required."' is not ... Remove this comment to see the full error message
      setError('password is required.');
      return true;
    }
    return false;
  };

  const validateEditUser = () => {
    if (!login_id.value) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"LoginID is required."' is not a... Remove this comment to see the full error message
      setError('LoginID is required.');
      return true;
    }
    return false;
  };

  const onSubmitAddUser = () => {
    if (!validateAddUser() && !loading) {
      dispatch(mainActions.setLoading(true));
      user
        .addUser({
          login_id: login_id.value,
          password: password.value,
          organization_id: selectedOrganization,
          email: email.value,
        })
        .then((data) => {
          dispatch(mainActions.setLoading(false));
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
          if (data.meta?.status === 'success') {
            window.location.reload();
          } else {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
            setError(data.meta?.message || 'failed');
          }
        })
        .catch(() => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"failed"' is not assignable to p... Remove this comment to see the full error message
          setError('failed');
        })
        .finally(() => {
          setLoading(false);
          dispatch(mainActions.setLoading(false));
        });
    }
  };

  const onSubmitEditUser = () => {
    if (!validateEditUser() && !loading) {
      dispatch(mainActions.setLoading(true));
      user
        .editUser({
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          user_id: selectedUser.user_id,
          login_id: login_id.value,
          password: password.value,
          organization_id: organization_id.value,
          email: email.value,
        })
        .then((data) => {
          dispatch(mainActions.setLoading(false));
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
          if (data.meta?.status === 'success') {
            window.location.reload();
          } else {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
            setError(data.meta?.message || 'failed');
          }
        })
        .catch(() => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"failed"' is not assignable to p... Remove this comment to see the full error message
          setError('failed');
        })
        .finally(() => {
          setLoading(false);
          dispatch(mainActions.setLoading(false));
        });
    }
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmitDelUser = () => {
    setLoading(true);
    dispatch(mainActions.setLoading(true));
    user
      .delUser({
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        user_id: selectedUser.user_id,
      })
      .then((data) => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
        if (data.meta?.status === 'success') {
          window.location.reload();
        } else {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
          setError(data.meta?.message || 'failed');
        }
        dispatch(mainActions.setLoading(false));
      })
      .catch(() => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"failed"' is not assignable to p... Remove this comment to see the full error message
        setError('failed');
      })
      .finally(() => {
        setLoading(false);
        dispatch(mainActions.setLoading(false));
      });
    setModalOpenDelUser(false);
  };

  return (
    <div className="tw-w-10/12 tw-bg-white tw-h-screen tw-pt-28 tw-pl-16">
      <h3 className="tw-text-3xl tw-font-bold">Users</h3>

      <button
        onClick={() => openModalAddUser()}
        className="tw-right-0 tw-h-11 tw-rounded-2xl tw-border-2
           tw-border-primary tw-px-3 tw-text-primary"
      >
        Add users
      </button>

      <table className="tw-table-auto tw-w-11/12 tw-mt-4">
        <thead className="tw-bg-secondary">
          <th className="tw-p-3 tw-border">User ID</th>

          <th className="tw-p-3 tw-border">User name</th>

          <th className="tw-p-3 tw-border">Mail Address</th>

          <th className="tw-p-3 tw-border  tw-w-44"></th>
        </thead>

        <tbody>
          {(userList || []).map((user: any, i: any) => {
            return (
              // ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
              <tr className={`${i % 2 === 0 ? 'tw-bg-lightdary' : ''}`}>
                <td className="tw-p-3 tw-border">{i + 1}</td>

                <td className="tw-p-3 tw-border">{user.login_id}</td>

                <td className="tw-p-3 tw-border">{user.email}</td>

                <td className="tw-p-3 tw-border tw-w-44 tw-flex">
                  <span onClick={() => openModalEditUser(user)} className="tw-cursor-pointer" title="Delete user">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                  </span>

                  <span onClick={() => openModalDelUser(user.user_id)} className="tw-cursor-pointer" title="Delete user">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Transition.Root show={isModalOpenDel} as={React.Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setModalOpenDelUser}>
          <div className="flex items-center justify-center min-h-screen w-full pt-4 px-6 pb-20 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}

            <span className="hidden" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle">
                <div className="flex flex-col bg-white py-4 px-8">
                  <React.Fragment>
                    <div className="">
                      <div className="flex flex-col bg-white py-4 px-8">
                        <div className="tw-font-bold tw-text-xl pb-3"></div>

                        <div className="flex flex-row content-center py-4 mb-3">
                          <div className="flex flex-col tw-w-full">
                            <div className="tw-font-bold tw-text-lg pb-2">Delete user</div>
                          </div>
                        </div>

                        <div className="flex justify-end">
                          <button
                            onClick={() => onSubmitDelUser()}
                            className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-9 py-2.5 mb-2 tw-text-white font-bold tw-bg-primary mr-2"
                          >
                            OK
                          </button>

                          <button
                            onClick={() => {
                              setModalOpenDelUser(false);
                            }}
                            className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-6 py-2.5 mb-2 tw-text-primary font-bold"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={isModalOpenAdd} as={React.Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setModalOpenAddUser}>
          <div className="flex items-center justify-center min-h-screen w-full pt-4 px-6 pb-20 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}

            <span className="hidden" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle">
                <div className="flex flex-col bg-white py-4 px-8">
                  <React.Fragment>
                    <div className="">
                      <div className="flex flex-col bg-white py-4 px-8">
                        <div className="tw-text-danger tw-mx-4">{error && <small className="">{error}</small>}</div>
                        <div className="flex flex-row content-center py-4 mb-3">
                          <div className="flex flex-col tw-w-full">
                            <div className="tw-font-bold tw-text-lg pb-2">Add User</div>
                            <div>Login ID</div>
                            <input className="form-control" type="text" name="text" {...login_id} placeholder="Login ID" />
                            <div>Email</div>
                            <input className="form-control" type="email" name="email" {...email} placeholder="Email" />
                            <div>Password</div>
                            <input className="form-control" type="password" name="password" {...password} placeholder="Password" autoComplete="off" />
                            <div>Organization Name</div>
                            <div className="tw-col-start-2 tw-col-end-4">
                              <select
                                className="tw-w-full tw-p-2 tw-border-secondary tw-border tw-rounded"
                                {...organization_id}
                                onChange={(event) => {
                                  handleChangeOrganization(event);
                                }}
                              >
                                {organizationList.map((organization: any) => {
                                  return (
                                    // ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
                                    <option value={organization.organization_id}>{organization.organization_name}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-end">
                          <button
                            onClick={() => onSubmitAddUser()}
                            className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-9 py-2.5 mb-2 tw-text-white font-bold tw-bg-primary mr-2"
                          >
                            OK
                          </button>

                          <button
                            onClick={() => {
                              setModalOpenAddUser(false);
                            }}
                            className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-6 py-2.5 mb-2 tw-text-primary font-bold"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={isModalOpenEdit} as={React.Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setModalOpenEditUser}>
          <div className="flex items-center justify-center min-h-screen w-full pt-4 px-6 pb-20 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}

            <span className="hidden" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <div className="inline-flex flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle">
                <div className="flex flex-col bg-white py-4 px-8">
                  <React.Fragment>
                    <div className="">
                      <div className="flex flex-col bg-white py-4 px-8">
                        <div className="tw-text-danger tw-mx-4">{error && <small className="">{error}</small>}</div>

                        <div className="flex flex-row content-center py-4 mb-3">
                          <div className="flex flex-col tw-w-full">
                            <div className="tw-font-bold tw-text-lg pb-2">Edit User</div>

                            <div>Login ID</div>

                            <input className="form-control" type="text" name="text" {...login_id} placeholder="Login ID" />

                            <div>Email</div>

                            <input className="form-control" type="email" name="email" {...email} placeholder="Email" />

                            <div>Password</div>

                            <input className="form-control" type="password" name="password" {...password} placeholder="Password" />

                            <div>Organization Name</div>

                            <div className="tw-col-start-2 tw-col-end-4">
                              <select
                                className="tw-w-full tw-p-2 tw-border-secondary tw-border tw-rounded"
                                {...organization_id}
                                onChange={(event) => {
                                  handleChangeOrganization(event);
                                }}
                              >
                                {organizationList.map((organization: any) => {
                                  return <option value={organization.organization_id}>{organization.organization_name}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-end">
                          <button
                            onClick={() => onSubmitEditUser()}
                            className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-9 py-2.5 mb-2 tw-text-white font-bold tw-bg-primary mr-2"
                          >
                            OK
                          </button>

                          <button
                            onClick={() => {
                              setModalOpenEditUser(false);
                            }}
                            className="self-center tw-rounded-lg tw-border-2 tw-border-primary px-6 py-2.5 mb-2 tw-text-primary font-bold"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default Users;
